<div class="info-modal info-modal--project" *ngIf="projectProxy">
  <app-generic-modal
    [closeButton]="{ text: 'Cancel' }"
    [acceptButton]="{ text: 'Save project' }"
    [developmentType]="DevelopmentType.project"
    [primary]="true"
    [hideHeaderCloseIcon]="true"
    (closeModal)="close($event)"
  >
    <ng-template #headerTemplate>
      <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
    </ng-template>
    <button
      (click)="openDeleteModal()"
      class="project-delete-btn"
      *ngIf="selectedProject && projectProxy.isOwner && modalType() === ModalType.EDIT"
    >
      <app-icon
        class="me-1"
        [width]="'0.5rem'"
        [height]="'0.6rem'"
        [fileName]="'trash_3_icon.svg'"
        [color]="IconColors.grey"
      ></app-icon>
      DELETE PROJECT
    </button>
    <div class="info-modal__body">
      <form [formGroup]="form">
        <div class="form-control-container">
          <label class="col-sm-3 input-label">Name*</label>
          <div class="col-sm-12">
            <app-input-with-clear
              data-testid="add-project-input-name-field"
              [hasError]="(controls.name.touched && controls.name.invalid) || controls.name.errors?.backendError"
              [placeholder]="'Name for Project'"
              [formControl]="controls.name"
            ></app-input-with-clear>
            <div class="invalid-feedback d-block" *ngIf="controls.name.getError('backendError') as backendError">
              {{ backendError }}
            </div>
            <div class="invalid-feedback d-block" *ngIf="controls.name.touched && controls.name.errors?.required">
              Name is required.
            </div>
          </div>
        </div>
        <div class="form-control-container">
          <div class="form-control-label">
            <label class="col-sm-3 input-label">Category</label>
            @if (isCurrentUserOwnerOrDeputyOfWorkspace()) {
              <button class="btn manage-btn" (click)="openManageCategoryModal()">
                <img src="../../../assets/icons/icon_manage.svg" alt="edit btn icon" />
                Manage
              </button>
            }
          </div>
          <div class="col-sm-12">
            <app-dropdown-select
              [items]="categorySelectItems()"
              placeholder="Uncategorized"
              [formControl]="form.get('category')"
            ></app-dropdown-select>
          </div>
        </div>
        <div class="form-control-container">
          <label class="col-sm-3 input-label">Status</label>
          <div class="col-sm-12">
            <app-status-dropdown
              data-testid="add-project-status-dropdown"
              [statusSelectItems]="statusSelectItems"
              formControlName="status"
            ></app-status-dropdown>
          </div>
        </div>
        <div class="form-control-container">
          <app-input-with-validation
            [control]="controls.description"
            [config]="{ label: 'Description', name: 'description' }"
            class="description-label"
          >
            <app-text-long-input
              [formControl]="controls.description"
              [counterOptions]="{ show: true, maxLength: 120 }"
              [hasError]="markDescriptionAsInvalid"
              placeholder="Describe project details ..."
              data-testid="add-project-input-description-field"
            ></app-text-long-input>
          </app-input-with-validation>
        </div>
      </form>
      <app-item-colors
        data-testid="add-project-modal-color-select"
        [itemColor]="controls.color.value"
        (selectedColorIndex)="onColorChange($event)"
      ></app-item-colors>
    </div>
  </app-generic-modal>
</div>

<ng-template #modalHeaderTemplate let-modal>
  <app-modal-header-left
    data-testid="add-project-modal-header"
    header="LabGears"
    imgSrc="project_icon.svg"
    itemName="Project"
    [developmentType]="DevelopmentType.project"
    [categoryName]="selectedProject()?.name || 'New'"
    (closeModal)="closeModal.emit()"
  ></app-modal-header-left>
</ng-template>
<ng-template #deleteConfirmationModal let-modal>
  <app-project-actions-modal
    [actionType]="ACTION_TYPE.DELETE"
    (closeModal)="modal.close()"
    (takeProjectAction)="onDeleteConfirmation(modal)"
  ></app-project-actions-modal>
</ng-template>

<ng-template #manageCategoryModal let-modal>
  <app-workspace-manage-projects-categories
    (closeModal)="modal.close()"
    [workspaceId]="workspace().id"
    [workspaceName]="workspace().name"
  ></app-workspace-manage-projects-categories>
</ng-template>
