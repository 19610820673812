import { Observable } from 'rxjs';
import { FileDto } from '@shared/_models/file';

export enum FileDownloadStatus {
  generating,
  generated,
  failed,
}

export interface IFileDownloadStatus {
  id: string;
  status: FileDownloadStatus;
}

export interface IFileUploadStatus {
  file: File;
  progress: Observable<number>;
  file$: Observable<Partial<FileDto>>;
}
