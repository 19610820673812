import { getFirstSection } from '@app/domain/category-template';
import { comparePositions } from '@app/domain/shared';
import { DevelopmentItem, SectionType } from '@shared/_models';

export function getInitiallySelectedImageSection(developmentItem: DevelopmentItem): string {
  const mainImageId = developmentItem.main_image_id;
  const sections = developmentItem?.template?.sections.filter(section => section.type === SectionType.IMAGES).sort(comparePositions);

  return mainImageId
    ? (developmentItem.images.find(image => image.id === mainImageId)?.section_id ??
        getFirstSection(developmentItem?.template, SectionType.IMAGES)?.id)
    : sections?.[0]?.id;
}
