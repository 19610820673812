import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-copy-button',
  imports: [CommonModule, NgbTooltip],
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyButtonComponent {
  @Input() value: string;

  constructor(private readonly clipboard: Clipboard) {}

  onCopyIcon(copyTooltip: NgbTooltip) {
    this.clipboard.copy(this.value);
    setTimeout(() => copyTooltip.close(), 500);
  }
}
