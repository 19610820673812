import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { RouteParam, UserDto, WorkspaceDto, WorkspaceProxy } from '@shared/_models';
import { RootState } from '@shared/_root-store/reducer';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';
import { selectCurrentUser } from '@shared/_root-store/users-store/users.selectors';

import { getInitialWorkspace } from './utils';
import { WorkspacesState } from './workspaces.reducer';

export const selectWorkspacesState = createSelector(
  (state: RootState) => state.workspaces,
  state => state
);

export const selectWorkspacesDictionary = createSelector(
  selectWorkspacesState,
  (state: WorkspacesState) => state?.entities ?? {}
);
export const selectWorkspaces = createSelector(selectWorkspacesDictionary, (dictionary: Dictionary<WorkspaceDto>) =>
  Object.values(dictionary)
);
export const selectWorkspacesPending = createSelector(selectWorkspacesState, (state: WorkspacesState) => state.pending);
export const selectWorkspacesLoading = createSelector(selectWorkspacesState, (state: WorkspacesState) => state.loading);
export const selectWorkspacesLoaded = createSelector(selectWorkspacesState, (state: WorkspacesState) => state.loaded);

export const selectWorkspacePendingMembers = createSelector(
  selectWorkspacesState,
  (state: WorkspacesState) => state?.pendingMembers ?? []
);
export const selectIvitedWorkspaces = createSelector(
  selectWorkspacesState,
  (state: WorkspacesState) => state?.invitedWorkspaces ?? []
);

export const selectFavouriteWorkspace = createSelector(selectWorkspaces, workspaces =>
  workspaces.find(workspace => workspace.is_favourite)
);
export const selectInitialWorkspace = (workspaceId?: string) =>
  createSelector(selectWorkspaces, selectCurrentUser, (workspaces: WorkspaceDto[], currentUser: UserDto) =>
    getInitialWorkspace(workspaces, currentUser, workspaceId)
  );

export const selectSelectedWorkspace = createSelector(
  selectWorkspacesDictionary,
  selectRouteParam(RouteParam.WORKSPACE_ID),
  (dictionary: Dictionary<WorkspaceDto>, workspaceId: string) => dictionary[workspaceId]
);
export const selectSelectedOrInitialWorkspace = createSelector(
  selectSelectedWorkspace,
  selectInitialWorkspace(),
  (selectedWorkspace: WorkspaceDto, initialWorkspace: WorkspaceDto) => selectedWorkspace ?? initialWorkspace
);

export const selectError = createSelector(selectWorkspacesState, (state: WorkspacesState) => state.error);

export const selectWorkspaceById = (workspaceId: string) =>
  createSelector(selectWorkspaces, workspaces => workspaces.find(workspace => workspace.id === workspaceId));

export const selectWorkspaceProxy = createSelector(
  selectCurrentUser,
  selectSelectedOrInitialWorkspace,
  (currentUser: UserDto, workspace: WorkspaceDto) => {
    if (!(currentUser && workspace)) return;

    return new WorkspaceProxy(workspace, currentUser);
  }
);

export const selectWorkspacesCurrentUserIsOwner = createSelector(
  selectWorkspaces,
  selectCurrentUser,
  (workspaces, currentUser) => workspaces.filter(workspace => new WorkspaceProxy(workspace, currentUser).isOwner)
);

export const selectWorkspaceProjectsCategories = createSelector(
  selectSelectedWorkspace,
  (workspace: WorkspaceDto) => workspace?.project_categories ?? []
);

export const selectDefaultProjectCategoryId = createSelector(
  selectSelectedWorkspace,
  (workspace: WorkspaceDto) => workspace?.default_project_category_id ?? []
);
