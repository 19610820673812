<div
  class="pepseq-form"
  [ngClass]="{ 'pepseq-form--edit-mode': editMode, 'area--hidden': !hidden && mode === FieldsViewMode.TEMPLATE_EDIT }"
  (mouseenter)="fieldHovered = true"
  (mouseleave)="fieldHovered = false">
  <div class="pepseq-form__field">
    <app-box-controls-bar
      *ngIf="mode === FieldsViewMode.TEMPLATE_EDIT"
      [hovered]="fieldHovered"
      [visible]="areaHovered"
      (edit)="edit.emit()"
      (delete)="delete.emit()">
    </app-box-controls-bar>
    <app-pepseq-form-field
      [field]="complexFieldSignal()?.parent"
      (valueChange)="onSequenceValueChange($event)"
      [editMode]="editMode"
      [hovered]="active && fieldHovered"
      [errorMessage]="errors | validationError: complexFieldSignal()?.parent.field_template_id">
    </app-pepseq-form-field>
  </div>
  <div class="pepseq-form__dropdowns" *ngIf="showDropdowns">
    <div
      class="pepseq-form__dropdown"
      [ngClass]="{ 'pepseq-form__dropdown--expanded': modificationsDropdownExpanded || editMode }"
      *ngIf="complexFieldSignal()?.parent.type === DisplayFieldType.PEPSEQ">
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <span>
          <span class="pepseq-form__counter" data-testid="modifications-counter">
            Modifications: {{ numberOfVisibleModifications }}/{{ modificationsMaxNumber() }}
          </span>
          <button
            class="pepseq-form__toggle-btn"
            *ngIf="numberOfVisibleModifications > 0"
            (click)="onModificationToggleBtnClick()"></button>
          <span
            class="pepseq-form__add-btn-wrapper"
            ngbTooltip="Modification limit reached"
            placement="bottom"
            tooltipClass="pepseq-form__add-btn-tootlip"
            [disableTooltip]="numberOfVisibleModifications < modificationsMaxNumber()">
          </span>
        </span>
        <button
          class="pepseq-form__add-btn"
          (click)="onModificationAdd()"
          [disabled]="numberOfVisibleModifications >= modificationsMaxNumber()">
          + ADD
        </button>
      </div>

      <div class="pepseq-form__fields-container" *ngIf="mode !== FieldsViewMode.TEMPLATE_PREVIEW && mode !== FieldsViewMode.TEMPLATE_EDIT">
        <ng-container *ngFor="let modification of complexFieldSignal().modifications; let i = index; trackBy: trackByFieldTemplateId">
          <ng-container>
            <!-- TODO: consider to create separate component/container only responsible for arranging
            fields based on their type, would be used here & in app-item-details-grid-section  -->
            <app-pepseq-form-field
              *ngIf="!isNil(modification.value)"
              class="pepseq-form__field"
              [ngClass]="{ 'field--wide': modification.type === DisplayFieldType.TEXT_LONG }"
              [field]="modification"
              [errorMessage]="errors | validationError: modification.field_template_id"
              [editMode]="editMode"
              (valueChange)="onModificationValueChange(i, $event)"
              [hovered]="active && fieldHovered"
              (remove)="onModificationRemove(i)">
            </app-pepseq-form-field>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div
      class="pepseq-form__dropdown"
      [ngClass]="{ 'pepseq-form__dropdown--expanded': calculatedDropdownExpanded || editMode }"
      *ngIf="complexFieldSignal()?.calculated.length > 0">
      <div class="mb-2 d-flex align-items-center">
        <span class="pepseq-form__counter" data-testid="fields-calculated-dropdown">
          Fields calculated from {{ complexFieldSignal()?.parent.name }}
        </span>
        <button class="pepseq-form__toggle-btn" (click)="onCalculatedToggleBtnClick()" data-testid="toggle-dropdown-button"></button>
      </div>
      <div
        [nxtSortablejs]="complexFieldSignal().calculated"
        [config]="options()"
        (mouseenter)="calculatedFieldsHovered.set(true)"
        (mouseleave)="calculatedFieldsHovered.set(false)"
        class="pepseq-form__fields-container pepseq-form__fields-container--calculated-fields">
        @for (calculated of complexFieldSignal().calculated; track calculated.field_template_id; let i = $index) {
          <div
            (mouseenter)="hoveredCalculatedFieldIndex.set(i)"
            (mouseleave)="hoveredCalculatedFieldIndex.set(null)"
            class="pepseq-form__field pepseq-form__field--calculated-field"
            [ngClass]="{ 'field--wide': calculated.type === DisplayFieldType.TEXT_LONG, 'pepseq-form__field--template-edit': mode === FieldsViewMode.TEMPLATE_EDIT }">
            @if (mode === FieldsViewMode.TEMPLATE_EDIT) {
              <app-box-controls-bar
                [hovered]="hoveredCalculatedFieldIndex() === i"
                [visible]="calculatedFieldsHovered()"
                [hideCopy]="true"
                [hideEdit]="true"
                (delete)="deleteCalculated.emit(calculated)">
              </app-box-controls-bar>
            }
            <app-pepseq-form-field
              [field]="calculated"
              [editMode]="false"
              (valueChange)="onModificationValueChange(i, $event)"
              [hovered]="active && fieldHovered">
            </app-pepseq-form-field>
          </div>
        }
      </div>
    </div>
  </div>
</div>
