import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AuthAction } from '@app/_auth/auth-layout/auth-layout/auth-layout.component';

@Component({
  selector: 'app-password-layout',
  templateUrl: './password-layout.component.html',
  styleUrls: ['./password-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PasswordLayoutComponent {
  readonly AuthAction = AuthAction;
}
