import { AsyncPipe, JsonPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { ImageComponent } from '@shared/_components/image/components/image.component';
import { TextLongInputComponent } from '@shared/_components/inputs/text-long-input/text-long-input.component';
import { DevelopmentItem, DevelopmentType, Image, ImageMetaPayload } from '@shared/_models';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';

import { ButtonComponent } from '../../button/button.component';

@Component({
  selector: 'app-item-image-details',
  imports: [
    NgClass,
    ReactiveFormsModule,
    ImageComponent,
    DevelopmentTypeCssClassPipe,
    NgIf,
    JsonPipe,
    AsyncPipe,
    IconComponent,
    ButtonComponent,
    TextLongInputComponent
  ],
  templateUrl: './item-image-details.component.html',
  styleUrls: ['./item-image-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemImageDetailsComponent implements OnInit {
  developmentItem = input.required<DevelopmentItem>();
  developmentType = computed<DevelopmentType>(() => this.developmentItem()?.template?.category?.development_type);
  image = input.required<Image>();

  editMode = input.required<boolean>();
  isMain = computed(() => this.image()?.id === this.developmentItem()?.main_image_id);

  @Output() editModeChange = new EventEmitter<boolean>();
  @Output() closeModal = new EventEmitter<void>();
  @Output() openImageNameModal = new EventEmitter<void>();
  @Output() imageMetaChange = new EventEmitter<ImageMetaPayload>();

  backendErrorMessage = input<string>(null);

  readonly IconColors = IconColors;
  control: FormControl;

  ngOnInit() {
    this.#createFormControl();
  }

  #createFormControl() {
    this.control = new FormControl(this.image().description);
  }

  openImageMetaModal() {
    this.openImageNameModal.emit();
  }

  onEditBtnClick(): void {
    this.editModeChange.emit(!this.editMode());
  }

  onCloseBtnClick(): void {
    this.editModeChange.emit(false);
    this.closeModal.emit();
  }

  onSaveBtnClick(): void {
    this.imageMetaChange.emit({ description: this.control.value });
  }

  onCancelBtnClick(): void {
    this.control.setValue(this.image()?.description);
    this.editModeChange.emit(false);
  }
}
