import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, input, Input, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

import { RouteData } from '@shared/_models/route-data';
import { selectRouteDataParam } from '@shared/_root-store/router-store/selectors';

@Component({
  selector: 'app-empty-list-placeholder',
  imports: [CommonModule],
  templateUrl: './empty-list-placeholder.component.html',
  styleUrls: ['./empty-list-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyListPlaceholderComponent {
  @Input() header = '-';
  @Input() linkText = '-';
  inactive = input<boolean>();
  @Output() addItem = new EventEmitter<void>();
  showSecondaryNavigation = toSignal(this.store.select(selectRouteDataParam(RouteData.SHOW_SECONDARY_NAVIGATION)));

  constructor(private store: Store) {}

  addNewItem() {
    if (this.inactive()) {
      return;
    }
    this.addItem.emit();
  }
}
