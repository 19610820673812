import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Experiment } from '@shared/_models';

import { ExperimentsState, EXPERIMENTS_FEATURE_KEY } from './experiments.reducer';

export const selectExperimentsState = createFeatureSelector<ExperimentsState>(EXPERIMENTS_FEATURE_KEY);

export const selectExperimentsDictionary = createSelector(
  selectExperimentsState,
  (state: ExperimentsState) => state.entities
);

export const selectExperiments = createSelector(selectExperimentsDictionary, (dictionary: Dictionary<Experiment>) =>
  Object.values(dictionary)
);

export const selectExperimentsLoading = createSelector(
  selectExperimentsState,
  (state: ExperimentsState) => state.loading
);

export const selectExperimentsExporting = createSelector(
  selectExperimentsState,
  (state: ExperimentsState) => state.exporting
);

export const selectExperimentsLoaded = createSelector(
  selectExperimentsState,
  (state: ExperimentsState) => state.loaded
);

export const selectExperimentsPagination = createSelector(
  selectExperimentsState,
  (state: ExperimentsState) => state.pagination
);

export const selectExperimentsSelectedIds = createSelector(
  selectExperimentsState,
  (state: ExperimentsState) => state.selectedIds
);

export const selectExperimentById = (id: string) =>
  createSelector(selectExperimentsDictionary, dictionary => dictionary?.[id]);

export const selectSelectedAll = createSelector(
  selectExperimentsState,
  (state: ExperimentsState) => state.selectedIds.length === state.pagination.total_records
);

export const selectExperimentsWithStatus = (statusId: string) =>
  createSelector(selectExperiments, (experiments: Experiment[]) =>
    experiments.filter(obj => obj?.status?.id === statusId)
  );
