<div class="item-colors__header">
  <label class="col-sm-3 item-color__header__label">Colors</label>
</div>
<div class="item-colors__body">
  <div
    *ngFor="let color of itemColors; let i = index"
    class="item-colors__color"
    [ngClass]="['option-color-' + i, selectedColor() === color ? 'item-colors__color--selected' : '']"
    (click)="onColorClick(color, i)"
  ></div>
</div>
