import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { FileType } from '@app/shared/_models/file-type';

import { TiffViewerComponent } from './tiff-viewer/tiff-viewer.component';

@Component({
  selector: 'app-image',
  imports: [CommonModule, TiffViewerComponent],
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent {
  base64 = input.required<string>();

  isTiff = computed(() => this.base64().startsWith(`data:${FileType.TIFF};`));
}
