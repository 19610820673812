export enum Unit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  B,
  KB,
  MB,
  GB,
  TB,
  PB,
  EB,
  ZB,
  YB,
}
export interface IFileUploadConfig {
  maxFiles: number;
  maxFileSizeMB: number;
  allowedFileTypes: string; // comma separated
  uploadURL: string;
  deleteURL: string;
  unit: Unit;
}
