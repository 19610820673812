import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { upperFirst } from 'lodash-es';
import { Subject } from 'rxjs';

import { ROOT_CATEGORY_ID } from '@app/domain/category';
import { ButtonComponent } from '@app/shared/_components/button/button.component';
import { GenericModalComponent } from '@app/shared/_components/generic-modal/generic-modal.component';
import { ModalHeaderCenterComponent } from '@app/shared/_components/modal-header-center/modal-header-center.component';
import { DevelopmentType, ProjectDto } from '@app/shared/_models';
import { ActionButton } from '@app/shared/_models/actionButton';
import { ExperimentParamsService } from '@app/shared/_services/experiment/experiment-params.service';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { DevelopmentItemService } from '@shared/_services/development-item.service';
import { NavigationService } from '@shared/_services/navigation.service';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';

@Component({
  selector: 'app-item-details-edit-modal',
  imports: [NgIf, DevelopmentTypeCssClassPipe, ButtonComponent, GenericModalComponent, ModalHeaderCenterComponent, NgTemplateOutlet],
  templateUrl: './item-details-edit-modal.component.html',
  styleUrls: ['./item-details-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailsEditModalComponent implements OnInit {
  project = input.required<ProjectDto>();
  @Output() closeModal = new EventEmitter<null>();
  @Output() cancelBtnClick = new EventEmitter<null>();
  @Output() saveBtnClick = new EventEmitter<null>();
  @Input() itemName: string;
  @Input() developmentType: DevelopmentType;
  @Input() roId: string;
  header: string;
  message: string;
  listOfButtonName: string;
  sectionName: string;
  imgSrc: string;
  closeButton: ActionButton = { text: '', style: '' };
  acceptButton: ActionButton = { text: '', style: '' };

  subject: Subject<boolean>;
  CloseReason = CloseReason;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly activeModal: NgbActiveModal,
    private readonly developmentItemService: DevelopmentItemService,
    private navigationService: NavigationService,
    private readonly experimentParamsService: ExperimentParamsService
  ) {}

  ngOnInit() {
    this.sectionName = this.developmentItemService.getEditActiveSectionName();
    if (this.itemName) {
      this.header = "Can't delete ";
    } else {
      this.header = 'You are editing a section ';
      this.message =
        'You are currently editing ' + upperFirst(this.sectionName) + ' section, you should save or cancel editing before further proceed.';
    }
    if (this.developmentType == DevelopmentType.experiment) {
      this.listOfButtonName = this.project()?.development_type_names.research_objects;
    } else if (this.developmentType == DevelopmentType.researchObject) {
      this.listOfButtonName = this.project()?.development_type_names.experiments;
    }
    this.imgSrc = this.itemName ? 'toast_icon_danger.png' : 'toast_icon_warning.png';

    if (this.itemName) {
      this.acceptButton.text = 'Close';
      this.closeButton.text = 'List of ' + this.listOfButtonName;
    } else {
      this.acceptButton.text = 'Save changes';
      this.closeButton.text = 'Discard changes';
    }
  }

  close(reason: CloseReason) {
    this.closeModal.emit();
    this.activeModal.close();
    if (reason === CloseReason.CLOSE && !this.itemName) {
      this.discardChanges();
    }
    if (reason === CloseReason.ACCEPT && !this.itemName) {
      this.saveChanges();
    }
  }

  discardChanges() {
    this.developmentItemService.emitSectionEditCancelEvent();
  }

  saveChanges() {
    this.developmentItemService.emitSectionEditSaveEvent();
  }

  showRelatedItems() {
    this.close(CloseReason.CLOSE);

    if (this.developmentType === DevelopmentType.experiment) {
      return;
    }

    this.experimentParamsService.patchCurrentParams({
      ro_ids: [this.roId]
    });
    const workspaceId = this.activatedRoute.snapshot.paramMap.get('workspaceId');
    const projectId = this.activatedRoute.snapshot.paramMap.get('projectId');
    this.navigationService.goToExperimentsListWithParams(workspaceId, projectId, ROOT_CATEGORY_ID, { page: 1, ro_ids: [this.roId] });
  }
}
