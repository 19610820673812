import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';

import { getDefaultPagination } from '@shared/_helpers';
import { PaginationDto, ResearchObject, SortDto, SortOrderTypes } from '@shared/_models';

import * as actions from './research-objects.actions';

export const RESEARCH_OBJECTS_FEATURE_KEY = 'researchObjects';
export const researchObjectsAdapter: EntityAdapter<ResearchObject> = createEntityAdapter<ResearchObject>();
export interface ResearchObjectsState extends EntityState<ResearchObject> {
  readonly pagination: PaginationDto;
  readonly sort: SortDto;
  readonly loading: boolean;
  readonly exporting: boolean;
  readonly loaded: boolean;
  // TODO: Get parameter names for lowest category table view RO from API
  readonly selectedIds: string[];
  readonly search: string;
}

export const initialState: ResearchObjectsState = researchObjectsAdapter.getInitialState({
  pagination: getDefaultPagination(),
  sort: null,
  loading: false,
  exporting: false,
  loaded: false,
  // TODO: Get parameter names for lowest category table view RO from API
  selectedIds: [],
  search: ''
});

export const reducer: ActionReducer<ResearchObjectsState> = createReducer(
  initialState,

  // LOAD
  on(actions.loadResearchObjects, state => {
    return { ...state, loading: true, loaded: false };
  }),
  on(actions.loadResearchObjectsSuccess, (state, action) =>
    researchObjectsAdapter.setAll(action.data.data, {
      ...state,
      pagination: action.data.pagination,
      sort: action.data.sort,
      loading: false,
      loaded: true,
      search: action.data.pagination.search
    })
  ),

  on(actions.loadResearchObjectsFailure, state => {
    return { ...state, loading: false, loaded: false };
  }),

  on(actions.loadResearchObjectsAndStayOnCurrentPageSuccess, (state, action) => {
    return researchObjectsAdapter.setAll(action.data.data, {
      ...state,
      pagination: action.data.pagination,
      sort: action.data.sort,
      loading: false,
      loaded: true,
      search: action.data.pagination.search
    });
  }),

  // SELECT
  on(actions.getAllResearchObjectsIdsSuccess, (state, action) => {
    const ids = action.data.ids;

    return { ...state, selectedIds: ids };
  }),
  on(actions.selectResearchObjectAction, (state, action) => ({ ...state, selectedIds: [...state.selectedIds, action.id] })),
  on(actions.selectResearchObjectsAction, (state, action) => {
    const idsSet = new Set([...state.selectedIds, ...action.ids]);
    const ids = Array.from(idsSet);

    return { ...state, selectedIds: [...ids] };
  }),
  on(actions.unselectResearchObject, (state, action) => {
    const selectedIds = state.selectedIds.filter(id => id !== action.id);

    return { ...state, selectedIds };
  }),
  on(actions.unselectResearchObjects, (state, action) => {
    const idsToRemove = action.ids;
    const selectedIds = state.selectedIds.filter(id => !idsToRemove.includes(id));

    return { ...state, selectedIds };
  }),
  on(actions.clearResearchObjectSelection, state => ({ ...state, selectedIds: [] })),

  // DELETE
  on(actions.deleteResearchObjectsSuccess, (state, action) => {
    return researchObjectsAdapter.removeMany(action.deletedResearchObjectsIds, { ...state });
  }),

  on(actions.resetSorting, state => {
    return {
      ...state,
      sort: { prop: 'date_created', order: SortOrderTypes.DESCENDING }
    };
  }),

  on(actions.resetSearching, state => {
    return {
      ...state,
      search: ''
    };
  }),

  on(actions.clearResearchObjects, state => {
    return researchObjectsAdapter.removeAll(state);
  }),

  on(actions.updateImageMetaSuccess, (state, action) => {
    const { researchObjectId, imageMeta } = action;
    const researchObjectToUpdate = state.entities[researchObjectId];

    return researchObjectsAdapter.updateOne(
      {
        id: researchObjectId,
        changes: {
          images: researchObjectToUpdate.images.map(image => (image.id === imageMeta.id ? imageMeta : image))
        }
      },
      state
    );
  }),

  on(actions.generateCSV, state => {
    return { ...state, exporting: true };
  }),

  on(actions.generateCSVSuccess, state => {
    return { ...state, exporting: false };
  }),

  on(actions.generateCSVFailure, state => {
    return { ...state, exporting: false };
  }),

  on(actions.generateMultipleCSV, state => {
    return { ...state, exporting: true };
  }),

  on(actions.generateMultipleCSVSuccess, state => {
    return { ...state, exporting: false };
  }),

  on(actions.generateMultipleCSVFailure, state => {
    return { ...state, exporting: false };
  })
);
