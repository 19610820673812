import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { CategoryDto, CategoryWithChildrenDto, UpdateCategoryDto, UpdateStatusesDto } from '../../_models';
import { CATEGORIES_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class CategoriesApiService {
  constructor(private readonly http: HttpClient) {}

  getList(params?: Params) {
    return this.http.get<CategoryDto[]>(CATEGORIES_API_URL, { params });
  }

  get(categoryId: string) {
    return this.http.get<CategoryWithChildrenDto>(`${CATEGORIES_API_URL}/${categoryId}`);
  }

  add(category: CategoryDto) {
    return this.http.post<CategoryDto>(`${CATEGORIES_API_URL}`, category);
  }

  update(categoryId: string, changes: UpdateCategoryDto) {
    return this.http.patch<CategoryDto>(`${CATEGORIES_API_URL}/${categoryId}`, changes);
  }

  duplicate(categoryId: string, name: string) {
    return this.http.post<CategoryDto>(`${CATEGORIES_API_URL}/${categoryId}/duplicate`, { name });
  }

  delete(categoryId: string) {
    return this.http.delete<string>(`${CATEGORIES_API_URL}/${categoryId}`);
  }

  updateStatuses(categoryId: string, changes: UpdateStatusesDto) {
    return this.http.put<CategoryDto>(`${CATEGORIES_API_URL}/${categoryId}/statuses`, changes);
  }
}
