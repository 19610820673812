import { HttpInterceptorFn, HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, of, throwError } from 'rxjs';

import { AuthService } from '@app/_auth/auth.service';

import { ToastService } from '../_modules/toast/toast.service';

export const errorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const toastService = inject(ToastService);

  return next(req).pipe(
    catchError(error => {
      if (req.method === 'GET' && error.status === 404 && req.responseType !== 'blob') {
        router.navigateByUrl('app/not-found');

        return of(null);
      }

      if (error.status === 401) {
        authService.signOut();
        toastService.show('Your session expired', {
          header: 'Please log again',
          type: 'danger',
          delay: 9000
        });
      }

      return throwError(() => error);
    })
  );
};
