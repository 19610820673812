<div class="nav-actions nav-actions-sm">
  <div class="row row-cols-auto align-items-center">
    <div class="col px-2 pe-lg-3">
      <button
        type="button"
        data-testid="show-selected-items-button"
        class="btn btn-sm btn-link btn-action"
        [ngClass]="{ 'active': onlySelectedShowed }"
        [disabled]="isEmptySelection()"
        (click)="onShowSelectedBtnClick()"
        (mouseenter)="iconSelected.onHoverChange(true)"
        (mouseleave)="iconSelected.onHoverChange(false)"
      >
        <app-icon
          #iconSelected
          width="0.8125rem"
          height="0.8125rem"
          [fileName]="onlySelectedShowed ? 'eye_closed_icon.svg' : 'eye_icon.svg'"
          [color]="onlySelectedShowed ? IconColors.blue : iconColorBase()"
          [hoverColor]="IconColors.blue"
        ></app-icon>
        Show: {{ selectedItemsAmount() }} selected
      </button>
    </div>
    <ng-container *ngIf="!onlySelectedShowed">
      <div class="col-vr"></div>
      <div class="col px-2 px-lg-3">
        <span class="btn-text">Select:</span>
        <button type="button" class="btn btn-sm btn-link btn-action" (click)="onSelectAll()">All</button>
        <button type="button" class="btn btn-sm btn-link btn-action" (click)="onSelectAllOnCurrentPage()">
          Displayed
        </button>
      </div>
      <div class="col-vr"></div>
      <div class="col pe-0 ps-2 ps-lg-3">
        <span class="btn-text">Unselect:</span>
        <button
          data-testid="unselect-all-items-button"
          type="button"
          class="btn btn-sm btn-link btn-action"
          [disabled]="isEmptySelection()"
          (click)="onUnselectAll()"
        >
          All
        </button>
      </div>
    </ng-container>
    <div class="col ms-auto px-2">
      <button
        type="button"
        class="btn btn-sm btn-link btn-action"
        (click)="onImport($event)"
        (mouseenter)="iconImport.onHoverChange(true)"
        (mouseleave)="iconImport.onHoverChange(false)"
      >
        <app-icon
          #iconImport
          width="0.8125rem"
          height="0.8125rem"
          fileName="import_icon_3.svg"
          [color]="IconColors.darkGrey"
          [hoverColor]="IconColors.blue"
        ></app-icon>
        Import
      </button>
      <button
        type="button"
        class="btn btn-sm btn-link btn-action ms-1 ms-lg-3"
        [ngClass]="{ 'active': exporting(), 'pending': exporting() }"
        [disabled]="isEmptySelection() || exporting()"
        [attr.tabindex]="exporting() ? '-1' : '0'"
        (click)="onExportSelected($event)"
        (mouseenter)="iconExport.onHoverChange(true)"
        (mouseleave)="iconExport.onHoverChange(false)"
      >
        <app-icon
          #iconExport
          width="0.8125rem"
          height="0.8125rem"
          fileName="export_icon_3.svg"
          [color]="exporting() ? IconColors.blue : iconColorBase()"
          [hoverColor]="IconColors.blue"
        ></app-icon>

        <span class="btn-spinner">
          <span class="spinner-border spinner-border-sm spinner-action" aria-hidden="true"></span>
        </span>

        <span class="btn-label">
          <span class="label-active">Exporting</span>
          <span class="label">Export</span>
        </span>
      </button>
      <button
        type="button"
        class="btn btn-sm btn-link btn-action ms-1 ms-lg-3"
        [disabled]="isEmptySelection()"
        (click)="onDeleteSelected($event)"
        (mouseenter)="iconDelete.onHoverChange(true)"
        (mouseleave)="iconDelete.onHoverChange(false)"
      >
        <app-icon
          #iconDelete
          width="0.8125rem"
          height="0.8125rem"
          fileName="trash_4_icon.svg"
          [color]="iconColorBase()"
          [hoverColor]="IconColors.blue"
        ></app-icon>
        Delete
      </button>
    </div>
  </div>
</div>
