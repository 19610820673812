import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';

import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { GENERIC_ERROR_MESSAGE, toMessage } from '@app/domain/error-handling';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { NavigationService } from '@shared/_services/navigation.service';
import { SettingsApiService } from '@shared/_services/settings/settings-api.service';

import { notifyAboutError, navigateToDefaultRoute, loadSettings, loadSettingsSuccess, loadSettingsFailure } from './app.actions';

@Injectable()
export class AppEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly navigationService: NavigationService,
    private readonly workspaceService: WorkspaceService,
    private readonly settingsApiService: SettingsApiService
  ) {}

  notifyAboutError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notifyAboutError),
        tap(({ notification, errorResponse }) => {
          const getToastProps = (): typeof GENERIC_ERROR_MESSAGE => {
            if (notification) return notification;

            if (errorResponse)
              return {
                content: toMessage(errorResponse),
                header: GENERIC_ERROR_MESSAGE.header
              };

            return GENERIC_ERROR_MESSAGE;
          };

          const { content, header } = getToastProps();

          this.toastService.show(content, {
            header,
            type: 'danger',
            delay: 5000
          });
        })
      ),
    { dispatch: false }
  );

  navigateToDefaultRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToDefaultRoute),
        switchMap(() =>
          this.workspaceService.getInitialWorkspace().pipe(tap(workspace => this.navigationService.goToProjectsList(workspace.id)))
        )
      ),
    { dispatch: false }
  );

  loadSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSettings),
      switchMap(() =>
        this.settingsApiService.get().pipe(
          map(settings => loadSettingsSuccess({ settings })),
          catchError(errorResponse => of(loadSettingsFailure({ errorResponse })))
        )
      )
    )
  );

  loadSettingsFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSettingsFailure),
      map(({ errorResponse }) => notifyAboutError({ errorResponse }))
    )
  );
}
