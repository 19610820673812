import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { stripHtmlTags } from '@shared/_helpers/strip-html-tags';

export function stripHtmlTextMaxLengthValidator(maxLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || typeof control.value !== 'string') {
      return null;
    }

    const strippedValue = stripHtmlTags(control.value);

    return strippedValue.length > maxLength ? { maxlength: { requiredLength: maxLength } } : null;
  };
}
