import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, input, Input, Output, signal } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';
import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';

@Component({
  selector: 'app-input-with-clear',
  imports: [NgClass, NgIf, IconComponent],
  templateUrl: './input-with-clear.component.html',
  styleUrls: ['./input-with-clear.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputWithClearComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() iconPath: string; // for FontAwesome icons
  icon = input<{ name: string; color: IconColors; width: string; height: string }>(); // for our app-icon component
  @Input() hasError: boolean;
  @Input() type: 'text' | 'number' = 'text';
  @Output() inputCleared = new EventEmitter<void>();
  ngModel = signal('');

  constructor(controlDirective: NgControl) {
    controlDirective.valueAccessor = this;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.#onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.#onTouched = fn;
  }
  setDisabledState(): void {}

  writeValue(value: string): void {
    this.ngModel.set(value);
  }

  onClear() {
    this.ngModel.set('');
    this.#onTouched();
    this.#onChange(this.ngModel());
    this.inputCleared.emit();
  }

  onValueChange(value: string) {
    this.ngModel.set(value);
    this.#onTouched();
    this.#onChange(this.ngModel());
  }

  #onChange = (value: string) => void {};
  #onTouched = () => void {};
}
