import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, OnInit, output, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SortablejsModule } from 'nxt-sortablejs';
import { filter, takeUntil } from 'rxjs';

import { getShortGUID } from '@shared/_helpers/get-short-guid';

import { DEFAULT_SELECT_PLACEHOLDER, NO_VALUE_COLOR_INDEX } from '../../../domain/field/placeholders/dropdown';
import { AutoDestroyDirective } from '../../_directives/auto-destroy/auto-destroy.directive';
import { DropdownItem, DropdownValues } from '../../_models';
import { DropdownSelectComponent } from '../inputs/dropdown-select/dropdown-select.component';
import { TextInputComponent } from '../inputs/text-input/text-input.component';
import { ItemColorsComponent } from '../item-colors/item-colors.component';
import { DropdownItemErrors } from './utils/dropdown-item-errors';

const DROPDOWN_ITEMS_LIMIT = 20;
const DROPDOWN_ITEM_CHARACTERS_LIMIT = 30;

@Component({
  selector: 'app-dropdown-manage-values',
  imports: [
    DropdownSelectComponent,
    TextInputComponent,
    CommonModule,
    SortablejsModule,
    ReactiveFormsModule,
    NgbTooltip,
    ItemColorsComponent
  ],
  templateUrl: './dropdown-manage-values.component.html',
  styleUrls: ['./dropdown-manage-values.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownManageValuesComponent extends AutoDestroyDirective implements OnInit {
  data = input<DropdownValues>();
  backendErrorMessage = input<string>();
  colorsEnabled = input<boolean>();
  label = input<string>();
  defaultLabel = input<string>();
  placeholder = input<string>();
  addLabel = input<string>();
  removeConfirmationNeeded = input<boolean>(false);
  itemsChange = output<Partial<DropdownItem>[]>();
  defaultValueChange = output<string>();
  removeConfirmationModal = output<{ index: number; itemId: string }>();
  haveItemError = output<boolean>();
  form: FormGroup;
  selectedItem: FormGroup = null;
  itemIndex: number = null;
  itemErrors = signal<DropdownItemErrors[]>([]);
  dropdownItems = signal<DropdownItem[]>([]);
  dropdownItemsLimitReached = computed(() => this.dropdownItems().length >= DROPDOWN_ITEMS_LIMIT);
  defaultValueOptions = computed<DropdownItem[]>(() =>
    [{ id: getShortGUID(), name: this.placeholder() ?? DEFAULT_SELECT_PLACEHOLDER } as DropdownItem].concat(
      this.dropdownItems()
    )
  );
  options = computed(() => ({
    onEnd: $event => {
      if ($event.newIndex === $event.oldIndex) {
        return;
      }

      this.onDragEnd(this.selectItemsFormArray.controls[$event.oldIndex].get('id').value, $event.newIndex);
    }
  }));
  get selectItemsFormArray() {
    return this.controls.selectItems as FormArray;
  }
  #defaultItem: DropdownItem;

  constructor(private fb: FormBuilder) {
    super();
    this.#initForm();
    this.selectItemsFormArray.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(values => {
      this.dropdownItems.set(values);
      this.itemsChange.emit(values.map((value, index) => ({ ...value, position: index + 1 })));
      this.#onSelectItemsChange(values);
    });
    this.controls.dropdownDefaultValue.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.defaultValueChange.emit(value);
      this.#rememberDefaultItem(value);
    });
  }

  get controls() {
    return this.form.controls;
  }

  ngOnInit() {
    this.#initFormValue();
  }

  onSelectItemClick(item: FormGroup, itemIndex: number) {
    this.selectedItem = item;
    this.itemIndex = itemIndex;
    this.form.get('color').patchValue(item.get('color').value);
  }

  onAddSelectItem(name: string) {
    const newSelectItem = this.fb.group({
      id: new FormControl<string>(getShortGUID()),
      name: new FormControl<string>(name),
      color: new FormControl<number>(NO_VALUE_COLOR_INDEX)
    });

    this.selectItemsFormArray.push(newSelectItem);
  }

  onRemoveSelectItem(index: number, id: string) {
    if (this.removeConfirmationNeeded()) {
      this.removeConfirmationModal.emit({ index: index, itemId: id });
    } else {
      this.handleRemoveConfirmation(index);
    }
  }

  handleRemoveConfirmation(index: number) {
    const selectedItemName = this.selectItemsFormArray.controls[index].get('name').value;
    this.selectItemsFormArray.removeAt(index);
    this.checkForItemErrors();

    if (this.selectItemsFormArray.length === 0 || selectedItemName === this.controls.dropdownDefaultValue.value) {
      this.controls.dropdownDefaultValue.setValue(null);
    }
  }

  onColorChange(color: number) {
    this.selectedItem?.get('color').patchValue(color);
  }

  onDragEnd(id: string, position: number) {
    const startIndex = this.selectItemsFormArray.controls.findIndex(control => control.get('id').value === id);
    const indexToMove = startIndex === -1 ? 0 : startIndex;
    const [itemToMove] = this.selectItemsFormArray.controls.splice(indexToMove, 1);
    this.selectItemsFormArray.controls.splice(position + 1, 0, itemToMove);
    // to emit itemsChange event
    this.selectItemsFormArray.controls.forEach((control, index) => control.patchValue({ position: index + 1 }));
  }

  checkForItemErrors(): void {
    const error = (control: FormControl, index: number) => {
      const name = control.get('name').value;
      return {
        index: index - 1,
        errors: {
          isDuplicated: this.#isDuplicateName(name, index),
          isToLong: name?.length > DROPDOWN_ITEM_CHARACTERS_LIMIT
        }
      };
    };

    const errors = this.selectItemsFormArray.controls.map(error);
    this.itemErrors.set(errors.filter(item => Object.values(item.errors).some(Boolean)));
    this.haveItemError.emit(this.itemErrors().length > 0);
  }

  hasError(index: number) {
    return this.itemErrors().some(item => item.index === index);
  }

  hasDuplicatedError(index: number) {
    return this.itemErrors().some(item => item.index === index && item.errors.isDuplicated);
  }

  hasToLongError(index: number) {
    return this.itemErrors().some(item => item.index === index && item.errors.isToLong);
  }

  #isDuplicateName(name: string, currentIndex: number): boolean {
    const trimmedName = name?.trim();

    if (!trimmedName) return false;

    return this.selectItemsFormArray.controls.some(
      (control, index) => index !== currentIndex && control.get('name').value?.trim() === trimmedName
    );
  }

  #initForm() {
    this.form = this.fb.group({
      dropdownDefaultValue: new FormControl(),
      color: new FormControl<number>(0),
      colorsEnabled: new FormControl<boolean>(false),
      selectItems: this.fb.array([])
    });

    this.controls.colorsEnabled.valueChanges
      .pipe(
        filter(enabled => enabled),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.#setColorForAllOptions());
  }

  #initFormValue() {
    const defaultValue = this.data()?.defaultValue ?? DEFAULT_SELECT_PLACEHOLDER;
    this.controls.dropdownDefaultValue.setValue(defaultValue);

    if (this.data()?.values?.length) {
      const sortedValues = [...this.data().values].sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
      const controls = sortedValues.map(value =>
        this.fb.group({
          id: new FormControl<number | string>(value.id),
          name: new FormControl<string>(value.name),
          color: new FormControl<number>(value.color)
        })
      );

      controls.forEach(control => this.selectItemsFormArray.push(control));
    }
    this.#rememberDefaultItem(defaultValue);
  }

  #setColorForAllOptions() {
    this.selectItemsFormArray.controls
      .filter(control => !control.get('color').value)
      .forEach(control => {
        control.get('color').setValue(NO_VALUE_COLOR_INDEX);
      });
  }

  #rememberDefaultItem(name: string) {
    this.#defaultItem = this.selectItemsFormArray.value.find(value => value.name === name);
  }

  #onSelectItemsChange(values: DropdownItem[]) {
    if (!this.#defaultItem) return;

    const defaultValue = values.find(value => value.id === this.#defaultItem.id);

    if (!defaultValue || defaultValue.name === this.#defaultItem.name) return;

    this.controls.dropdownDefaultValue.setValue(defaultValue.name);
  }
}
