import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-role-description',
  imports: [],
  templateUrl: './role-description.component.html',
  styleUrl: './role-description.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleDescriptionComponent {
  role = input.required<string>();
  roleDescription = input.required<string>();
  unableTo = input<string[]>();
}
