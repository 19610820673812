import { NgClass } from '@angular/common';
import { Component, computed, input } from '@angular/core';
@Component({
  selector: 'app-file-thumb',
  imports: [NgClass],
  template: `
    <div class="file-thumb" [ngClass]="mainClass()"></div>
  `,
  styleUrls: ['./file-thumb.component.scss']
})
export class FileThumbComponent {
  error = input<boolean>(false);
  image = input<boolean>(false);

  mainClass = computed<string>(() =>
    [this.error() ? 'file-thumb--error' : null, this.image() && !this.error() ? 'file-thumb--image' : null].filter(Boolean).join(' ')
  );
}
