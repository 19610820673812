<app-input-with-validation
  [control]="formControl()"
  [config]="config"
  [backendErrorMessage]="backendErrorMessage"
  [externalTouched]="externalTouched"
>
  <ckeditor
    #editor
    [editor]="Editor"
    [config]="config"
    [data]="formControl().value"
    [ngClass]="{ invalid: markAsInvalid }"
    (input)="input($event)"
    (change)="change($event)"
    (blur)="blurred.emit()"
  ></ckeditor>
  <app-text-counter
    *ngIf="counterOptions?.show"
    [length]="length$ | async"
    [maxLength]="counterOptions.maxLength"
  ></app-text-counter>
</app-input-with-validation>
