<app-item-details-section
  [sectionName]="areaName()"
  [sectionType]="SectionType.IMAGES"
  [showAddMoreBtn]="editable()"
  [showEditBtn]="editable()"
  [activeArea]="sectionActive()"
  (addMore)="addImageBtnClick()"
  (edit)="onEdit()"
>
  <ng-container *ngIf="areaName()" header-elements>
    <button
      data-testid="tab"
      *ngFor="let section of sections(); trackBy: trackById"
      class="tab"
      (click)="changeTab(section.id)"
      [ngClass]="{ 'tab--active': sectionId() === section.id }"
    >
      {{ section.name }}
    </button>
  </ng-container>

  <div
    data-testid="images-carousel"
    class="images"
    [ngClass]="{
      'images--research-object': developmentType() === DevelopmentType.researchObject,
      'images--experiment': developmentType() === DevelopmentType.experiment
    }"
  >
    <ngb-carousel
      data-testid="images-carousel-slides"
      [wrap]="false"
      [showNavigationArrows]="false"
      #ngbCarousel
      *ngIf="images()?.length > 0; else noImage"
      [interval]="100000000000000000000"
      class="carousel--pagination-on-hover"
    >
      <ng-container *ngFor="let image of images()">
        <ng-template ngbSlide>
          <div class="images__wrapper" [class.images__wrapper--main]="image.id === mainImageId()">
            <ng-container *ngTemplateOutlet="nextPrevBtns"></ng-container>
            <div class="images__image">
              <span *ngIf="image.id === mainImageId()" data-testid="main-image-icon" class="images__main-icon">
                main image
              </span>
              <span
                class="images__details-icon-wrapper"
                (click)="openImageDetailsModal(image)"
                [ngClass]="developmentType() | developmentTypeCSSClass"
                data-testid="item-details-button">
                <img class="images__details-icon" src="../../../../../../assets/icons/magnifying_glass_icon.svg"
                     alt="image details icon" />
              </span>
              <app-image [base64]="image.content?.base64"></app-image>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ngb-carousel>

    <ng-template #noImage>
      <div
        data-testid="item-add-image-field"
        class="images__wrapper images__wrapper--placeholder"
        [class.images__wrapper--editable]="editable()"
        (click)="addImageBtnClick()"
      >
        <div class="images__placeholder" (click)="onImagePlaceholderClick()">
          <i *ngIf="developmentType() === DevelopmentType.researchObject"
             class="images__placeholder-icon images__placeholder-icon_ro"></i>
          <i *ngIf="developmentType() === DevelopmentType.experiment"
             class="images__placeholder-icon images__placeholder-icon_exp"></i>
          <span class="images__placeholder-text">
            <span data-testid="item-add-image-placeholder" href="#"
                  class="images__placeholder-link">Add {{ areaName() }}</span>
          </span>
        </div>
      </div>
    </ng-template>
  </div>
</app-item-details-section>

<ng-template #nextPrevBtns>
  <ng-container *ngIf="images()?.length > 1">
    <button
      data-testid="image-carousel-previous-button"
      class="images__arrow-btn images__arrow-btn--previous {{ developmentType() | developmentTypeCSSClass }}"
      (click)="onCarouselPrevious()"
    ></button>
    <button
      data-testid="image-carousel-next-button"
      class="images__arrow-btn images__arrow-btn--next {{ developmentType() | developmentTypeCSSClass }}"
      (click)="onCarouselNext()"
    ></button>
  </ng-container>
</ng-template>

<ng-template #imageDetailsModal let-modal>
  <app-item-image-details
    [developmentItem]="developmentItem()"
    [image]="selectedImage()"
    [editMode]="editMode()"
    [backendErrorMessage]="imageMetaErrorDetails()"
    (editModeChange)="editMode.set($event)"
    (imageMetaChange)="onSaveImageMeta($event)"
    (openImageNameModal)="openImageNameModal(selectedImage())"
    (closeModal)="onImageDetailsModalClose(modal)">
  </app-item-image-details>
</ng-template>

<ng-template #imageNameModal let-modal>
  <app-generic-edit-modal
    [value]="selectedImage()?.name"
    [header]="editNameModalHeader()"
    [developmentType]="developmentType()"
    [config]="{ label: 'Image name', name: 'image name' }"
    [validators]="imageNameValidators"
    [backendErrorMessage]="imageMetaErrorName()"
    [errorMessages]="{ isDuplicate: 'An image with this name already exists in this object.' }"
    placeholder="Enter image name"
    (save)="onSaveImageName($event, modal)"
    (closeModal)="onImageNameModalClose(modal)">
  </app-generic-edit-modal>
</ng-template>
