import { CommonModule } from '@angular/common';
import { Component, computed, Inject, input, OnDestroy, OnInit, output, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { isNil, omitBy } from 'lodash-es';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { addErrorToForm } from '@app/domain/error-handling';
import { AppSettings } from '@app/shared/_configuration';
import { ProjectsStoreService } from '@app/shared/_root-store/projects-store/projects-store.service';
import { UsersStoreService } from '@app/shared/_root-store/users-store/users-store.service';
import { ProjectsApiService } from '@app/shared/_services/project';
import { GenericModalComponent } from '@shared/_components/generic-modal/generic-modal.component';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { InputWithClearComponent } from '@shared/_components/inputs/input-with-clear/input-with-clear.component';
import { InputWithValidationComponent } from '@shared/_components/inputs/input-with-validation/input-with-validation.component';
import { TextLongInputComponent } from '@shared/_components/inputs/text-long-input/text-long-input.component';
import { ItemColorsComponent } from '@shared/_components/item-colors/item-colors.component';
import { ModalHeaderLeftComponent } from '@shared/_components/modal-header-left/modal-header-left.component';
import { StatusDropdownComponent } from '@shared/_components/status-dropdown/status-dropdown.component';
import {
  ActiveStatus,
  CreateProjectDto,
  DevelopmentType,
  DropdownItem,
  EditMode,
  ProjectDto,
  ProjectProxy,
  UpdateProjectDto,
  UserDto,
  WorkspaceDto,
  WorkspaceProjectCategoryDto,
  WorkspaceProxy
} from '@shared/_models';
import { ProjectActionsModalComponent } from '@shared/_modules/project/project-actions-modal/project-actions-modal.component';
import { ModalType } from '@shared/_modules/project/utils';

import { ACTION_TYPE } from './utils/action_type';
import { WorkspaceManageProjectsCategoriesComponent } from '../../../../_workspaces/workspace/workspace-manage-projects-categories/workspace-manage-projects-categories.component';
import { DropdownSelectComponent } from '../../../_components/inputs/dropdown-select/dropdown-select.component';

@Component({
  selector: 'app-project-edit-add',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProjectActionsModalComponent,
    GenericModalComponent,
    IconComponent,
    InputWithClearComponent,
    InputWithValidationComponent,
    TextLongInputComponent,
    StatusDropdownComponent,
    ItemColorsComponent,
    ModalHeaderLeftComponent,
    WorkspaceManageProjectsCategoriesComponent,
    DropdownSelectComponent
  ],
  templateUrl: './project-edit-add.component.html',
  styleUrls: ['./project-edit-add.component.scss']
})
export class ProjectEditAddComponent implements OnInit, OnDestroy {
  modalType = input<ModalType>();
  selectedProject = input<ProjectDto>();
  workspace = input<WorkspaceDto>();
  closeModal = output<void>();
  deleteConfirmationModal = viewChild('deleteConfirmationModal');
  manageCategoryModal = viewChild('manageCategoryModal');

  categorySelectItems = computed<DropdownItem[]>(() => {
    const categories =
      this.workspace()
        ?.project_categories?.map(category => ({
          id: category.position,
          name: category.name
        }))
        .sort((a, b) => a.id - b.id) || [];
    return [{ id: 0, name: 'Uncategorized' }, ...categories];
  });

  isCurrentUserOwnerOrDeputyOfWorkspace = computed(() => {
    const workspaceProxy = new WorkspaceProxy(this.workspace(), this.currentUser());

    return workspaceProxy.isOwner || workspaceProxy.isDeputy;
  });

  readonly destroy$: Subject<boolean> = new Subject<boolean>();
  readonly ModalType = ModalType;
  readonly ACTION_TYPE = ACTION_TYPE;
  readonly DevelopmentType = DevelopmentType;
  form: FormGroup;
  statusSelectItems: DropdownItem[] = [
    { id: 1, name: ActiveStatus.ACTIVE },
    { id: 2, name: ActiveStatus.INACTIVE }
  ];
  projectProxy: ProjectProxy;
  IconColors = IconColors;
  currentUser$ = this.usersStoreService.currentUser$;
  currentUser = toSignal(this.currentUser$);

  get markDescriptionAsInvalid(): boolean {
    const control = this.controls.description;

    return control?.invalid && control?.touched;
  }

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    public readonly activeModal: NgbActiveModal,
    private readonly projectsApiService: ProjectsApiService,
    private readonly projectsStoreService: ProjectsStoreService,
    private readonly ngbModal: NgbModal,
    private readonly workspaceService: WorkspaceService,
    private readonly usersStoreService: UsersStoreService
  ) {}

  ngOnInit() {
    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe(currentUser => {
      this.projectProxy = new ProjectProxy(currentUser, this.selectedProject());
      this.initForm(currentUser);
    });
  }

  get controls() {
    return this.form.controls;
  }

  private initForm(currentUser: UserDto) {
    const defaultCategoryName =
      this.modalType() === ModalType.NEW
        ? this.workspace()?.project_categories?.find(
            category => category.id === this.workspace()?.default_project_category_id
          )?.name || this.categorySelectItems()[0].name
        : this.selectedProject()?.project_category?.name || this.categorySelectItems()[0].name;
    this.form = new FormGroup({
      name: new FormControl(this.selectedProject()?.name, Validators.required),
      category: new FormControl<string>(defaultCategoryName),
      status: new FormControl<string>(this.selectedProject()?.status || 'active'),
      color: new FormControl<number>(this.selectedProject()?.color || 0),
      description: new FormControl<string>(this.selectedProject()?.description, Validators.maxLength(120)),
      owners: new FormControl<UserDto[]>(
        [this.selectedProject()?.user_crt || currentUser],
        [Validators.required, Validators.maxLength(1)]
      )
    });
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.CLOSE) {
      this.closeModal.emit();
    } else if (reason === CloseReason.ACCEPT) {
      this.onSaveProject();
    }
  }

  onColorChange(color: number) {
    this.controls.color.setValue(color);
  }

  onSaveProject() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.saveChanges();
  }

  openDeleteModal() {
    this.ngbModal.open(this.deleteConfirmationModal(), this.settings.MODAL_DEFAULT_CONFIG);
  }

  onDeleteConfirmation(deleteConfirmation: NgbModalRef) {
    this.projectsApiService
      .delete(this.selectedProject().id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.projectsStoreService.loadProjects(this.workspaceService.currentWorkspaceId());
        deleteConfirmation.close();
        this.closeModal.emit();
      });
  }

  private saveChanges() {
    const mode = this.modalType() === ModalType.NEW ? EditMode.ADD : EditMode.EDIT;
    const addOrUpdate =
      mode === EditMode.ADD
        ? this.projectsApiService.add(this.getProject(mode) as CreateProjectDto)
        : this.projectsApiService.update(this.selectedProject().id, this.getProject(mode));

    addOrUpdate
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.projectsStoreService.loadProjects(this.workspaceService.currentWorkspaceId());
          this.closeModal.emit();
        }),
        catchError(errorResponse => {
          addErrorToForm(errorResponse, this.form);

          return EMPTY;
        })
      )
      .subscribe();
  }

  private getProject(mode: EditMode): Partial<UpdateProjectDto> | CreateProjectDto {
    const selectedCategoryId =
      this.workspace()?.project_categories?.find(category => category.name === this.controls.category.value)?.id ||
      null;
    const project =
      mode === EditMode.ADD
        ? {
            workspace_id: this.workspaceService.currentWorkspaceId(),
            name: this.controls.name.value,
            description: this.controls.description.value,
            status: this.controls.status.value,
            color: this.controls.color.value,
            user_created: this.controls.owners.value.map(owner => owner.id)[0]
          }
        : {
            name: this.controls.name.value,
            description: this.controls.description.value,
            status: this.controls.status.value,
            color: this.controls.color.value,
            user_created: this.controls.owners.value.map(owner => owner.id)[0]
          };

    const omittedProject = omitBy(project, isNil);
    return {
      ...omittedProject,
      project_category_id: selectedCategoryId
    };
  }

  openManageCategoryModal() {
    this.ngbModal.open(this.manageCategoryModal(), this.settings.MODAL_DEFAULT_CONFIG);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
