export function base64ToArrayBuffer(base64: string): ArrayBuffer {
  // Remove the 'data:image/tiff;base64,' part if it exists
  const cleanBase64 = base64.replace(/^data:image\/tiff;base64,/, '');

  const binaryString = window.atob(cleanBase64); // Decode the base64 string
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}
