<div
  class="field-value-preview"
  [ngClass]="{
    'field-value-preview--table': viewMode() === ViewMode.table,
    'field-value-preview--minimized': minimized(),
  }"
>
  <ng-container [ngSwitch]="fieldSignal()?.type">
    <ng-container *ngSwitchCase="DisplayFieldType.URL">
      <a class="field-value field-value--url" [href]="fieldSignal().value" target="_blank">
        {{ fieldSignal().display_options?.url_format || fieldSignal().value }}
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="DisplayFieldType.USER">
      <div class="field-value field-value--user">
        <app-user-avatar [imageBase64]="avatar$ | async" avatarSize="21px"></app-user-avatar>
        <div class="field-text">
          {{ (userDisplayValue$ | async) || placeholder() }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="DisplayFieldType.SELECT">
      <div class="field-value field-value--select">
        <div class="field-icon" *ngIf="hasColor(fieldSignal())">
          <app-option-icon [options]="fieldSignal().options" [value]="fieldSignal().value" data-testid="status-icon" />
        </div>
        <div class="field-text">
          {{ fieldSignal().value || placeholder() }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="DisplayFieldType.DATE">
      <div class="field-value field-value--date">
        <app-icon
          *ngIf="!minimized()"
          width="1.1rem"
          height="1.1rem"
          fileName="field_date_icon.svg"
          [color]="IconColors.darkGrey"
        />
        <div class="field-text">
          {{ (fieldSignal() | itemReadValue) || placeholder() }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="DisplayFieldType.WYSIWYG">
      <div
        *ngIf="!minimized(); else minimizedWysiwyg"
        [innerHtml]="(fieldSignal() | itemReadValue | handleItemParameterOrResultIfEmpty) || placeholder()"
        class="field-value field-value--wysiwyg"
        data-testid="wysiwyg-field-value-preview"
      ></div>
      <ng-template #minimizedWysiwyg>
        <app-wysiwyg-field
          [fieldValue]="fieldSignal() | itemReadValue"
          [headerCustomStyles]="{ color: 'inherit' }"
          tooltip="Parameter"
        ></app-wysiwyg-field>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="DisplayFieldType.NUMBER">
      {{ (fieldSignal() | itemReadValue | ensureNumber | number: decimalFormat()) || placeholder() }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="field-value field-value--text" data-testid="field-value">
        <div class="field-text">
          {{ (fieldSignal() | itemReadValue | handleItemParameterOrResultIfEmpty) || placeholder() }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
