import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';

import { IconComponent } from '../../../_components/icon/components/icon/icon.component';

@Component({
  selector: 'app-file-thumb-rotate',
  imports: [CommonModule, IconComponent],
  templateUrl: './file-thumb-rotate.component.html',
  styleUrls: ['./file-thumb-rotate.component.scss']
})
export class FileThumbRotateComponent {
  @Input() fileFailed: boolean;
  @Input() fileAnalyzed: boolean;
  IconColors = IconColors;
}
