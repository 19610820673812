import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { BreadcrumbsComponent } from '@shared/breadcrumbs';

import { DetailsFileThumbComponent } from './details-file-thumb/details-file-thumb.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EmptyListPlaceholderComponent } from './empty-list-placeholder/empty-list-placeholder.component';
import { EmptySearchResultComponent } from './empty-search-result/empty-search-result.component';
import { FormSectionComponent } from './form-section/form-section.component';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { ImportErrorsComponent } from './import';
import { InputWithClearComponent } from './inputs/input-with-clear/input-with-clear.component';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { ITEM_COMPONENTS } from './item';
import { LIST_COMPONENTS } from './list';
import { MemberElementComponent } from './member-element/member-element.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ScrollableListViewportComponent } from './scrollable-list-viewport/scrollable-list-viewport.component';
import { SearchSelectComponent } from './search-select/search-select.component';
import { StatusDropdownComponent } from './status-dropdown/status-dropdown.component';
import { TreeSelectComponent } from './tree-select';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';

export const SHARED_COMPONENTS = [
  DetailsFileThumbComponent,
  DropdownComponent,
  EmptyListPlaceholderComponent,
  EmptySearchResultComponent,
  FormSectionComponent,
  GenericModalComponent,
  ImportErrorsComponent,
  InputWithClearComponent,
  InternalServerErrorComponent,
  ...ITEM_COMPONENTS,
  ...LIST_COMPONENTS,
  MemberElementComponent,
  NotFoundComponent,
  ScrollableListViewportComponent,
  SearchSelectComponent,
  StatusDropdownComponent,
  TreeSelectComponent,
  UserAvatarComponent,
  BreadcrumbsComponent,
  IconComponent
] as const;

export * from './item';
export * from './list';
export * from './scrollable-list-viewport/scrollable-list-viewport.component';
export * from './form-section/form-section.component';
export * from './tree-select/tree-select/tree-select.component';
export * from './empty-search-result/empty-search-result.component';
export * from './details-file-thumb/details-file-thumb.component';
export * from './search-select/search-select.component';
export * from './dropdown/dropdown.component';
export * from './inputs/input-with-clear/input-with-clear.component';
export * from './import/import-errors/import-errors.component';
export * from './import/import-errors-field/import-errors-field.component';
