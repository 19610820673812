import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

//
import { AlertService } from '../alert.service';
import { Alert, AlertType } from '../interface';
import { IAlertsChange } from '../interface/alerts-change.interface';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: false
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';
  @Input() fade = true;

  alerts: Alert[] = [];
  alertSubscription: Subscription;
  routeSubscription: Subscription;

  @Output() alertsChange: EventEmitter<IAlertsChange> = new EventEmitter<IAlertsChange>();

  constructor(
    private router: Router,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.alertSubscription = this.alertService.alerts$ // .onAlert(this.id)
      .subscribe(alert => {
        // clear alerts when an empty alert is received
        if (!alert.message) {
          // filter out alerts without 'keepAfterRouteChange' flag
          this.alerts = this.alerts.filter(alertObj => alertObj.keepAfterRouteChange);
          // remove 'keepAfterRouteChange' flag on the rest
          this.alerts.forEach(alertObj => delete alertObj.keepAfterRouteChange);

          return;
        }

        // add alert to array
        const alertExists = this.alerts.find(alertObj => alertObj.id === alert.id);

        if (!alertExists) {
          this.alerts.push(alert);
          const ids = this.alerts.map(alertObj => alertObj.id);

          this.alertsChange.emit({
            displaying: this.alerts.length > 0,
            activeIds: ids
          });
        }

        // auto close alert if required
        if (alert.autoClose) {
          setTimeout(() => this.removeAlert(alert), 3000);
        }
      });

    // clear alerts on location change
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });
  }

  ngOnDestroy(): void {
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  removeAlert(alert: Alert): void {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) {
      return;
    }

    if (this.fade) {
      // fade out alert
      this.alerts.find(alertObj => alertObj === alert).fade = true;

      // remove alert after faded out
      setTimeout(() => {
        this.alerts = this.alerts.filter(alertObj => alertObj !== alert);
      }, 250);
    } else {
      // remove alert
      this.alerts = this.alerts.filter(alertObj => alertObj !== alert);
    }
  }

  getCssClass(alert: Alert): string {
    if (!alert) {
      return;
    }

    const classes = ['alert', 'alert-dismissable'];
    const alertTypeClass = {
      [AlertType.Success]: 'alert alert-success',
      [AlertType.Error]: 'alert alert-danger',
      [AlertType.Info]: 'alert alert-info',
      [AlertType.Warning]: 'alert alert-warning'
    };

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }

  trackById(index: number, alert: { [key: string]: any }): string {
    return alert.id as string;
  }
}
