import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CONTACT_ADMINISTRATOR } from '@app/_auth/constants';
import { toMessage } from '@app/domain/error-handling';
import { EmailWithDomainValidator } from '@app/shared/_validators/email-with-domain.validator';

import { AuthRoutingPath } from '../../auth-routing-path';
import { AuthService } from '../../auth.service';
import { AuthRemindPasswordRequest } from '../../interface';

enum FORM_KEYS {
  EMAIL = 'email'
}

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false
})
export class RequestResetPasswordComponent {
  isRequestResetPasswordSuccessfulSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isRequestResetPasswordSuccessful$ = this.isRequestResetPasswordSuccessfulSubject.asObservable();

  readonly signInRoutingPath = ['/', AuthRoutingPath.auth, AuthRoutingPath.signIn];
  focusEmail: boolean;
  formSubmitted = false;
  readonly FORM_KEYS = FORM_KEYS;
  readonly CONTACT_ADMINISTRATOR = CONTACT_ADMINISTRATOR;
  remindPasswordForm = new FormGroup({
    [FORM_KEYS.EMAIL]: new FormControl(null, [Validators.required, EmailWithDomainValidator])
  });

  constructor(private readonly authService: AuthService) {}

  remindPassword($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.formSubmitted = true;
    if (this.remindPasswordForm.invalid) {
      this.remindPasswordForm.markAllAsTouched();
      return;
    }

    const requestData = this.remindPasswordForm.value as AuthRemindPasswordRequest;

    if (this.remindPasswordForm.valid && requestData) {
      this.authService
        .requestResetPassword(requestData)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if ([400, 404, 422].includes(error.status)) {
              this.remindPasswordForm.get(FORM_KEYS.EMAIL).setErrors({ backendError: toMessage(error) });
              return EMPTY;
            }
            return throwError(() => error);
          })
        )
        .subscribe(() => this.isRequestResetPasswordSuccessfulSubject.next(true));
    } else {
      throw 'Unable to process Request Password data';
    }
  }
}
