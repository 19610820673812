import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appOverflowContentPluginToNgbTooltip]',
  standalone: true
})
export class OverflowContentPluginToNgbTooltipDirective {
  // this directive enables to show tooltip only when element content is overflowed (includes also line clamp)
  // if you need to display always visible, default tooltip, provide it through input property
  // IMPORTANT: use this directive along with ngbTooltip!

  @Input() defaultTooltipValue;

  constructor(
    private ngbTooltipComponent: NgbTooltip,
    private element: ElementRef
  ) {
    ngbTooltipComponent.container = 'body';
    ngbTooltipComponent.triggers = 'manual';
  }

  @HostListener('mouseenter') onMouseEnter() {
    /* Substracted -1 from scrollHeight because it was too sensitive for categories tree where elements have defined line-height.
       It should trigger only when the text doesnt fit and is collapsed to the next line.
    */
    if (
      this.element.nativeElement.offsetWidth < this.element.nativeElement.scrollWidth ||
      this.element.nativeElement.clientHeight < this.element.nativeElement.scrollHeight - 1
    ) {
      this.ngbTooltipComponent.open();
    } else if (typeof this.defaultTooltipValue !== 'undefined') {
      this.ngbTooltipComponent.ngbTooltip = this.defaultTooltipValue;
      this.ngbTooltipComponent.open();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.ngbTooltipComponent.close();
  }
}
