import { uniqBy } from 'lodash-es';

import { ProjectDto, UserDto } from '../../shared/_models';

export function getUsers(project: ProjectDto): UserDto[] {
  if (!project) return [];

  const members = project.members.map(member => member.user);
  const leads = project.leads;
  const owner = project.user_crt;

  return uniqBy([owner, ...leads, ...members], user => user.id);
}
