import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, computed, signal } from '@angular/core';

@Component({
  selector: 'app-icon',
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  fileName = input.required<string>();
  color = input.required<string>();
  width = input.required<string>();
  height = input.required<string>();
  hoverColor = input<string>(null);
  inheritHover = input<boolean>(false);
  path = computed<string>(() => `url('assets/icons/${this.fileName()}')`);
  iconColor = computed<string>(() =>
    (this.hover() || this.inheritHover()) && this.hoverColor() ? this.hoverColor() : this.color()
  );
  hover = signal<boolean>(false);

  onHoverChange(value: boolean) {
    this.hover.set(value);
  }
}
