import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { getAreaName } from '@app/domain/shared';
import { DevelopmentType, ProjectDto, ProjectProxy, RouteParam } from '@shared/_models';
import { RootState } from '@shared/_root-store/reducer';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';
import { selectCurrentUser } from '@shared/_root-store/users-store/users.selectors';

import { ProjectsState } from './projects.reducer';

export const selectProjectsState = createSelector(
  (state: RootState) => state.projects,
  state => state
);

export const selectProjectsDictionary = createSelector(
  selectProjectsState,
  (state: ProjectsState) => state?.entities ?? {}
);
export const selectProjects = createSelector(selectProjectsDictionary, (dictionary: Dictionary<ProjectDto>) =>
  Object.values(dictionary)
);
export const selectProjectsLoading = createSelector(selectProjectsState, (state: ProjectsState) => state.loading);
export const selectProjectsLoaded = createSelector(selectProjectsState, (state: ProjectsState) => state.loaded);
export const selectProjectProxy = createSelector(
  selectProjects,
  selectRouteParam(RouteParam.PROJECT_ID),
  selectCurrentUser,
  (projects, projectId, currentUser) => {
    const project = projects?.find(project => project.id === projectId);

    if (!(project && currentUser)) {
      return null;
    }

    return new ProjectProxy(currentUser, project);
  }
);
export const selectSelectedProject = createSelector(
  selectProjectsDictionary,
  selectRouteParam(RouteParam.PROJECT_ID),
  (dictionary: Dictionary<ProjectDto>, projectId: string) => dictionary[projectId]
);
export const selectAreaName = (developmentType: DevelopmentType) =>
  createSelector(selectSelectedProject, project => getAreaName(project, developmentType));
export const selectShowResearchObjects = createSelector(
  selectSelectedProject,
  project => !project?.hidden_development_types?.includes(DevelopmentType.researchObject)
);
export const selectShowExperiments = createSelector(
  selectSelectedProject,
  project => !project?.hidden_development_types?.includes(DevelopmentType.experiment)
);
export const selectError = createSelector(selectProjectsState, (state: ProjectsState) => state.error);

export const selectProjectById = (projectId: string) =>
  createSelector(selectProjects, projects => projects.find(project => project.id === projectId));

export const selectProjectsIBelongTo = createSelector(selectProjects, selectCurrentUser, (projects, currentUser) => {
  return projects.filter(project => {
    const projectProxy = new ProjectProxy(currentUser, project);

    return projectProxy.belongsToProject;
  });
});

export const selectProjectsCategories = createSelector(selectProjects, projects =>
  projects.reduce((acc, project) => {
    if (project.project_category) {
      acc.push(project.project_category);
    }

    return acc;
  }, [])
);
