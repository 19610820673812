import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { IToast } from '@shared/_models/toast.interface';

import { ToastService } from '../toast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  standalone: false
})
export class ToastsComponent implements OnInit {
  toasts: IToast[] = [];

  constructor(
    private toastService: ToastService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.changeDetector.detach();
    this.toastService.toast$.subscribe(toast => {
      const length = this.toasts.push(toast);
      this.changeDetector.detectChanges();
      setTimeout(() => {
        this.removeToastByIndex(length - 1);
      }, toast.options.delay ?? 4000);
    });
  }

  trackByIndex(index: number): number {
    return index;
  }

  close(toastIndex: number) {
    this.removeToastByIndex(toastIndex);
  }

  removeToastByIndex(toastIndex: number) {
    // toast won't be actually removed, but set as null
    // reason: toasts can be removed in two concurrent ways: timeout or close btn
    this.toasts[toastIndex] = null;
    this.changeDetector.detectChanges();
  }
}
