import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostListener,
  input,
  output,
  signal,
  ViewChild
} from '@angular/core';

import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { DropdownItem, StatusDto } from '@shared/_models';
import { StatusIconPipe } from '@shared/pipes';

@Component({
  selector: 'app-development-item-status-dropdown',
  imports: [CommonModule, IconComponent, StatusIconPipe],
  templateUrl: './development-item-status-dropdown.component.html',
  styleUrls: ['./development-item-status-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevelopmentItemStatusDropdownComponent {
  showColors = input.required<boolean>();
  editable = input.required<boolean>();
  statuses = input.required<StatusDto[]>();
  selectedStatus = input<StatusDto>();
  update = output<StatusDto>();
  resetStatus = output<void>();

  @ViewChild('statusSelect') statusSelect: ElementRef;

  statusSelectItems = computed<DropdownItem[]>(() =>
    this.statuses().map(status => ({ id: status.position, name: status.name }))
  );
  IconColors = IconColors;
  isOpen = signal(false);

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen.set(false);
    }
  }

  toggle(): void {
    if (!this.editable()) return;
    this.isOpen.update(open => !open);
  }

  selectOption(option: StatusDto): void {
    if (this.selectedStatus()?.name !== option.name) {
      this.onStatusChange(option);
    }

    this.isOpen.set(false);
  }

  private onStatusChange(option: StatusDto) {
    this.update.emit(option);
  }

  onResetStatus(): void {
    if (this.selectedStatus()) {
      this.resetStatus.emit();
    }

    this.isOpen.set(false);
  }
}
