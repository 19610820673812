import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Inject, input, signal, TemplateRef } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { DevelopmentType, MemberEdit, MemberRole, ProjectProxy, UserDto } from '@app/shared/_models';
import { GenericModalComponent } from '@shared/_components/generic-modal/generic-modal.component';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { MemberElementComponent } from '@shared/_components/member-element/member-element.component';
import { ModalHeaderCenterComponent } from '@shared/_components/modal-header-center/modal-header-center.component';
import { RoleDescriptionComponent } from '@shared/_components/role-description/role-description.component';
import { AppSettings } from '@shared/_configuration';
import { changeRole, removeUserFromProject } from '@shared/_root-store/projects-store/projects.actions';
import { selectAllAvatars } from '@shared/_root-store/users-store/users.selectors';

import { ChangeOwnerConfirmationModalComponent } from '../../shared/_components/change-owner-confirmation-modal/change-owner-confirmation-modal';

@Component({
  selector: 'app-members-elements',
  imports: [
    CommonModule,
    MemberElementComponent,
    GenericModalComponent,
    ModalHeaderCenterComponent,
    AsyncPipe,
    ChangeOwnerConfirmationModalComponent,
    RoleDescriptionComponent
  ],
  templateUrl: './members-elements.component.html',
  styleUrls: ['./members-elements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembersElementsComponent {
  users = input<MemberEdit[]>([]);
  projectProxy = input<ProjectProxy>();

  usersAvatars = toSignal(this.store.select(selectAllAvatars));
  leadsWithAvatars = computed(() =>
    this.users()
      .filter(member => member.roleBeforeEdit == MemberRole.owner || member.roleBeforeEdit == MemberRole.lead)
      .map(user => ({
        lead: user,
        avatar: this.usersAvatars().find(avatar => avatar.id === user.user.id)
      }))
  );

  membersWithAvatars = computed(() =>
    this.users()
      .filter(member => member.roleBeforeEdit == MemberRole.editor || member.roleBeforeEdit == MemberRole.reader)
      .map(user => ({
        member: user,
        avatar: this.usersAvatars().find(avatar => avatar.id === user.user.id)
      }))
  );

  selectedUser = signal<UserDto>(null);
  readonly DevelopmentType = DevelopmentType;
  readonly MemberRole = MemberRole;
  #deleteModalRef: NgbModalRef;
  #changeOwnerModalRef: NgbModalRef;

  constructor(
    private store: Store,
    private readonly ngbModalService: NgbModal,
    @Inject(AppSettings) private readonly settings: AppSettings
  ) {}

  onCloseRemoveUserModal(reason: CloseReason) {
    if (reason === CloseReason.ACCEPT) {
      this.store.dispatch(
        removeUserFromProject({
          userId: this.selectedUser().id,
          projectId: this.projectProxy().projectId
        })
      );
    }

    this.#deleteModalRef.close();
    this.selectedUser.set(null);
  }

  onCloseChangeOwnerModal(reason: CloseReason) {
    if (reason === CloseReason.ACCEPT) {
      this.store.dispatch(
        changeRole({
          userId: this.selectedUser().id,
          projectId: this.projectProxy().projectId,
          newRole: MemberRole.owner
        })
      );
    }

    this.#changeOwnerModalRef.close();
    this.selectedUser.set(null);
  }

  onMemberDelete(member: UserDto, removeUserFromProjectModal: TemplateRef<any>) {
    this.#deleteModalRef = this.ngbModalService.open(removeUserFromProjectModal, this.settings.MODAL_DEFAULT_CONFIG);
    this.selectedUser.set(member);
  }

  onEditRole(roleAfterEdit: MemberRole, user: UserDto, changeOwnerConfirmationModal: TemplateRef<any>) {
    if (roleAfterEdit === MemberRole.owner) {
      this.selectedUser.set(user);
      this.#changeOwnerModalRef = this.ngbModalService.open(changeOwnerConfirmationModal, this.settings.MODAL_DEFAULT_CONFIG);
      return;
    }

    this.store.dispatch(
      changeRole({
        userId: user.id,
        projectId: this.projectProxy().projectId,
        newRole: roleAfterEdit
      })
    );
  }
}
