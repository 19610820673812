import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { ImportExcelRowError } from '@app/shared/_models/import-excel';
import { AutoFocusDirective } from '@shared/_directives/auto-focus/auto-focus.directive';

@Component({
  selector: 'app-import-errors-field',
  imports: [AutoFocusDirective, ReactiveFormsModule],
  templateUrl: './import-errors-field.component.html',
  styleUrls: ['./import-errors-field.component.scss']
})
export class ImportErrorsFieldComponent implements OnInit {
  @Input() field: ImportExcelRowError;
  @Input() rowNumber: number;
  control = new FormControl();

  ngOnInit(): void {
    this.control.setValue(this.field.cell_value);
  }

  setHeightToContent(textArea: HTMLTextAreaElement): void {
    textArea.style.height = 'auto';
    textArea.style.height = `${textArea.scrollHeight}px`;
  }
}
