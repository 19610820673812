import { NgIf, NgClass, JsonPipe, AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit, ViewChild, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { ChangeEvent, CKEditorComponent, CKEditorModule } from '@ckeditor/ckeditor5-angular';
import Editor from 'ckeditor5/build/ckeditor';
import { mergeMap } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { InputWithValidationComponent } from '@app/shared/_components/inputs/input-with-validation/input-with-validation.component';
import { TextCounterComponent } from '@app/shared/_components/inputs/text-long-input/text-counter/text-counter.component';
import { CounterOptions } from '@app/shared/_components/inputs/text-long-input/text-counter/utils';
import { stripHtmlTags } from '@shared/_helpers/strip-html-tags';

import { FieldConfigEditor } from '../../models/field-config-editor-interface';

@Component({
  selector: 'app-editor',
  imports: [NgIf, NgClass, CKEditorModule, TextCounterComponent, InputWithValidationComponent, JsonPipe, AsyncPipe],
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  formControl = input.required<FormControl>();
  @Input() label: string;
  @Input() maxLength: number;
  @Input() counterOptions: CounterOptions;
  @Input() backendErrorMessage: string;
  @Input() externalTouched: boolean;
  @Input() markAsInvalid: boolean;
  @Output() blurred = new EventEmitter<void>();
  @ViewChild('editor') editorEl: CKEditorComponent;
  length$ = toObservable(this.formControl).pipe(
    mergeMap(formControl => formControl.valueChanges.pipe(startWith(formControl.value))),
    map(value => stripHtmlTags(value)),
    map(stripped => stripped.length)
  );
  Editor = Editor;
  config: FieldConfigEditor;

  ngOnInit(): void {
    this.config = {
      name: this.label,
      wordCount: {
        displayCharacters: true,
        displayWords: false
      },
      toolbar: {
        items: ['bold', 'italic', 'underline', 'link', 'numberedList', 'bulletedList', 'fontSize', 'undo', 'redo'],
        shouldNotGroupWhenFull: true
      },
      fontSize: {
        options: [
          {
            title: '12px',
            model: 'text-12',
            view: {
              name: 'span',
              classes: 'ck-text-12'
            }
          },
          {
            title: '14px',
            model: 'text-14',
            view: {
              name: 'span',
              classes: 'ck-text-14'
            }
          },
          {
            title: '16px',
            model: 'text-16',
            view: {
              name: 'span',
              classes: 'ck-text-16'
            }
          },
          {
            title: '18px',
            model: 'text-18',
            view: {
              name: 'span',
              classes: 'ck-text-18'
            }
          }
        ],
        supportAllValues: false
      }
    };
  }

  change({ editor }: ChangeEvent) {
    this.formControl().setValue(editor.getData());

    if (!this.formControl().touched) {
      this.formControl().markAsTouched();
    }
  }

  input($event: Event) {
    $event.stopImmediatePropagation();
  }
}
