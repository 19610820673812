import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DevelopmentType, FileDto } from 'src/app/shared/_models';

import { DevelopmentTypeCssClassPipe } from '@app/shared/pipes/development-type-css-class.pipe';
import { FileExtensionPipe } from '@app/shared/pipes/file-extension.pipe';

@Component({
  selector: 'app-details-file-thumb',
  imports: [NgClass, DevelopmentTypeCssClassPipe, FileExtensionPipe],
  templateUrl: './details-file-thumb.component.html',
  styleUrls: ['./details-file-thumb.component.scss']
})
export class DetailsFileThumbComponent {
  @Input() file: FileDto;
  @Input() developmentType: DevelopmentType;
  @Output() fileThumbClick = new EventEmitter<string>();

  onFileThumbClick() {
    this.fileThumbClick.emit(this.file.id);
  }
}
