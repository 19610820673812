import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//
import { EXPERIMENTS_FILES_API_URL } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class ExperimentDownloadFileApiService {
  constructor(private readonly http: HttpClient) {}

  downloadZipped(ids_to_download: string[]): Observable<any> {
    return this.http.post<any>(
      `${EXPERIMENTS_FILES_API_URL}/download_zipped_files`,
      { ids_to_download },
      { responseType: 'blob' as 'json' }
    );
  }

  download(file_id: string): Observable<any> {
    return this.http.post<any>(`${EXPERIMENTS_FILES_API_URL}/download_file/${file_id}`, {}, { responseType: 'blob' as 'json' });
  }
}
