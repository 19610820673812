import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ScrollManager, ScrollSectionDirective, ScrollAnchorDirective } from '@app/shared/_directives/scroll';

@Component({
  selector: 'app-privacy-policy',
  imports: [ScrollSectionDirective, ScrollAnchorDirective],
  providers: [ScrollManager],
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyComponent {}
