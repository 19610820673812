import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';

import { ImageDto } from '@shared/_models';

@Injectable()
export class ImageDetailsModalsService {
  private readonly imageDetailsModalSource = new Subject<ImageDto>();
  private readonly imageNameModalSource = new Subject<ImageDto>();

  readonly imageDetailsModal$ = this.imageDetailsModalSource.asObservable();
  readonly imageNameModal$ = this.imageNameModalSource.asObservable();

  imageMetaErrorName = signal<string>(null);
  imageMetaErrorDetails = signal<string>(null);

  emitOpenDetailsModal(image: ImageDto): void {
    this.imageMetaErrorDetails.set(null);
    this.imageDetailsModalSource.next(image);
  }

  emitOpenUpdateNameModal(image: ImageDto): void {
    this.imageMetaErrorName.set(null);
    this.imageNameModalSource.next(image);
  }
}
