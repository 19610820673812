import { WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { skip } from 'rxjs';

import { ProjectsStoreService } from '@shared/_root-store/projects-store/projects-store.service';

import { Filterable, Pageable, Searchable, Sortable, CommonParams } from '../_models';

export abstract class ParamsService<T extends CommonParams> {
  selectedOnlyIndexPage = 1;
  isSelectOnlyMode = false;
  abstract params: WritableSignal<T>;

  constructor(protected readonly projectService: ProjectsStoreService) {
    this.#resetSortOnProjectChange();
  }

  setCurrentParams(params: T): void {
    this.params.set(params);
  }

  patchCurrentParams(params: Partial<T>): void {
    this.params.update(value => ({ ...value, ...params }));
  }

  getCurrentParams(): T {
    return this.params();
  }

  resetSortState() {
    this.patchCurrentParams({ sortState: null } as Partial<T>);
  }

  getSelectedOnlyIndexPage() {
    return this.selectedOnlyIndexPage;
  }

  setSelectedOnlyIndexPage(indexPage: number) {
    this.selectedOnlyIndexPage = indexPage;
  }

  getIsSelectOnlyMode(): boolean {
    return this.isSelectOnlyMode;
  }

  setIsSelectOnlyMode(isSelectOnlyMode) {
    this.isSelectOnlyMode = isSelectOnlyMode;
  }

  abstract getHttpParams(): Searchable & Pageable & Sortable & Filterable;

  #resetSortOnProjectChange() {
    /* skip(1) is used to avoid error that params property is not set yet,
     * and we don't care about initial value */
    this.projectService.projects$.pipe(skip(1), takeUntilDestroyed()).subscribe(() => this.resetSortState());
  }
}
