import { NgFor, NgIf } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { OverflowContentPluginToNgbTooltipDirective } from '@shared/_directives/overflow-content-plugin-to-ngb-tooltip.directive';

@Component({
  selector: 'app-items-counter',
  imports: [NgFor, NgIf, NgbModule, OverflowContentPluginToNgbTooltipDirective],
  templateUrl: './items-counter.component.html',
  styleUrls: ['./items-counter.component.scss']
})
export class ItemsCounterComponent {
  items = input<string[] | number[]>();
  textBeforeCounter = input<string>();
  tooltipHeader = input<string>();
  container = input<string>();
  counterVisibleForLengthOne = input(false);
  defaultTooltipValue = input<string>();
  showCounter = input(true);

  shouldCounterBeDisplayed = computed(() => {
    const minLength = this.counterVisibleForLengthOne() ? 1 : 2;
    return this.items()?.length >= minLength && this.showCounter();
  });
}
