<div class="text-long" [ngClass]="{'text-long--invalid': hasError()}">
  <div class="text-long__inner">
    <textarea
      class="text-long__textarea"
      [ngModel]="ngModel()"
      [placeholder]="placeholder()"
      (keydown)="onKeydown($event)"
      (ngModelChange)="onNgModelChange($event)"
      (blur)="blurred.emit()"
      autosize
      [minRows]="MIN_ROWS"
      [maxRows]="autoHeight() ? null : MAX_ROWS"
    ></textarea>
  </div>
</div>

@if(counterOptions()?.show) {
  <app-text-counter [length]="ngModel()?.length || 0" [maxLength]="counterOptions()?.maxLength"></app-text-counter>
}
