import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';

import { getDefaultPagination } from '@shared/_helpers';
import { Experiment, PaginationDto, SortDto, SortOrderTypes } from '@shared/_models';

import * as actions from './experiments.actions';

export const EXPERIMENTS_FEATURE_KEY = 'experiments';
export const experimentsAdapter: EntityAdapter<Experiment> = createEntityAdapter<Experiment>();

export interface ExperimentsState extends EntityState<Experiment> {
  readonly pagination: PaginationDto;
  readonly sort: SortDto;
  readonly loading: boolean;
  readonly exporting: boolean;
  readonly loaded: boolean;
  readonly selectedIds: string[];
  readonly search: string;
}

export const initialState: ExperimentsState = experimentsAdapter.getInitialState({
  pagination: getDefaultPagination(),
  sort: null,
  loading: false,
  exporting: false,
  loaded: false,
  selectedIds: [],
  search: ''
});

export const reducer: ActionReducer<ExperimentsState> = createReducer(
  initialState,

  // LOAD
  on(actions.loadExperiments, state => {
    return { ...state, loading: true, loaded: false };
  }),
  on(actions.loadExperimentsSuccess, (state, action) => {
    return experimentsAdapter.setAll(action.data.data, {
      ...state,
      pagination: action.data.pagination,
      sort: action.data.sort,
      loading: false,
      loaded: true,
      search: action.data.pagination.search
    });
  }),
  on(actions.loadExperimentsFailure, state => {
    return { ...state, loading: false, loaded: false };
  }),

  // SELECT
  on(actions.getAllExperimentsIdsSuccess, (state, action) => {
    const { ids } = action.data;

    return { ...state, selectedIds: ids };
  }),
  on(actions.selectExperimentAction, (state, action) => ({ ...state, selectedIds: [...state.selectedIds, action.id] })),
  on(actions.selectExperimentsAction, (state, action) => {
    const idsSet = new Set([...state.selectedIds, ...action.ids]);
    const ids = Array.from(idsSet);

    return { ...state, selectedIds: [...ids] };
  }),
  on(actions.unselectExperiment, (state, action) => {
    const selectedIds = state.selectedIds.filter(id => id !== action.id);

    return { ...state, selectedIds };
  }),
  on(actions.unselectExperiments, (state, action) => {
    const idsToRemove = action.ids;
    const selectedIds = state.selectedIds.filter(id => !idsToRemove.includes(id));

    return { ...state, selectedIds };
  }),
  on(actions.clearExperimentSelection, state => ({ ...state, selectedIds: [] })),

  // DELETE
  on(actions.deleteExperimentsSuccess, (state, action) => experimentsAdapter.removeMany(action.deletedExperimentsIds, { ...state })),

  on(actions.resetExperimentsSorting, state => {
    return {
      ...state,
      sort: { prop: 'date_created', order: SortOrderTypes.DESCENDING }
    };
  }),

  on(actions.resetExperimentsSearching, state => {
    return {
      ...state,
      search: ''
    };
  }),

  on(actions.clearExperiments, state => {
    return experimentsAdapter.removeAll(state);
  }),

  on(actions.updateImageMetaSuccess, (state, action) => {
    const { experimentId, imageMeta } = action;
    const experimentToUpdate = state.entities[experimentId];

    return experimentsAdapter.updateOne(
      {
        id: experimentId,
        changes: {
          images: experimentToUpdate.images.map(image => (image.id === imageMeta.id ? imageMeta : image))
        }
      },
      state
    );
  }),

  on(actions.generateCSV, state => {
    return { ...state, exporting: true };
  }),

  on(actions.generateCSVSuccess, state => {
    return { ...state, exporting: false };
  }),

  on(actions.generateCSVFailure, state => {
    return { ...state, exporting: false };
  }),

  on(actions.generateMultipleCSV, state => {
    return { ...state, exporting: true };
  }),

  on(actions.generateMultipleCSVSuccess, state => {
    return { ...state, exporting: false };
  }),

  on(actions.generateMultipleCSVFailure, state => {
    return { ...state, exporting: false };
  })
);
