<div class="field-modal__control">
  <label
    class="field-modal__label"
    [class.field-modal__label--invalid]="backendErrorMessage() || itemErrors().length > 0"
  >
    {{ label() }}
  </label>
  <div
    [nxtSortablejs]="selectItemsFormArray.controls"
    [config]="options()"
    class="select-items-container"
    data-testid="dropdown-item-container"
  >
    <div *ngFor="let itemControl of selectItemsFormArray.controls; let index = index">
      <div
        class="select-item__container"
        [class.select-item__container--selected]="itemIndex === index"
        [class.select-item__container--invalid]="hasError(index)"
        (click)="onSelectItemClick(itemControl, index)"
      >
        <span class="select-item__drag-drop-icon"></span>
        @if (colorsEnabled()) {
          <span class="status-icon" [ngClass]="'option-color-' + itemControl.get('color').value"></span>
        }
        <app-text-input
          spellcheck="false"
          class="select-item__input"
          placeholder=""
          [formControl]="itemControl.get('name')"
          (input)="checkForItemErrors()"
          ngDefaultControl
        ></app-text-input>
        <button
          class="btn select-item__btn-clear"
          (click)="onRemoveSelectItem(index, itemControl.get('id').value)"
        ></button>
      </div>
      @if (hasDuplicatedError(index)) {
        <div class="select-item__error">Item is duplicated</div>
      }
      @if (hasToLongError(index)) {
        <div class="select-item__error">Item must contain less than 30 characters.</div>
      }
    </div>
  </div>
  @if (colorsEnabled()) {
    <app-item-colors [itemColor]="controls.color.value" (selectedColorIndex)="onColorChange($event)"></app-item-colors>
  }
  <div
    class="select-items-add__container"
    [class.select-items-add__container--disabled]="dropdownItemsLimitReached()"
    [ngbTooltip]="dropdownItemsLimitReached() ? 'You have reached the limit of items' : null"
    (click)="dropdownItemsLimitReached() ? null : onAddSelectItem('')"
    data-testid="add-dropdown-item"
  >
    <span class="select-items-add__text">+ {{ addLabel() }}</span>
  </div>
  <div class="invalid-feedback select-field-modal__error" [class.d-block]="backendErrorMessage()">
    {{ backendErrorMessage() }}
  </div>
  <label class="field-modal__label mb-1">{{ defaultLabel() }}</label>
  <div class="select-dropdown__container">
    <app-dropdown-select
      [items]="defaultValueOptions()"
      [colorsEnabled]="colorsEnabled()"
      [placeholder]="placeholder()"
      [formControl]="form.get('dropdownDefaultValue')"
    ></app-dropdown-select>
  </div>
</div>
