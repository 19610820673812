import { AsyncPipe, NgFor, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ROOT_CATEGORY_ID } from '@app/domain/category';
import { DevelopmentType, ProjectDto } from '@app/shared/_models';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { ExperimentParamsService } from '@shared/_services/experiment/experiment-params.service';
import { NavigationService } from '@shared/_services/navigation.service';

import { GenericModalComponent } from '../../../generic-modal/generic-modal.component';
import { ModalHeaderCenterComponent } from '../../../modal-header-center/modal-header-center.component';

@Component({
  selector: 'app-research-objects-remove-rejection',
  imports: [NgFor, NgbModule, AsyncPipe, GenericModalComponent, ModalHeaderCenterComponent, NgTemplateOutlet],
  templateUrl: './research-objects-remove-rejection.component.html',
  styleUrls: ['./research-objects-remove-rejection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResearchObjectsRemoveRejectionComponent {
  project = input.required<ProjectDto>();
  @Input() modal: NgbModalRef;
  @Input() deleteFailRoIds: string[];
  @Input() deleteFailRelatedExpCount: number;

  readonly DevelopmentType = DevelopmentType;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private experimentParamsService: ExperimentParamsService
  ) {}

  close(reason: CloseReason) {
    if (reason === CloseReason.CLOSE) {
      this.showRelatedExperiments();
    } else if (reason === CloseReason.ACCEPT) {
      this.modal.close();
    }
  }

  showRelatedExperiments() {
    const ro_ids = this.deleteFailRoIds;
    this.experimentParamsService.patchCurrentParams({ ro_ids });
    const workspaceId = this.activatedRoute.snapshot.paramMap.get('workspaceId');
    const projectId = this.activatedRoute.snapshot.paramMap.get('projectId');
    this.navigationService.goToExperimentsListWithParams(workspaceId, projectId, ROOT_CATEGORY_ID, { page: 1, limit: 1, ro_ids });
    this.modal.close();
  }
}
