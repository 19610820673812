import { Injectable } from '@angular/core';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class LgPopoverConfig extends NgbPopoverConfig {
  constructor() {
    super();

    this.triggers = 'hover';
    this.popoverClass = 'lg-popover';

    this.popperOptions = options => {
      options.onFirstUpdate = state => {
        if (state.elements?.arrow) {
          state.elements.arrow.style.display = 'none';
        }
      };
      return options;
    };
  }
}
