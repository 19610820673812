import { ParameterDto, ParameterUpdateDto } from '@shared/_models';

export function toTemplateFieldUpdate(field: ParameterDto): ParameterUpdateDto {
  return {
    name: field.name,
    placeholder: field.placeholder,
    position: field.position,
    default: field.default,
    options: field.options,
    display_options: field.display_options
  };
}
