import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  input,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotImplementedModalComponent } from '@shared/_components/not-implemented-modal/not-implemented-modal.component';
import { AppSettings } from '@shared/_configuration';
import { DevelopmentType } from '@shared/_models';

@Component({
  selector: 'app-box-controls-bar',
  imports: [CommonModule, NotImplementedModalComponent],
  templateUrl: './box-controls-bar.component.html',
  styleUrls: ['./box-controls-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxControlsBarComponent {
  @Input() visible: boolean;
  @Input() hovered = false;
  @Input() showActionBtns = true;
  hideCopy = input<boolean>(false);
  hideEdit = input<boolean>(false);

  @Output() delete = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
  @ViewChild('notImplementedModal', { static: false }) notImplementedModal;

  readonly DevelopmentType = DevelopmentType;

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    private ngbModalService: NgbModal
  ) {}

  onCopyBtnClick() {
    /* TODO: When field copy will be implemented:
     * 1) remove showing notImplementedModal
     * 2) remove taking developmentType param from activated route
     * 3) clear unused imports + injections
     */
    this.ngbModalService.open(this.notImplementedModal, this.settings.MODAL_DEFAULT_CONFIG);
  }
}
