import { JsonPipe, NgIf, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  viewChild
} from '@angular/core';

import { ListSearchComponent } from '@shared/_components/list/list-search/list-search.component';
import { DevelopmentType, ViewMode } from '@shared/_models';
import { ListActionsBarVisibility } from '@shared/_models/list-actions-bar';
import { ExperimentParamsService } from '@shared/_services/experiment/experiment-params.service';
import { ResearchObjectParamsService } from '@shared/_services/research-object/research-object-params.service';
import { BreadcrumbsComponent } from '@shared/breadcrumbs';

@Component({
  selector: 'app-list-actions-bar',
  imports: [NgClass, NgIf, BreadcrumbsComponent, ListSearchComponent, JsonPipe],
  templateUrl: './list-actions-bar.component.html',
  styleUrls: ['./list-actions-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListActionsBarComponent implements OnInit {
  @Input() searchTextHasValue: boolean;
  @Input() showActions = { search: true, sort: true, viewModeSwitch: true } as ListActionsBarVisibility;
  @Input() header: string;
  @Input() total: number;
  @Input() currentCategoryHasChildren!: boolean;
  @Input() developmentType!: DevelopmentType;
  @Input() viewMode: ViewMode;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  addItemControl = viewChild<ElementRef>('addItemControl');
  searchControl = viewChild<ElementRef>('searchControl');
  sortControl = viewChild<ElementRef>('sortControl');
  viewControl = viewChild<ElementRef>('viewControl');
  selectionActionControl = viewChild<ElementRef>('selectionActionControl');

  // it's not a perfect solution ... to find if given ng-content was injected
  isAddItemControl = computed(() => {
    if (!this.addItemControl()) return false;

    return this.addItemControl().nativeElement.children.length > 0;
  });
  isSearchControl = computed(() => {
    if (!this.searchControl()) return false;

    return this.searchControl().nativeElement.children.length > 0;
  });
  isSortControl = computed(() => {
    if (!this.sortControl()) return false;

    return this.sortControl().nativeElement.children.length > 0;
  });
  isViewControl = computed(() => {
    if (!this.viewControl()) return false;

    return this.viewControl().nativeElement.children.length > 0;
  });
  isSelectionActionControl = computed(() => {
    if (!this.selectionActionControl()) return false;

    return this.selectionActionControl().nativeElement.children.length > 0;
  });
  searchBarExpanded = false;
  instancesTableVisible = false;
  showInstancesBtn: boolean;

  readonly DevelopmentType = DevelopmentType;
  readonly ViewMode = ViewMode;

  constructor(
    private readonly researchObjectParamsService: ResearchObjectParamsService,
    private readonly experimentParamsService: ExperimentParamsService
  ) {}

  ngOnInit() {
    if (
      (this.developmentType === DevelopmentType.experiment && this.experimentParamsService.getCurrentParams().search) ||
      (this.developmentType === DevelopmentType.researchObject &&
        this.researchObjectParamsService.getCurrentParams().search)
    ) {
      this.searchBarExpanded = true;
    }
  }

  onSearchChange(value: string): void {
    this.searchTextHasValue = !!value;
    this.searchChange.emit(value);
  }

  onExpandSearchBarBtnClick() {
    this.searchBarExpanded = !this.searchBarExpanded;
  }
}
