import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ResearchObject, Experiment, DevelopmentType } from '@shared/_models';

@Component({
  selector: 'app-item-details-additional-info',
  imports: [CommonModule],
  templateUrl: './item-details-additional-info.component.html',
  styleUrls: ['./item-details-additional-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailsAdditionalInfoComponent {
  @Input() item: ResearchObject | Experiment;
  @Input() developmentType: DevelopmentType;
  readonly DevelopmentType = DevelopmentType;
}
