import { NgClass, NgIf, NgFor, LowerCasePipe, TitleCasePipe, CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, computed, signal } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SERIAL_NUMBER } from '@app/_research_objects/research-object/research-object-info/utils/get-instance-view-data';
import { findValidationError, isPreprocessedError, toMessage } from '@app/domain/error-handling';
import { comparePositions } from '@app/domain/shared';
import { IsTextLongPipe } from '@app/shared/pipes/is-text-long.pipe';
import { ContentWithCopyBtnComponent } from '@shared/_components/content-with-copy-btn/content-with-copy-btn.component';
import { FieldValuePreviewComponent } from '@shared/_components/item/item-details/item-details-grid-section/field-value-preview/field-value-preview.component';
import { OptionIconComponent } from '@shared/_components/option-icon/option-icon.component';
import { OverflowContentPluginToNgbTooltipDirective } from '@shared/_directives/overflow-content-plugin-to-ngb-tooltip.directive';
import { trackByIndex } from '@shared/_helpers';
import { Field, ErrorMessageSection, ViewMode, PreprocessedErrorItemDto } from '@shared/_models';
import { FieldsErrors } from '@shared/dynamic-form/components/dynamic-field/utils';
import { ItemReadValuePipe } from '@shared/pipes/item-read-value.pipe';

import { DYNAMIC_FORM } from '../../../../dynamic-form';
import { IconComponent } from '../../../icon/components/icon/icon.component';
import { UserAvatarComponent } from '../../../user-avatar/user-avatar.component';

@Component({
  selector: 'app-item-details-table-section',
  imports: [
    ContentWithCopyBtnComponent,
    NgClass,
    NgIf,
    NgFor,
    NgbModule,
    LowerCasePipe,
    ...DYNAMIC_FORM,
    OverflowContentPluginToNgbTooltipDirective,
    ItemReadValuePipe,
    IsTextLongPipe,
    TitleCasePipe,
    OptionIconComponent,
    FieldValuePreviewComponent,
    IconComponent,
    UserAvatarComponent,
    CommonModule
  ],
  templateUrl: './item-details-table-section.component.html',
  styleUrls: ['./item-details-table-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailsTableSectionComponent {
  @Input() set fields(value: Field[]) {
    this.fieldsSignal.set(value);
  }
  fieldsSignal = signal<Field[]>([]);
  sortedFields = computed(() => this.fieldsSignal().sort(comparePositions));

  @Input() editMode: boolean = false;
  @Input() templatePreview: boolean = false;

  @Input() set errorMessage(value: ErrorMessageSection) {
    this.#errorMessageSignal.set(value);
  }
  #errorMessageSignal = signal<ErrorMessageSection>(null);

  errors = computed<string[]>(() => {
    if (!this.fieldsSignal()) return [];

    return this.fieldsSignal().map(field => getError(field, this.#errorMessageSignal()));
  });

  @Output() errorsChange: EventEmitter<FieldsErrors> = new EventEmitter<FieldsErrors>();

  readonly trackByIndex = trackByIndex;
  readonly ViewMode = ViewMode;

  onValueChange(value: any, field: Field) {
    field.value = value;
    this.#clearErrors(field);
  }

  #clearErrors(field: Field) {
    const index = this.fieldsSignal().findIndex(fieldItem => fieldItem.field_template_id === field.field_template_id);
    if (this.errors()[index]) {
      this.#errorMessageSignal.set(null);
    }
  }
}

function getError(field: Field, errorMessage: ErrorMessageSection): string {
  const errorResponse = errorMessage?.errorResponse as HttpErrorResponse;

  if (!isPreprocessedError(errorResponse?.error)) return;

  const validationError: PreprocessedErrorItemDto = findValidationError(errorResponse, field);

  if (validationError) return validationError.message;

  if (field.location !== SERIAL_NUMBER) return;

  return toMessage(errorResponse, SERIAL_NUMBER, null);
}
