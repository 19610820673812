export enum IconColors {
  darkBlue = '#1247a1',
  grey = '#7f888d',
  mediumGrey = '#a2bdcf',
  mediumLightGrey = '#dbe4eb',
  mediumDarkGrey = '#6c8190',
  darkGrey = '#89969f',
  lightGrey = '#d3d3d3',
  blue = '#4aa6e8',
  white = '#ffffff',
  invalidRed = '#eb7171',
  roGradient = 'linear-gradient(242.99deg, #1093bd 27.22%, #0fc992 100%)',
  expGradient = 'linear-gradient(242.99deg, #9869e3 27.22%, #329be7 100%)'
}
