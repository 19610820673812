import { HttpErrorResponse } from '@angular/common/http';

import { PreprocessedErrorDto } from '@shared/_models/error-message';

import { isPreprocessedError } from './is-prepocessed-error';

export function isUniqueError(errorResponse: HttpErrorResponse): boolean {
  if (!isPreprocessedError(errorResponse.error)) return false;

  const preprocessedError = errorResponse.error as PreprocessedErrorDto;

  return preprocessedError.errors.some(error => error.location === 'name' && error.message.toLowerCase().includes('unique'));
}
