import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-signin-form-input',
  templateUrl: './signin-form-input.component.html',
  styleUrls: ['./signin-form-input.component.scss'],
  standalone: false
})
export class SigninFormInputComponent {
  @Input() control: FormControl;
  @Input() formSubmitted: boolean;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() icon: string;

  focus: boolean;
}
