import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ContentsMatchValidator, PasswordConditionsValidator } from '@shared/_validators';

import { AuthRoutingPath } from '../../auth-routing-path';
import { AuthService } from '../../auth.service';
import { AuthResetPasswordRequest } from '../../interface';

enum FORM_KEYS {
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword'
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ResetPasswordComponent implements OnInit {
  isResetPasswordSuccessful = signal(false);

  readonly signInRoutingPath = ['/', AuthRoutingPath.auth, AuthRoutingPath.signIn];
  readonly FORM_KEYS = FORM_KEYS;
  focusPassword: boolean;
  focusRetypePassword: boolean;
  formSubmitted = false;
  token: string;
  resetPasswordForm = new FormGroup(
    {
      [FORM_KEYS.PASSWORD]: new FormControl(null, [Validators.required, PasswordConditionsValidator]),
      [FORM_KEYS.CONFIRM_PASSWORD]: new FormControl(null, [Validators.required, PasswordConditionsValidator])
    },
    ContentsMatchValidator(FORM_KEYS.PASSWORD, FORM_KEYS.CONFIRM_PASSWORD)
  );

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');

    if (!this.token) {
      this.authService.redirectToPasswordChangeRequest();
    }
  }

  private getRequestData(): AuthResetPasswordRequest {
    return {
      token: this.token,
      password: this.resetPasswordForm.value[FORM_KEYS.CONFIRM_PASSWORD]
    };
  }

  resetPassword($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    const requestData = this.getRequestData();

    this.formSubmitted = true;
    if (this.resetPasswordForm.valid && requestData) {
      this.authService.resetPassword(requestData).subscribe(() => this.isResetPasswordSuccessful.set(true));
    }
  }
}
