<app-input-with-validation
  [control]="control()"
  [config]="config()"
  [backendErrorMessage]="backendErrorMessage()"
  [externalTouched]="externalTouched()"
>
  <app-text-long-input
    [formControl]="control()"
    [counterOptions]="counterOptions()"
    [hasError]="markAsInvalid()"
    (blurred)="blur$.next()"
  ></app-text-long-input>
</app-input-with-validation>
