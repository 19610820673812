<app-generic-modal
  class="manage-projects-modal"
  [closeButton]="{ text: 'Cancel' }"
  [acceptButton]="{ text: 'Save changes', disabled: dropdownItemError || acceptButtonDisabled() }"
  [developmentType]="DevelopmentType.workspace"
  [primary]="true"
  [hideHeaderCloseIcon]="true"
  [hideFooter]="false"
  (closeModal)="close($event)"
>
  <ng-template #headerTemplate>
    <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
  </ng-template>
  <div class="info-modal">
    <div class="info-modal__header">Available projects categories in this workspace</div>
    <div class="info-modal__body">
      <app-dropdown-manage-values
        [data]="getDropdownValues()"
        label="List of Categories"
        defaultLabel="Default selected category when new project created"
        addLabel="ADD CATEGORY"
        [removeConfirmationNeeded]="true"
        [placeholder]="DEFAULT_VALUE_PLACEHOLDER"
        (haveItemError)="onDropdownHaveItemError($event)"
        (itemsChange)="onDropdownItemsChange($event)"
        (defaultValueChange)="onDropdownDefaultValueChange($event)"
        (removeConfirmationModal)="openRemoveConfirmationModal($event)"
      >
      </app-dropdown-manage-values>
    </div>
  </div>
</app-generic-modal>

<ng-template #modalHeaderTemplate let-modal>
  <app-modal-header-left
    header="Workspace"
    imgSrc="workspace_icon.svg"
    itemName="Manage projects categories"
    [categoryName]="'in ' + workspaceName()"
    [developmentType]="DevelopmentType.all"
    (closeModal)="closeModal.emit()"
  ></app-modal-header-left>
</ng-template>
<ng-template #removeConfirmationModal let-modal>
  <app-generic-modal
    [closeButton]="{ text: 'No, I want to keep it' }"
    [acceptButton]="{ text: 'Yes, remove it' }"
    [developmentType]="DevelopmentType.researchObject"
    [primary]="true"
    [hideHeaderCloseIcon]="true"
    (closeModal)="closeRemoveConfirmationModal($event, modal)"
  >
    <ng-template #headerTemplate>
      <ng-template [ngTemplateOutlet]="modalCenterHeaderTemplate"></ng-template>
    </ng-template>
    <div class="remove-confirmation-modal__body">
      This category is already used in projects. If you remove this category and save changes then projects that used
      this category will be set to uncategorized.
    </div>
  </app-generic-modal>
</ng-template>
<ng-template #modalCenterHeaderTemplate>
  <app-modal-header-center imgSrc="toast_icon_warning.png" header="Are you sure you want to remove used category?">
  </app-modal-header-center>
</ng-template>
