import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';

import { NumberMaskDirective } from '@app/shared/_directives/number-mask/number-mask.directive';
import { FieldConfig } from '@app/shared/dynamic-form/models';

@Component({
  selector: 'app-number-input',
  imports: [FormsModule, CommonModule, ReactiveFormsModule, NumberMaskDirective],
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements ControlValueAccessor {
  @Input() placeholder = '-';
  @Input() config: FieldConfig;
  @Output() blurred = new EventEmitter<void>();

  ngModel = signal('');
  registerOnChange(fn: (value: string) => void): void {
    this.#onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.#onTouched = fn;
  }
  setDisabledState(): void {}
  writeValue(value: string): void {
    this.ngModel.set(value);
  }

  constructor(private controlDirective: NgControl) {
    this.controlDirective.valueAccessor = this;
  }

  onNgModelChange(value: string) {
    this.ngModel.set(value);
    this.#onTouched();
    this.#onChange(this.ngModel());
  }

  #onChange = (value: string) => void {};
  #onTouched = () => void {};
}
