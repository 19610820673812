import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ensureNumber',
  standalone: true
})
export class EnsureNumberPipe implements PipeTransform {
  transform(value: string | number, fallback: number = null): number {
    const numericValue = typeof value === 'number' ? value : parseFloat(value);

    return isNaN(numericValue) ? fallback : numericValue;
  }
}
