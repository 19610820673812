import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, input, output, signal, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { DEFAULT_SELECT_PLACEHOLDER } from '../../../domain/field/placeholders/dropdown';
import { DropdownManageValuesComponent } from '../../../shared/_components/dropdown-manage-values/dropdown-manage-values.component';
import { GenericModalComponent } from '../../../shared/_components/generic-modal/generic-modal.component';
import { CloseReason } from '../../../shared/_components/generic-modal/utils';
import { ModalHeaderCenterComponent } from '../../../shared/_components/modal-header-center/modal-header-center.component';
import { ModalHeaderLeftComponent } from '../../../shared/_components/modal-header-left/modal-header-left.component';
import { AppSettings } from '../../../shared/_configuration';
import { DevelopmentType, DropdownItem, UpdateWorkspaceProjectCategoriesDto } from '../../../shared/_models';
import { selectProjectsCategories } from '../../../shared/_root-store/projects-store/projects.selectors';
import { updateProjectCategories } from '../../../shared/_root-store/workspaces-store/workspaces.actions';
import {
  selectDefaultProjectCategoryId,
  selectWorkspaceProjectsCategories
} from '../../../shared/_root-store/workspaces-store/workspaces.selectors';

@Component({
  selector: 'app-workspace-manage-projects-categories',
  imports: [
    GenericModalComponent,
    ModalHeaderLeftComponent,
    NgTemplateOutlet,
    CommonModule,
    DropdownManageValuesComponent,
    ModalHeaderCenterComponent
  ],
  templateUrl: './workspace-manage-projects-categories.component.html',
  styleUrls: ['./../../styles/workspace-modals.scss', './workspace-manage-projects-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceManageProjectsCategoriesComponent {
  validationError = input<string>();
  workspaceId = input<string>();
  workspaceName = input<string>();
  closeModal = output<void>();
  removeConfirmationModal = viewChild('removeConfirmationModal');
  dropdownManageValuesComponent = viewChild(DropdownManageValuesComponent);
  acceptButtonDisabled = signal<boolean>(false);

  dropdownItems: Partial<DropdownItem>[] = [];
  dropdownDefaultValue: string = null;
  selectedItemIndex: number = null;
  selectedCategoryIsUsed: boolean = null;
  dropdownItemError = false;

  readonly DevelopmentType = DevelopmentType;
  readonly projectsCategories = toSignal(this.store.select(selectWorkspaceProjectsCategories));
  readonly defaultProjectCategoryId = toSignal(this.store.select(selectDefaultProjectCategoryId));
  readonly selectProjectsCategories = toSignal(this.store.select(selectProjectsCategories));

  readonly DEFAULT_VALUE_PLACEHOLDER = 'Uncategorized';

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    private readonly ngbModal: NgbModal,
    private readonly store: Store
  ) {}

  close(reason: CloseReason) {
    if (reason === CloseReason.ACCEPT) {
      this.acceptButtonDisabled.set(true);

      const currentCategories = this.projectsCategories();

      const hasExistingCategoryById = (id: string) => currentCategories.some(category => category.id === id);

      const newCategories = this.dropdownItems
        .filter(item => item.name !== '')
        .map((item, index) => ({
          id: hasExistingCategoryById(String(item.id)) ? String(item.id) : null,
          name: item.name,
          position: index + 1
        }));

      const changes: UpdateWorkspaceProjectCategoriesDto = {
        project_categories: [],
        default_project_category_name: null
      };

      // Compare categories
      const currentCategoriesMap = new Map(currentCategories?.map(c => [c.position, c.name]));
      const newCategoriesMap = new Map(newCategories.map(c => [c.position, c.name]));

      const categoriesChanged =
        newCategories.some(newCat => currentCategoriesMap.get(newCat.position) !== newCat.name) ||
        currentCategories?.some(currentCat => !newCategoriesMap.has(currentCat.position));

      changes.project_categories = newCategories;

      // Compare default category
      const currentDefaultValue = this.projectsCategories().find(
        category => category.id === this.defaultProjectCategoryId()
      )?.name;

      const isDefaultPlaceholder = (value: string) =>
        value === DEFAULT_SELECT_PLACEHOLDER || value === this.DEFAULT_VALUE_PLACEHOLDER;

      const dafaultCategoryChanged = this.dropdownDefaultValue !== currentDefaultValue;

      if (dafaultCategoryChanged) {
        changes.default_project_category_name = isDefaultPlaceholder(this.dropdownDefaultValue)
          ? null
          : this.dropdownDefaultValue;
      } else {
        changes.default_project_category_name = currentDefaultValue;
      }

      // Only dispatch if there are changes
      if (categoriesChanged || dafaultCategoryChanged) {
        this.store.dispatch(
          updateProjectCategories({
            workspaceId: this.workspaceId(),
            changes: changes as UpdateWorkspaceProjectCategoriesDto,
            onFail: () => {
              // przycisk jest odblokowywany tylko on fail, dlatego że w tym przypadku success request może przyjść
              // szybciej niż zamknięcie modala, co oznacza że user wielokrotnie i szybko klikając w przycisk może
              // wywołać kolejne requesty
              this.acceptButtonDisabled.set(false);
            }
          })
        );
      }
    }

    this.closeModal.emit();
  }

  closeRemoveConfirmationModal(reason: CloseReason, modal: NgbModalRef) {
    if (reason === CloseReason.ACCEPT) {
      this.dropdownManageValuesComponent().handleRemoveConfirmation(this.selectedItemIndex);
    }
    modal.close();
    this.selectedItemIndex = null;
  }

  onDropdownItemsChange(items: Partial<DropdownItem>[]) {
    this.dropdownItems = items;
  }

  onDropdownDefaultValueChange(value: string) {
    this.dropdownDefaultValue = value;
  }

  onDropdownHaveItemError(error: boolean) {
    this.dropdownItemError = error;
  }

  openRemoveConfirmationModal(data: { index: number; itemId: string }) {
    this.selectedItemIndex = data.index;
    this.selectedCategoryIsUsed = this.selectProjectsCategories().some(category => category.id === data.itemId);
    if (this.selectedCategoryIsUsed) {
      this.ngbModal.open(this.removeConfirmationModal(), this.settings.MODAL_DEFAULT_CONFIG);
    } else {
      this.dropdownManageValuesComponent().handleRemoveConfirmation(data.index);
    }
  }

  getDropdownValues() {
    const defaultValue = this.projectsCategories().find(
      category => category.id === this.defaultProjectCategoryId()
    )?.name;

    return {
      values: this.projectsCategories().map(category => ({
        id: category.id,
        name: category.name,
        position: category.position
      })),
      defaultValue: defaultValue === this.DEFAULT_VALUE_PLACEHOLDER ? null : defaultValue || null
    };
  }
}
