<div
  *ngIf="control"
  class="container"
  [ngClass]="{ 'container--focused': focus, 'container--is-invalid': control.invalid && control.dirty  }"
>
  <label class="container__label">{{ name | uppercase }}</label>
  <div class="container__input-icon">
    <i class="container__input-icon__icon" [ngClass]="icon ? icon : ''"></i>
    <input
      type="{{ type }}"
      class="form-control container__input-icon__input"
      placeholder="{{ placeholder }}"
      [formControl]="control"
      (focusin)="focus = true"
      (focusout)="focus = false"
    />
  </div>
  <ng-container *ngIf="control.dirty && control.hasError('required')">
    <small data-testid="input-required-validation-error" class="container__validation-message">{{ name }} is required</small>
  </ng-container>
  <ng-container *ngIf="control.dirty && control.hasError('invalidEmail')">
    <small class="container__validation-message">Inproper email format</small>
  </ng-container>
</div>
