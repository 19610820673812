import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { sortBy } from 'lodash-es';

import { getUsers } from '@app/domain/project';
import { ProjectDto, UserAvatar } from '@shared/_models';
import { selectSelectedProject } from '@shared/_root-store/projects-store/projects.selectors';

import { getFilteredUsers } from './get-filtered-users';
import { selectAvatarsDictionary } from './users.selectors';

// moved to seperate file to avoid circular dependencies (users & project selectors)
export const selectProjectUsersByTerm = (term: string) =>
  createSelector(selectAvatarsDictionary, selectSelectedProject, (avatarsDictionary: Dictionary<UserAvatar>, project: ProjectDto) => {
    const users = sortBy(getUsers(project), user => user.surname);
    const filteredUsers = getFilteredUsers(users, term);

    return filteredUsers.map(user => ({
      ...user,
      avatar: avatarsDictionary[user.id]?.base64 ?? null
    }));
  });
