import { AsyncPipe, JsonPipe, NgClass, NgFor, NgIf, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import { Component, computed, input, output, signal, Signal, viewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { NgbCarousel, NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

import { toMessage } from '@app/domain/error-handling';
import { comparePositions } from '@app/domain/shared';
import { GenericEditModalComponent } from '@shared/_components/generic-edit-modal/generic-edit-modal.component';
import { EditModalHeaderArgs } from '@shared/_components/generic-edit-modal/utils';
import { ImageComponent } from '@shared/_components/image/components/image.component';
import { ItemDetailsSectionComponent } from '@shared/_components/item/item-details/item-details-section/item-details-section.component';
import { ItemImageDetailsComponent } from '@shared/_components/item/item-image-details/item-image-details.component';
import { AutoDestroyDirective } from '@shared/_directives/auto-destroy/auto-destroy.directive';
import { trackById } from '@shared/_helpers';
import { DevelopmentItem, DevelopmentType, Image, ImageMetaData, ImageMetaPayload, SectionDto, SectionType } from '@shared/_models';
import { DevelopmentItemService } from '@shared/_services/development-item.service';
import { ImageDetailsModalsService } from '@shared/_services/image/image-details-modals.service';
import { duplicateStringValidator } from '@shared/_validators';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';
import { ImageSafeStyleUrlPipe } from '@shared/pipes/image-safe-style-url.pipe';

@Component({
  selector: 'app-item-details-images',
  imports: [
    NgClass,
    NgIf,
    NgFor,
    NgbModule,
    ItemDetailsSectionComponent,
    DevelopmentTypeCssClassPipe,
    ImageSafeStyleUrlPipe,
    AsyncPipe,
    TitleCasePipe,
    NgTemplateOutlet,
    ImageComponent,
    JsonPipe,
    GenericEditModalComponent,
    ItemImageDetailsComponent
  ],
  templateUrl: './item-details-images.component.html',
  styleUrls: ['./item-details-images.component.scss']
})
export class ItemDetailsImagesComponent extends AutoDestroyDirective {
  sectionId = input.required<string>();
  images = input.required<Image[]>();
  editable = input.required<boolean>();
  areaName = input.required<string>();
  developmentItem = input.required<DevelopmentItem>();

  imageMetaChange = output<ImageMetaData>();
  imagePlaceholderClick = output<void>();
  editBtnClick = output<void>();
  addBtnClick = output<void>();
  editImageBtnClick = output<Image>();
  sectionIdChange = output<string>();

  carousel = viewChild.required<NgbCarousel>('ngbCarousel');
  imageDetailsModal = viewChild.required('imageDetailsModal');
  imageNameModal = viewChild.required('imageNameModal');

  developmentType = computed<DevelopmentType>(() => this.developmentItem()?.template?.category?.development_type);
  selectedId = signal<string>(null);
  editMode = signal<boolean>(false);
  selectedImage = computed(() => this.images().find(image => image.id === this.selectedId()));
  imageMetaErrorDetails = this.imageService.imageMetaErrorDetails;
  imageMetaErrorName = this.imageService.imageMetaErrorName;

  sectionActive = computed(() => this.developmentItem()?.template?.active_section_types?.includes(SectionType.IMAGES));
  sections: Signal<SectionDto[]> = computed(() =>
    this.sectionActive()
      ? this.developmentItem()
          ?.template?.sections.filter(section => section.type === SectionType.IMAGES)
          .sort(comparePositions)
      : []
  );

  mainImageId = computed(() => this.developmentItem()?.main_image_id);

  reservedNames = computed<string[]>(() =>
    this.images()
      .filter(image => image.id !== this.selectedImage()?.id)
      .map(image => image.name)
  );

  editNameModalHeader = computed<EditModalHeaderArgs>(() => ({
    top: 'Editing name for',
    middle: 'Image',
    bottom: this.selectedImage()?.name,
    icon: 'images_placeholder_dark_icon.svg',
    whiteIconFilter: true
  }));

  readonly imageNameValidators = [Validators.required, duplicateStringValidator(this.reservedNames)];

  readonly DevelopmentType = DevelopmentType;
  readonly SectionType = SectionType;
  readonly trackById = trackById;

  constructor(
    private readonly developmentItemService: DevelopmentItemService,
    private readonly modalService: NgbModal,
    private readonly imageService: ImageDetailsModalsService
  ) {
    super();
    this.imageService.imageDetailsModal$.pipe(takeUntil(this.destroy$)).subscribe((image: Image) => {
      this.openImageDetailsModal(image);
    });

    this.imageService.imageNameModal$.pipe(takeUntil(this.destroy$)).subscribe((image: Image) => {
      this.openImageNameModal(image);
    });
  }

  openImageDetailsModal(image: Image) {
    if (this.developmentItemService.getEditActiveStatus()) {
      return this.developmentItemService.emitShowEditWarningModalEvent();
    }

    this.selectedId.set(image.id);

    this.modalService.open(this.imageDetailsModal(), {
      size: 'xl',
      centered: true
    });
  }

  openImageNameModal(image: Image) {
    if (this.developmentItemService.getEditActiveStatus()) {
      return this.developmentItemService.emitShowEditWarningModalEvent();
    }

    this.selectedId.set(image.id);

    this.modalService.open(this.imageNameModal(), {
      size: 'lg',
      centered: true
    });
  }

  onImageDetailsModalClose(modal: NgbModalRef) {
    modal.close();

    if (!this.modalService.hasOpenModals()) {
      this.selectedId.set(null);
    }

    this.imageService.imageMetaErrorDetails.set(null);
  }

  onImageNameModalClose(modal: NgbModalRef) {
    modal.close();

    if (!this.modalService.hasOpenModals()) {
      this.selectedId.set(null);
    }

    this.imageService.imageMetaErrorName.set(null);
  }

  addImageBtnClick(): void {
    if (!this.editable()) return;

    this.addBtnClick.emit();
  }

  onCarouselPrevious() {
    if (this.developmentItemService.getEditActiveStatus()) {
      this.developmentItemService.emitShowEditWarningModalEvent();
      return;
    }
    this.carousel().prev();
  }

  onCarouselNext() {
    if (this.developmentItemService.getEditActiveStatus()) {
      this.developmentItemService.emitShowEditWarningModalEvent();
      return;
    }
    this.carousel().next();
  }

  changeTab(tabId: string) {
    this.sectionIdChange.emit(tabId);
  }

  onEdit() {
    if (!this.editable()) return;

    this.editBtnClick.emit();
  }

  onImagePlaceholderClick() {
    if (!this.editable()) return;

    this.imagePlaceholderClick.emit();
  }

  onSaveImageName(name: string, modal: NgbModalRef) {
    if (name === this.selectedImage().name) {
      return modal.close();
    }

    this.imageService.imageMetaErrorName.set(null);

    this.imageMetaChange.emit({
      imageId: this.selectedId(),
      payload: { name },
      onSuccess: () => {
        modal.close();
      },
      onFail: errorResponse => this.imageService.imageMetaErrorName.set(toMessage(errorResponse, null, null))
    });
  }

  onSaveImageMeta(payload: ImageMetaPayload): void {
    if (payload.description === this.selectedImage().description) {
      return this.editMode.set(false);
    }

    if (!payload.description) {
      payload.description = '';
    }

    this.imageService.imageMetaErrorDetails.set(null);

    this.imageMetaChange.emit({
      imageId: this.selectedId(),
      payload,
      onSuccess: () => {
        this.editMode.set(false);
      },
      onFail: errorResponse => this.imageService.imageMetaErrorDetails.set(toMessage(errorResponse, null, null))
    });
  }
}
