import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';

import { UserDto, UserAvatar } from '@shared/_models';

import * as actions from './users.actions';

export const USERS_STATE_KEY = 'users';
export const usersAdapter: EntityAdapter<UserDto> = createEntityAdapter<UserDto>();
export const avatarsAdapter: EntityAdapter<UserAvatar> = createEntityAdapter<UserAvatar>();
export interface UsersState {
  readonly avatars: EntityState<UserAvatar>;
  readonly avatarsPending: string[];
  readonly users: EntityState<UserDto>;
  readonly currentUser: UserDto;
  readonly currentUserLoading: boolean;
  readonly currentUserLoaded: boolean;
  readonly usersLoading: boolean;
}

export const initialState: UsersState = {
  avatars: avatarsAdapter.getInitialState(),
  avatarsPending: [],
  users: usersAdapter.getInitialState(),
  currentUser: null,
  currentUserLoading: false,
  currentUserLoaded: false,
  usersLoading: false
};

export const reducer: ActionReducer<UsersState> = createReducer(
  initialState,

  on(actions.loadCurrentUser, state => ({
    ...state,
    currentUserLoading: true
  })),
  on(actions.loadCurrentUserSuccess, (state, action) => ({
    ...state,
    currentUser: action.user,
    currentUserLoading: false,
    currentUserLoaded: true
  })),
  on(actions.loadCurrentUserFailure, state => ({
    ...state,
    currentUser: null,
    currentUserLoading: false,
    currentUserLoaded: false
  })),
  on(actions.loadUserSuccess, (state, { user }) => ({
    ...state,
    users: usersAdapter.upsertOne(user, state.users)
  })),
  on(actions.loadUsersWithAvatars, state => ({
    ...state,
    usersLoading: true
  })),
  on(actions.loadUsersWithAvatarsSuccess, (state, { users }) => ({
    ...state,
    users: usersAdapter.upsertMany(users, state.users),
    usersLoading: false
  })),
  on(actions.loadUsersWithAvatarsFailure, state => ({
    ...state,
    usersLoading: false
  })),
  on(actions.loadAvatarsStart, (state, { ids }) => {
    return {
      ...state,
      avatarsPending: [...state.avatarsPending, ...ids]
    };
  }),
  on(actions.loadAvatarsSuccess, (state, { avatars }) => {
    const loadedIds = avatars.map(({ id }) => id);

    return {
      ...state,
      avatars: avatarsAdapter.upsertMany(avatars, state.avatars),
      avatarsPending: state.avatarsPending.filter(id => !loadedIds.includes(id))
    };
  }),
  on(actions.loadAvatarsFailure, (state, { ids }) => {
    return {
      ...state,
      avatarsPending: state.avatarsPending.filter(id => !ids.includes(id))
    };
  })
);

export function getAvatarIdsToLoad(ids: string[], state: UsersState): string[] {
  return ids.filter(id => !state.avatars.entities[id] && !state.avatarsPending.includes(id));
}
