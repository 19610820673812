import { ValidatorFn, Validators } from '@angular/forms';
import { isNil } from 'lodash-es';

import { DisplayFieldType } from '@shared/_models';
import { stripHtmlTextMaxLengthValidator } from '@shared/_validators/strip-html-text-max-length.validator';
import { FieldConfig } from '@shared/dynamic-form/models';

export function toValidation(config: FieldConfig): ValidatorFn[] {
  if (!config.options) return [];

  return Object.entries(config.options)
    .reduce((accumulation, [key, value]) => accumulation.concat(toValidatorFn(key, value, config.type)), [])
    .filter(Boolean);
}

function toValidatorFn(key: string, value: any, fieldType: DisplayFieldType): ValidatorFn {
  if (value === false || isNil(value)) return;

  if (key === 'min_length') return Validators.minLength(value);

  if (key === 'max_length' && fieldType === DisplayFieldType.WYSIWYG) {
    return stripHtmlTextMaxLengthValidator(value);
  }

  if (key === 'max_length' && fieldType !== DisplayFieldType.WYSIWYG) {
    return Validators.maxLength(value);
  }

  if (key === 'min_value') return Validators.min(value);

  if (key === 'max_value') return Validators.max(value);

  if (key === 'required') return Validators.required;

  if (key === 'pattern') return Validators.pattern(value);

  return;
}
