<div class="image">
  @if (base64()) {
    @if (isTiff()) {
      <app-tiff-viewer [base64]="base64()"></app-tiff-viewer>
    } @else {
      <img class="image__image" [src]="base64()" alt="image" />
    }
  } @else {
    <span class="image__loader">Loading image...</span>
  }
</div>
