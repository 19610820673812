import { Component } from '@angular/core';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

import { LgPopoverConfig } from '@shared/_configuration/lg-popover-config';
import { RouterService } from '@shared/_services/router/router.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: NgbPopoverConfig, useClass: LgPopoverConfig }, LgPopoverConfig],
  standalone: false
})
export class AppComponent {
  constructor(private routerService: RouterService) {
    this.routerService.handleActionsOnRouteChange();
  }
}
