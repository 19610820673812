import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { SettingsDto } from '@shared/_models';

export const resetStates = createAction('[App] Reset all states to initial state');
export const notifyAboutError = createAction(
  '[App] Error Notification',
  props<{ notification?: { content: string; header: string }; errorResponse?: HttpErrorResponse }>()
);
export const navigateToDefaultRoute = createAction('[App] Navigate to default route');
export const loadSettings = createAction('[App] Load settings');
export const loadSettingsSuccess = createAction('[App] Load settings success', props<{ settings: SettingsDto }>());
export const loadSettingsFailure = createAction('[App] Load settings failure', props<{ errorResponse: HttpErrorResponse }>());
