import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DevelopmentType, ViewMode } from 'src/app/shared/_models';

import { ExperimentParamsService } from '@app/shared/_services/experiment/experiment-params.service';
import { ResearchObjectParamsService } from '@app/shared/_services/research-object/research-object-params.service';

@Component({
  selector: 'app-list-view-nav',
  imports: [NgIf],
  templateUrl: './list-view-nav.component.html',
  styleUrls: ['./list-view-nav.component.scss']
})
export class ListViewNavComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Input() developmentType: DevelopmentType;
  readonly ViewMode = ViewMode;
  currentViewMode!: ViewMode;
  paramsSub!: Subscription;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly experimentParamsService: ExperimentParamsService,
    private readonly researchObjectParamsService: ResearchObjectParamsService
  ) {}

  ngOnInit(): void {
    this.paramsSub = this.activatedRoute.params.subscribe(params => {
      this.currentViewMode = params.viewMode;
    });
  }

  ngOnDestroy(): void {
    this.paramsSub?.unsubscribe();
  }

  selectView(viewMode: ViewMode): void {
    this.setViewMode(viewMode);
    const newUrl = this.router.url.replace(this.currentViewMode, viewMode);
    this.router.navigateByUrl(newUrl);
  }

  private setViewMode(viewMode: ViewMode) {
    if (this.developmentType == DevelopmentType.experiment) {
      this.experimentParamsService.patchCurrentParams({ viewMode: viewMode });
    }
    if (this.developmentType == DevelopmentType.researchObject) {
      this.researchObjectParamsService.patchCurrentParams({ viewMode: viewMode });
    }
  }
}
