import { NgClass, NgIf } from '@angular/common';
import { Component, computed, EventEmitter, input, Input, Output, signal } from '@angular/core';
import { ViewMode } from 'src/app/shared/_models';

import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';

@Component({
  selector: 'app-list-selection-actions-bar',
  imports: [NgClass, NgIf, IconComponent],
  templateUrl: './list-selection-actions-bar.component.html',
  styleUrls: ['./list-selection-actions-bar.component.scss']
})
export class ListSelectionActionsBarComponent {
  @Input() viewMode: ViewMode;
  @Input() currentCategoryHasChildren: boolean;
  @Input() editable: boolean;
  selectedItemsAmount = input<number>(0);
  exporting = input(false);

  @Output() selectAllOnCurrentPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() unselectAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() import: EventEmitter<void> = new EventEmitter<void>();
  @Output() exportSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() showSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() showAll: EventEmitter<void> = new EventEmitter<void>();
  onlySelectedShowed = false;

  isEmptySelection = computed(() => this.selectedItemsAmount() === 0);
  iconColorBase = computed(() => (this.selectedItemsAmount() === 0 ? IconColors.lightGrey : IconColors.darkGrey));

  readonly ViewMode = ViewMode;
  readonly IconColors = IconColors;

  onSelectAllOnCurrentPage() {
    this.selectAllOnCurrentPage.emit();
  }

  onImport($event) {
    this.import.emit();
  }

  onExportSelected($event) {
    this.exportSelected.emit();
  }

  onDeleteSelected($event) {
    this.deleteSelected.emit();
  }

  onSelectAll() {
    this.selectAll.emit();
  }

  onUnselectAll() {
    this.unselectAll.emit();
  }

  onShowSelectedBtnClick() {
    if (this.onlySelectedShowed === true) {
      this.showAll.emit();
    } else {
      this.showSelected.emit();
    }
    this.onlySelectedShowed = !this.onlySelectedShowed;
  }
}
