<div class="item-image-details-modal" [ngClass]="developmentType() | developmentTypeCSSClass">
  <div class="row g-0 p-0">
    <div class="col-lg-8 col-modal">
      <div class="image-container" [ngClass]="{'image-container--main': isMain()}">
        <div class="image-wrapper">
          <app-image [base64]="image()?.content?.base64"></app-image>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-modal" data-testid="image-description-field">
      <div class="info-container">
        <div class="info-header">
          <div class="item-details">
            <div
              *ngIf="developmentItem()?.template?.category?.name"
              data-testid="image-details-item-category"
              class="item-details__category"
            >
              {{ developmentItem()?.template?.category?.name }}
            </div>
            @if(developmentItem()?.name) {
              <div data-testid="image-details-item-name" class="item-details__name">
                {{ developmentItem()?.name }}
              </div>
            }
            @if(image()?.name) {
              <div data-testid="image-details-img-name" class="item-details__image-name">
                {{ image()?.name }}
                <button class="edit-btn" (click)="openImageMetaModal()">
                  <app-icon
                    class="edit-icon"
                    width="0.685rem"
                    height="0.685rem"
                    fileName="pen_icon.svg"
                    [color]="IconColors.blue"
                  ></app-icon>
                </button>
              </div>
            }
          </div>
        </div>

        <div class="info-body">

          <header data-testid="image-details-header" class="form-label">
            Image Description
          </header>
          @if (editMode()) {
            <app-text-long-input
              class="form-field"
              placeholder="Describe image ..."
              data-testid="description-image-inputfield"
              [formControl]="control"
              [autoHeight]="true"
              [hasError]="backendErrorMessage() !== null || (control.touched && control.invalid)"
            ></app-text-long-input>
            @if (backendErrorMessage() || (control.touched && control.invalid)) {
              <div class="form-field-error">
                {{ backendErrorMessage() }}
              </div>
            }
          } @else {
            <div class="form-value" data-testid="image-description">
              <div class="form-value__inner">{{ image()?.description }}</div>
            </div>
          }
        </div>

        <div class="info-footer">
          <div class="row g-3">
            @if (editMode()) {
              <div class="col">
                <app-button
                  data-testid="cancel-add-description-button"
                  class="{{ developmentType() }}-tertiary d-block h-30"
                  (click)="onCancelBtnClick()"
                >
                  Cancel
                </app-button>
              </div>
              <div class="col">
                <app-button
                  data-testid="save-add-description-button"
                  class="{{ developmentType() }}-primary d-block h-30"
                  (click)="onSaveBtnClick()"
                >
                  Save
                </app-button>
              </div>
            } @else {
              <div class="col-6">
                <app-button
                  data-testid="add-edit-description-button"
                  class="{{ developmentType() }}-tertiary d-block h-30"
                  (click)="onEditBtnClick()"
                >
                  {{ image()?.description?.length ? 'Edit' : 'Add' }}
                </app-button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-close">
    <button
      data-testid="close-image-details-modal-button"
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="onCloseBtnClick()"
    ></button>
  </div>
</div>
