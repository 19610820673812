import { ChangeDetectionStrategy, Component, input, viewChild, ElementRef } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { decode, TiffIfd } from 'tiff';

import { base64ToArrayBuffer, grayscaleToRgba, rgbToRgba } from './utils';

@Component({
  selector: 'app-tiff-viewer',
  imports: [],
  template: `
    <canvas #canvasElement></canvas>
  `,
  styles: [
    `
      :host {
        canvas { max-width: 100%; }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TiffViewerComponent {
  base64 = input.required<string>();
  canvasElement = viewChild<ElementRef>('canvasElement');

  base46$ = toObservable(this.base64);
  canvasElement$ = toObservable(this.canvasElement);

  constructor() {
    this.#afterInitDisplayTiff();
  }

  #afterInitDisplayTiff() {
    combineLatest([this.base46$, this.canvasElement$])
      .pipe(
        takeUntilDestroyed(),
        filter(([base64, canvasElement]) => !!base64 && !!canvasElement)
      )
      .subscribe(([base64, canvasElement]) => {
        this.#displayTiff(base64, canvasElement);
      });
  }

  #displayTiff(base64: string, canvasElement: ElementRef) {
    const arrayBuffer = base64ToArrayBuffer(base64);
    const ifds: TiffIfd[] = decode(arrayBuffer);
    const canvas = canvasElement.nativeElement;
    const ctx = canvas.getContext('2d');

    if (ifds.length === 0 || !ctx) return;

    // Assume first IFD contains image data
    const imageData = ifds[0].data as Uint8Array;
    const width = ifds[0].width;
    const height = ifds[0].height;
    const samplesPerPixel = ifds[0].samplesPerPixel || 1;

    // Resize canvas to match image dimensions
    canvas.width = width;
    canvas.height = height;

    // Check if the image is grayscale or RGB and convert accordingly
    const getRgbaData = () => {
      if (samplesPerPixel === 1) {
        return grayscaleToRgba(imageData);
      }

      if (samplesPerPixel === 3) {
        return rgbToRgba(imageData);
      }
    };

    const rgbaData = getRgbaData();

    if (!rgbaData) {
      console.error('Unsupported TIFF format.');
      return;
    }

    // Create ImageData object
    const imageDataObj = new ImageData(rgbaData, width, height);

    // Draw the image on the canvas
    ctx.putImageData(imageDataObj, 0, 0);
  }
}
