import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import {
  CreateWorkspaceDto,
  UpdateWorkspaceDto,
  UpdateWorkspaceProjectCategoriesDto,
  WorkspaceDto,
  WorkspaceMemberRole,
  WorkspacePendingMemberDto
} from '@shared/_models';

export const initWorkspaces = createAction('[Workspaces] Init Workspaces', props<{ workspaceId: string }>());
export const loadWorkspaces = createAction('[Workspaces] Load Workspaces');
export const loadWorkspacesSuccess = createAction(
  '[Workspaces] Load Workspaces Success',
  props<{ data: WorkspaceDto[] }>()
);
export const loadWorkspacesFailure = createAction(
  '[Workspaces] Load Workspaces Failure',
  props<{ errorResponse: HttpErrorResponse }>()
);
export const loadFavouriteWorkspace = createAction('[Workspaces] Load Favourite Workspace');
export const loadFavouriteWorkspaceSuccess = createAction(
  '[Workspaces] Load Favourite Workspace',
  props<{ workspace: WorkspaceDto }>()
);
export const loadFavouriteWorkspaceFailure = createAction(
  '[Workspaces] Load Favourite Workspace',
  props<{ errorResponse: HttpErrorResponse }>()
);
export const selectWorkspace = createAction('[Workspaces] Select Workspace', props<{ workspace: WorkspaceDto }>());
export const resetError = createAction('[Workspaces] Reset Error');
export const setAsFavorite = createAction('[Workspaces] Set As Favorite');
export const invite = createAction(
  '[Workspaces] Invite',
  props<{
    workspaceId: string;
    user: { email: string };
    onSuccess?: (workspace: WorkspaceDto) => void;
    onFail?: (errorResponse: HttpErrorResponse) => void;
  }>()
);
export const inviteSuccess = createAction('[Workspaces] Invite Success', props<{ workspace: WorkspaceDto }>());
export const inviteFailure = createAction('[Workspaces] Invite Failure', props<{ errorResponse: HttpErrorResponse }>());
export const update = createAction(
  '[Workspaces] Update',
  props<{
    workspaceId: string;
    changes: Partial<UpdateWorkspaceDto>;
    onSuccess?: (workspace: WorkspaceDto) => void;
    onFail?: (errorResponse: HttpErrorResponse) => void;
  }>()
);
export const updateSuccess = createAction('[Workspaces] Update Success', props<{ workspace: WorkspaceDto }>());
export const updateFailure = createAction('[Workspaces] Update Failure', props<{ errorResponse: HttpErrorResponse }>());
export const create = createAction(
  '[Workspaces] Create',
  props<{
    data: CreateWorkspaceDto;
    onSuccess?: (workspace: WorkspaceDto) => void;
    onFail?: (errorResponse: HttpErrorResponse) => void;
  }>()
);
export const createSuccess = createAction('[Workspaces] Create Success', props<{ workspace: WorkspaceDto }>());
export const createFailure = createAction('[Workspaces] Create Failure', props<{ errorResponse: HttpErrorResponse }>());
export const deleteWorkspace = createAction(
  '[Workspaces] Delete',
  props<{ workspaceId: string; onSuccess?: () => void }>()
);
export const deleteWorkspaceSuccess = createAction('[Workspaces] Delete Success', props<{ workspaceId: string }>());
export const deleteWorkspaceFailure = createAction(
  '[Workspaces] Delete Failure',
  props<{ errorResponse: HttpErrorResponse }>()
);
export const deleteMembers = createAction(
  '[Workspaces] Delete Members',
  props<{ workspaceId: string; members: string[] }>()
);
export const deleteMembersSuccess = createAction(
  '[Workspaces] Delete Members Success',
  props<{ workspace: WorkspaceDto }>()
);
export const deleteMembersFailure = createAction(
  '[Workspaces] Delete Members Failure',
  props<{ errorResponse: HttpErrorResponse }>()
);
export const leave = createAction(
  '[Workspaces] Leave',
  props<{ workspaceId: string; removeRequest?: boolean; onSuccess?: () => void }>()
);
export const leaveSuccess = createAction('[Workspaces] Leave Success', props<{ workspaceId: string }>());
export const acceptInvite = createAction('[Workspaces] Accept Invite', props<{ workspaceId: string }>());
export const acceptInviteSuccess = createAction(
  '[Workspaces] Accept Invite Success',
  props<{ workspace: WorkspaceDto }>()
);
export const rejectInvite = createAction('[Workspaces] Reject Invite', props<{ workspaceId: string }>());
export const rejectInviteSuccess = createAction(
  '[Workspaces] Reject Invite Success',
  props<{ workspace: WorkspaceDto }>()
);
export const sendJoinRequest = createAction(
  '[Workspaces] Send Join Request',
  props<{ code: string; onSuccess?: () => void }>()
);
export const sendJoinRequestSuccess = createAction(
  '[Workspaces] Send Join Request Success',
  props<{ workspace: WorkspaceDto }>()
);
export const acceptPendingMember = createAction(
  '[Workspaces] Accept Pending Member',
  props<{ workspaceId: string; userId: string }>()
);
export const acceptPendingMemberSuccess = createAction(
  '[Workspaces] Accept Pending Member Success',
  props<{ workspace: WorkspaceDto }>()
);
export const changeRole = createAction(
  '[Workspaces] Change Role',
  props<{ workspaceId: string; user_id: string; role: WorkspaceMemberRole }>()
);
export const changeRoleSuccess = createAction('[Workspaces] Change Role Success', props<{ workspace: WorkspaceDto }>());
export const updatePending = createAction('[Workspaces] Update Pending', props<{ isPending: boolean }>());
export const informRoleChange = createAction(
  '[Workspaces] Inform Role Change',
  props<{ workspaceId: string; users_id: string[] }>()
);
export const informRoleChangeSuccess = createAction(
  '[Workspaces] Inform Role Change Success',
  props<{ workspace: WorkspaceDto }>()
);
export const getPendingMembers = createAction('[Workspaces] Get Pending Members');
export const getPendingMembersSuccess = createAction(
  '[Workspaces] Get Pending Members Success',
  props<{ pendingMembers: WorkspacePendingMemberDto[] }>()
);
export const getInvitedWorkspaces = createAction('[Workspaces] Get Invited Workspaces');
export const getInvitedWorkspacesSuccess = createAction(
  '[Workspaces] Get Invited Workspaces Success',
  props<{ invitedWorkspaces: WorkspaceDto[] }>()
);
export const updateProjectCategories = createAction(
  '[Workspaces] Update Project Categories',
  props<{ workspaceId: string; changes: UpdateWorkspaceProjectCategoriesDto; onFail?: () => void }>()
);
export const updateProjectCategoriesSuccess = createAction(
  '[Workspaces] Update Project Categories Success',
  props<{ workspace: WorkspaceDto }>()
);
