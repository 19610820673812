import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ISortOption, ViewMode } from '@shared/_models';

@Component({
  selector: 'app-list-sort-nav',
  imports: [NgClass, NgFor, NgIf, NgbModule],
  templateUrl: './list-sort-nav.component.html',
  styleUrls: ['./list-sort-nav.component.scss']
})
export class ListSortNavComponent {
  readonly ViewMode = ViewMode;
  @Input() viewMode!: ViewMode;
  @Input() sortState: ISortOption;
  @Input() sortOptionsConfig!: ISortOption[];
  @Output() sortChange: EventEmitter<ISortOption> = new EventEmitter<ISortOption>();

  isDropdownOpened = false;

  setSort(sortOption: ISortOption): void {
    if (sortOption == this.sortState) {
      sortOption = null;
    }

    this.sortChange.emit(sortOption);
  }
}
