import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//
import { EXPERIMENTS_FILES_API_URL } from '../constants';

// export const EXPERIMENTS_IMAGE_UPLOAD_API_URL = `${EXPERIMENTS_FILES_API_URL}/upload_image`;

@Injectable({
  providedIn: 'root',
})
export class ExperimentImageApiService {
  constructor(private readonly http: HttpClient) {}

  deleteMultiple(ids_to_remove: string[]): Observable<any> {
    // TODO: check response
    return this.http.post<any>(`${EXPERIMENTS_FILES_API_URL}/delete_image`, { ids_to_remove });
  }
}
