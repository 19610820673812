import { Injectable } from '@angular/core';
//
import { UserDto } from '../../_models/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor() {}

  public get currentUser(): UserDto {
    const userData = localStorage.getItem('user');

    return JSON.parse(userData) as UserDto;
  }

  setUserData(data: { [key: string]: any }): void {
    localStorage.setItem('user', JSON.stringify(data));
  }

  unsetUserData(): void {
    localStorage.removeItem('user');
  }

  getUserData(): UserDto {
    const userDataStr = localStorage.getItem('user');

    return JSON.parse(userDataStr) as UserDto;
  }
}
