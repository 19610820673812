<section>
  <div class="accordion-item">
    <div class="vertical-allign">
      <div class="row error-container">
        <div class="col">
          <header>
            <p class="blue-color">500 Error</p>
            <h1 class="internal-server-header">Internal Server Error</h1>
          </header>
          <div>
            <p class="description">The server has been deserted for a while, Please be patient or try again later.</p>
            <app-button (click)="onGoHomeBtnClick()" class="general-primary h-45">Go home</app-button>
          </div>
        </div>
        <div class="col">
          <img src="../../../../assets/images/500.svg" />
        </div>
      </div>
    </div>
  </div>
</section>
