import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { isModificationField } from '@app/domain/field';
import { InputWithValidationComponent } from '@app/shared/_components/inputs/input-with-validation/input-with-validation.component';
import { TextInputComponent } from '@app/shared/_components/inputs/text-input/text-input.component';
import { DisplayFieldType } from '@shared/_models';

import { DynamicFieldBaseComponent } from '../dynamic-field-base/dynamic-field-base.component';

@Component({
  selector: 'app-form-input',
  imports: [NgClass, ReactiveFormsModule, TextInputComponent, InputWithValidationComponent],
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormInputComponent extends DynamicFieldBaseComponent {
  isSmiles = computed<boolean>(() => this.config().type === DisplayFieldType.SMILES);
  isModificationField = computed<boolean>(() => isModificationField(this.config()));
}
