import { UserDto } from '@shared/_models';

export function getFilteredUsers(users: UserDto[], term: string) {
  if (!term) return users;

  return users.filter(
    user =>
      user.name.toLowerCase().includes(term.toLowerCase()) ||
      user.surname.toLowerCase().includes(term.toLowerCase()) ||
      user.email.toLowerCase().includes(term.toLowerCase()) ||
      user.coalesced_email?.toLowerCase()?.includes(term.toLowerCase()) ||
      user.mail_address?.toLowerCase()?.includes(term.toLowerCase())
  );
}
