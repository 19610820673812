import { NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params } from '@angular/router';
import { distinctUntilChanged } from 'rxjs';

import { Pagination } from '@shared/_models';

import { createPageArray } from './utils';

const MAX_PAGES = 7;

@Component({
  selector: 'app-list-pagination',
  imports: [NgClass, NgFor],
  templateUrl: './list-pagination.component.html',
  styleUrls: ['./list-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListPaginationComponent {
  pagination = input.required<Pagination>();
  pageIndexChanged = output<number>();

  currentPageIndex = computed(() => this.pagination().page);
  pages = computed(() => createPageArray(this.currentPageIndex(), this.pagination(), MAX_PAGES));
  prevPageIndex = computed<number>(() => (this.currentPageIndex() > 0 ? this.currentPageIndex() - 1 : 0));
  nextPageIndex = computed<number>(() => {
    const pagesAmount = this.pagination()?.pageCount;

    return this.currentPageIndex() < pagesAmount ? this.currentPageIndex() + 1 : pagesAmount;
  });

  constructor(private readonly activatedRoute: ActivatedRoute) {
    this.#updatePageIndexOnParamsChange();
  }

  onPageIndexChanged(pageIndex: number): void {
    this.pageIndexChanged.emit(pageIndex);
  }

  #updatePageIndexOnParamsChange() {
    this.activatedRoute.queryParams
      .pipe(
        takeUntilDestroyed(),
        distinctUntilChanged((prev, curr) => prev.page === curr.page)
      )
      .subscribe((params: Params) => {
        const { page } = params;
        this.pageIndexChanged.emit(parseInt(page) || 1);
      });
  }
}
