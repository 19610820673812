import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TrackByFunction,
  computed,
  output,
  signal
} from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { isNil, cloneDeep } from 'lodash-es';
import { SortablejsModule } from 'nxt-sortablejs';

import { ComplexField } from '@app/domain/field';
import { BoxControlsBarComponent } from '@shared/_components/box-control-bar/box-controls-bar.component';
import { PepseqFormFieldComponent } from '@shared/_components/item/pepseq-form-field/pepseq-form-field.component';
import { DisplayFieldType, FieldsViewMode, Field, PreprocessedErrorItemDto } from '@shared/_models';
import { ValidationErrorPipe } from '@shared/pipes/validation-error.pipe';

@Component({
  selector: 'app-pepseq-form-group',
  imports: [
    CommonModule,
    NgbModule,
    SortablejsModule,
    PepseqFormFieldComponent,
    BoxControlsBarComponent,
    ValidationErrorPipe
  ],
  templateUrl: './pepseq-form-group.component.html',
  styleUrls: ['./pepseq-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PepseqFormGroupComponent {
  /* TODO: REFACTOR! We have big problem with the way we update parameters.
   * It starts in app-item-details-parameters where we hold fields in parametersDataToSend
   * and we MUTATE fields down the chain. I mean mutate here in methods:
   * onSequenceValueChange, onModificationValueChange
   *
   * as little walkaround for now I'm adding complexFieldSignal here
   * which will not be mutated and used to feed OnPush child components
   */
  @Input() set complexField(value: ComplexField) {
    this.#onComplexFieldChange(value);
  }
  get complexField(): ComplexField {
    return this.#complexField;
  }
  #complexField: ComplexField;
  complexFieldSignal = signal<ComplexField>(null);

  @Input() set mode(mode: FieldsViewMode) {
    this.#onModeChange(mode);
  }
  get mode() {
    return this.#mode;
  }
  #mode: FieldsViewMode;

  @Input() showDropdowns = true;
  @Input() errors: PreprocessedErrorItemDto[] = [];
  @Input() active = true;
  @Input() hidden = false;
  @Input() areaHovered = false;

  @Input() set editMode(value: boolean) {
    this.#onEditModeChange(value);
  }
  get editMode(): boolean {
    return this.#editMode;
  }
  #editMode = false;

  @Output() delete = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
  deleteCalculated = output<Field>();
  moveCalculated = output<{ id: string; position: number }>();

  #calculatedBeforeDrag: Field[];
  fieldHovered = false;
  options = computed(() => ({
    group: `calculated_fields_${this.complexFieldSignal()?.parent.name}`,
    ghostClass: 'area__field-shadow',
    dragClass: 'area__field-dragged',
    onStart: () => {
      this.#calculatedBeforeDrag = [...this.complexFieldSignal().calculated];
    },
    onEnd: $event => {
      if ($event.newIndex === $event.oldIndex) {
        return;
      }

      this.moveCalculated.emit({
        id: this.#calculatedBeforeDrag[$event.oldIndex].field_template_id,
        position: $event.newIndex
      });
    }
  }));
  calculatedFieldsHovered = signal<boolean>(false);
  hoveredCalculatedFieldIndex = signal<number>(null);
  modificationsDropdownExpanded = false;
  calculatedDropdownExpanded = false;
  modificationsMaxNumber = computed<number>(() => this.complexFieldSignal()?.modifications.length ?? 0);

  get numberOfVisibleModifications(): number {
    if (
      !this.complexField ||
      this.#mode === FieldsViewMode.TEMPLATE_EDIT ||
      this.#mode === FieldsViewMode.TEMPLATE_PREVIEW
    ) {
      return 0;
    }

    return this.complexField.modifications.filter(modification => !isNil(modification.value)).length;
  }

  readonly DisplayFieldType = DisplayFieldType;
  readonly FieldsViewMode = FieldsViewMode;
  readonly trackByFieldTemplateId: TrackByFunction<Field> = (_: number, item: Field): string => item.field_template_id;
  readonly isNil = isNil;

  onSequenceValueChange(value: string) {
    this.complexField.parent.value = value;
    this.complexFieldSignal.update(complexField => ({ ...complexField, parent: { ...complexField.parent, value } }));
  }

  onModificationValueChange(index: number, value: string) {
    this.complexField.modifications[index].value = value;
    this.complexFieldSignal.update(complexField => ({
      ...complexField,
      modifications: complexField.modifications.map((modification, i) =>
        i === index ? { ...modification, value } : modification
      )
    }));
  }

  onModificationRemove(index: number) {
    // modyfikacje z wartością undefined/null traktowane są jako usunięte (nie dodane)
    // modyfikacje z wartością '' traktowane są jako nowe, gotowe do wpisania wartości
    const modification = this.complexField.modifications[index];
    modification.value = null;
    this.complexFieldSignal.update(complexField => ({
      ...complexField,
      modifications: complexField.modifications.map((modification, i) =>
        i === index ? { ...modification, value: null } : modification
      )
    }));
    // jeśli modyfikacja posiadała błąd walidacyjny, po jej usunięciu staje się on nieaktualny
    this.errors = this.errors.filter(error => error.location !== modification.field_template_id);
  }

  onModificationToggleBtnClick() {
    if (!this.#mode || this.#mode === FieldsViewMode.TEMPLATE_PREVIEW || this.#mode === FieldsViewMode.TEMPLATE_EDIT) {
      return;
    }

    this.modificationsDropdownExpanded = !this.modificationsDropdownExpanded;
  }

  onCalculatedToggleBtnClick() {
    this.calculatedDropdownExpanded = !this.calculatedDropdownExpanded;
  }

  #onEditModeChange(value: boolean) {
    this.#editMode = value;

    if (!value) this.modificationsDropdownExpanded = true;

    this.#setProperValues();
  }

  #setProperValues() {
    if (!this.complexField) return;

    this.complexField.modifications = this.complexField.modifications.map(modification => ({
      ...modification,
      value: isNil(modification.value) ? '' : modification.value
    }));
  }

  onModificationAdd() {
    const emptyModification = this.complexField.modifications.find(mod => isNil(mod.value));

    if (emptyModification) {
      emptyModification.value = '';
    }

    this.complexFieldSignal.update(complexField => {
      const emptyModification = complexField.modifications.find(mod => isNil(mod.value));

      return {
        ...complexField,
        modifications: complexField.modifications.map(modification =>
          modification === emptyModification ? { ...modification, value: '' } : modification
        )
      };
    });
  }

  #onModeChange(mode: FieldsViewMode) {
    this.#mode = mode;
    const isDropdownExpanded = mode !== FieldsViewMode.TEMPLATE_EDIT;
    this.calculatedDropdownExpanded = isDropdownExpanded;
    this.modificationsDropdownExpanded = isDropdownExpanded;
  }

  #onComplexFieldChange(value: ComplexField) {
    this.#complexField = value;
    this.complexFieldSignal.set(cloneDeep(value));
  }
}
