import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const NameValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value ?? null;

  if (value) {
    // Regexp:
    // - \p{L} -> dowolna litera Unicode (polskie, francuskie, hiszpańskie itd.)
    // - Dozwolone spacje, myślniki i apostrofy w obrębie słów
    // - Myślnik i apostrof nie mogą być na początku ani na końcu
    // - Myślnik i apostrof nie mogą być podwójne
    // - Spacja nie może być podwójna
    const conditions = new RegExp(`^\\p{L}+(?:[-'’]\\p{L}+)*(?:\\s\\p{L}+(?:[-'’]\\p{L}+)*)*$`, 'u');

    const meetsConditions = conditions.test(value);

    return meetsConditions ? null : { meetsConditions: true };
  }

  return null;
};
