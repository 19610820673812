import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { CopyButtonComponent } from '@shared/_components/copy-button/copy-button.component';

@Component({
  selector: 'app-content-with-copy-btn',
  imports: [CommonModule, NgbTooltip, CopyButtonComponent],
  templateUrl: './content-with-copy-btn.component.html',
  styleUrls: ['./content-with-copy-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentWithCopyBtnComponent {
  @Input() valueToCopy: string;

  constructor(private readonly clipboard: Clipboard) {}
}
