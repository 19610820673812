import { ActionReducer, createReducer, on } from '@ngrx/store';

import { SettingsDto } from '@shared/_models';

import * as actions from './app.actions';

export const COMMON_STATE_KEY = 'common';
export interface CommonState {
  readonly loading: boolean;
  readonly loaded: boolean;
  settings: SettingsDto;
}

export const initialState: CommonState = {
  loading: false,
  loaded: false,
  settings: null
};

export const reducer: ActionReducer<CommonState> = createReducer(
  initialState,

  on(actions.loadSettings, state => ({ ...state, loading: true, loaded: false })),
  on(actions.loadSettingsSuccess, (state, action) => ({
    ...state,
    settings: action.settings,
    loading: false,
    loaded: true
  })),
  on(actions.loadSettingsFailure, state => ({ ...state, loading: false, loaded: false }))
);
