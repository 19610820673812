import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AuthAction } from '@app/_auth/auth-layout/auth-layout/auth-layout.component';

@Component({
  selector: 'app-signin-layout',
  templateUrl: './signin-layout.component.html',
  styleUrls: ['./signin-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SigninLayoutComponent {
  readonly AuthAction = AuthAction;

  constructor() {}
}
