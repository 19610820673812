import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ROOT_CATEGORY_ID } from '@app/domain/category';
import { toMessage } from '@app/domain/error-handling';
import { getAttachParams } from '@app/main/utils';
import { editedCategory } from '@app/navigation/secondary-navigation/secondary-navigation.store';
import { DevelopmentType, RouteParam } from '@app/shared/_models';
import { RouteData } from '@shared/_models/route-data';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { loadCategoryTemplates } from '@shared/_root-store/category-templates-store/category-templates.actions';
import { deleteCategoryTemplateOnCategoryDeleted } from '@shared/_root-store/category-templates-store/category-templates.actions';
import { selectRouteDataParam, selectRouteParams } from '@shared/_root-store/router-store/selectors';
import { NavigationService } from '@shared/_services/navigation.service';

import * as actions from './categories.actions';
import { CategoriesApiService } from '../../_services/category';

@Injectable()
export class CategoriesEffects {
  constructor(
    private actions$: Actions,
    private categoriesApiService: CategoriesApiService,
    private store: Store,
    private toastService: ToastService,
    private navigationService: NavigationService
  ) {}

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCategories),
      switchMap(() =>
        this.categoriesApiService.getList(this.#attachProjectId({})).pipe(
          map(data => actions.loadCategoriesSuccess({ data })),
          catchError(error => of(actions.loadCategoriesFailure({ error })))
        )
      )
    )
  );

  addCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addCategory),
      concatMap(({ category, onSuccessAction }) =>
        this.categoriesApiService.add(category).pipe(
          map(category => {
            onSuccessAction();

            return actions.addCategorySuccess({ category });
          }),
          catchError(error => of(actions.addCategoryFailure({ error })))
        )
      )
    )
  );

  updateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateCategory),
      concatMap(({ categoryId, update, onSuccessAction, changeCategoryOrder }) =>
        this.categoriesApiService.update(categoryId, update).pipe(
          map(category => {
            onSuccessAction?.();

            // TODO: what for ?
            if (changeCategoryOrder) {
              return actions.changeCategoryOrderSuccess({ category });
            }

            return actions.updateCategorySuccess({ category });
          }),
          catchError(errorResponse => of(actions.updateCategoryFailure({ errorResponse })))
        )
      )
    )
  );

  updateCategoryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updateCategoryFailure),
        tap(({ errorResponse }) => {
          const errorMessage = toMessage(errorResponse, null, 'An error occured while update category');

          this.toastService.show(errorMessage, {
            header: 'Error',
            type: 'danger',
            delay: 5000
          });
        })
      ),
    { dispatch: false }
  );

  updateCategoryStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateStatuses),
      concatMap(({ categoryId, changes, onSuccessAction, onFailureAction }) =>
        this.categoriesApiService.updateStatuses(categoryId, changes).pipe(
          tap(() => {
            this.toastService.show('Category statuses updated successfully', {
              header: 'Category statuses updated',
              type: 'success'
            });
          }),
          map(category => {
            onSuccessAction?.();
            return actions.updateStatusesSuccess({ category });
          }),
          catchError(errorResponse => {
            onFailureAction?.();
            return of(actions.updateStatusesFailure({ errorResponse }));
          })
        )
      )
    )
  );

  updateCategoryStatusesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updateStatusesFailure),
        tap(({ errorResponse }) => {
          const errorMessage = toMessage(errorResponse, null, 'An error occurred while update statuses');

          this.toastService.show(errorMessage, {
            header: 'Error',
            type: 'danger',
            delay: 5000
          });
        })
      ),
    { dispatch: false }
  );

  duplicateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.duplicateCategory),
      concatMap(({ categoryId, name }) =>
        this.categoriesApiService.duplicate(categoryId, name).pipe(
          map(category => {
            editedCategory.set(null);

            return actions.duplicateCategorySuccess({ category });
          }),
          catchError(error => of(actions.duplicateCategoryFailure({ error })))
        )
      )
    )
  );

  changeCategoryOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeCategoryOrder),
      concatMap(({ categoryId, update, onFailureAction }) => {
        return this.categoriesApiService.update(categoryId, update).pipe(
          map(() => actions.loadCategories()),
          catchError(errorResponse => {
            onFailureAction?.();

            return of(actions.updateCategoryFailure({ errorResponse }));
          })
        );
      })
    )
  );

  deleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteCategory),
      concatMap(({ categoryId }) =>
        this.categoriesApiService.delete(categoryId).pipe(
          map(() => actions.deleteCategorySuccess({ categoryId })),
          catchError(errorResponse => of(actions.deleteCategoryFailure({ errorResponse })))
        )
      )
    )
  );

  onDeleteCategoryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteCategoryFailure),
        tap(({ errorResponse }) => {
          const errorMessage = toMessage(errorResponse, null, 'An error occured while removing category');

          this.toastService.show(errorMessage, {
            header: 'Error',
            type: 'danger',
            delay: 5000
          });
        })
      ),
    { dispatch: false }
  );

  onDeleteCategorySuccessNavigateToRootCategory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteCategorySuccess),
        withLatestFrom(
          this.store.select(selectRouteParams),
          this.store.select(selectRouteDataParam(RouteData.DEVELOPMENT_TYPE))
        ),
        tap(([{ categoryId }, params, developmentType]) => {
          if (categoryId === params.categoryId) {
            this.navigationService.goToCategoriesEdit(
              params.workspaceId,
              params.projectId,
              ROOT_CATEGORY_ID,
              developmentType as DevelopmentType
            );
          }
        })
      ),
    { dispatch: false }
  );

  onDeleteCategorySuccessDeleteTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteCategorySuccess),
      map(({ categoryId }) => deleteCategoryTemplateOnCategoryDeleted({ categoryId }))
    )
  );

  onAddCategorySuccessRefreshCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addCategorySuccess),
      map(() => actions.loadCategories())
    )
  );

  onAddCategorySuccessNavigateToIt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addCategorySuccess),
      withLatestFrom(this.store.select(selectRouteParams)),
      tap(([{ category }, params]) => {
        this.navigationService.goToCategoriesEdit(
          params.workspaceId,
          params.projectId,
          category.id,
          category.development_type,
          true
        );
      }),
      map(([{ category }, params]) => loadCategoryTemplates({ projectId: params.projectId }))
    )
  );

  onDuplicateCategorySuccessRefreshCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.duplicateCategorySuccess),
      map(() => actions.loadCategories())
    )
  );

  onDuplicateCategorySuccessNavigateToIt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.duplicateCategorySuccess),
      withLatestFrom(this.store.select(selectRouteParams)),
      tap(([{ category }, params]) => {
        this.navigationService.goToCategoriesEdit(
          params.workspaceId,
          params.projectId,
          category.id,
          category.development_type,
          true
        );
      }),
      map(([{ category }, params]) => loadCategoryTemplates({ projectId: params.projectId }))
    )
  );

  #attachProjectId = getAttachParams(RouteParam.PROJECT_ID);
}
