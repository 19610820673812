import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule, NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { Observable, of, merge, BehaviorSubject } from 'rxjs';
import { switchMap, filter, map } from 'rxjs/operators';

import { InputWithValidationComponent } from '@shared/_components/inputs/input-with-validation/input-with-validation.component';
import { UserAvatarComponent } from '@shared/_components/user-avatar/user-avatar.component';
import { UserDto } from '@shared/_models';
import { selectProjectUsersByTerm } from '@shared/_root-store/users-store/select-project-users-by-term';
import { selectAvatarForId } from '@shared/_root-store/users-store/users.selectors';

import { getUserDisplayValue } from './utils';
import { DynamicFieldBaseComponent } from '../dynamic-field-base/dynamic-field-base.component';

@Component({
  selector: 'app-form-member',
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule, InputWithValidationComponent, UserAvatarComponent],
  templateUrl: './form-member.component.html',
  styleUrls: ['./form-member.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormMemberComponent extends DynamicFieldBaseComponent {
  selectedAvatar$: Observable<string> = toObservable(this.control).pipe(
    filter(control => !!control),
    switchMap((control: FormControl) => {
      if (!control.value) return control.valueChanges;

      return merge(of(control.value), control.valueChanges);
    }),
    switchMap((id: string) => (id ? this.store.select(selectAvatarForId(id)).pipe(map(avatar => avatar?.base64 ?? null)) : of(null)))
  );
  searchTerms$ = new BehaviorSubject<string>('');
  items$ = this.searchTerms$.pipe(switchMap((term: string) => this.store.select(selectProjectUsersByTerm(term))));

  constructor(private readonly store: Store) {
    super();
  }

  toLabel(user: UserDto, selectComponent: NgSelectComponent): string {
    if (selectComponent.isOpen) return;

    return getUserDisplayValue(user, this.config());
  }

  onClear() {
    this.control().setValue(null);
  }
}
