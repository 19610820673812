/**
 * Converts grayscale image data to RGBA format.
 * @param {Uint8Array} grayscaleData - The grayscale image data.
 * @returns {Uint8ClampedArray} - The converted RGBA image data.
 */
export function grayscaleToRgba(grayscaleData: Uint8Array): Uint8ClampedArray {
  const rgbaArray = grayscaleData.reduce((acc, value) => {
    acc.push(value, value, value, 255); // R = G = B, A = 255
    return acc;
  }, [] as number[]);

  return new Uint8ClampedArray(rgbaArray);
}
