import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-form-section',
  standalone: true,
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormSectionComponent {
  @Input() id: string;
  @Input() title: string;
  @Input() isChildSection: boolean;
  @Input() headerBorder = true;

  constructor() {}
}
