import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { InputWithValidationComponent } from '@app/shared/_components/inputs/input-with-validation/input-with-validation.component';
import { TextLongInputComponent } from '@app/shared/_components/inputs/text-long-input/text-long-input.component';

import { DynamicFieldBaseComponent } from '../dynamic-field-base/dynamic-field-base.component';

@Component({
  selector: 'app-form-long-input',
  imports: [ReactiveFormsModule, TextLongInputComponent, InputWithValidationComponent],
  templateUrl: './form-long-input.component.html',
  styleUrls: ['./form-long-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormLongInputComponent extends DynamicFieldBaseComponent {}
