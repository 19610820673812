import { createSelector } from '@ngrx/store';

import { ImageSize } from '@app/domain/image';
import { ImageDto } from '@shared/_models';
import { RootState } from '@shared/_root-store/reducer';

import { ImagesState } from './images.reducer';

export const selectImagesState = createSelector(
  (state: RootState) => state.images,
  state => state
);

export const selectImagesContentDictionary = (size: ImageSize) =>
  createSelector(selectImagesState, (state: ImagesState) => state.images[size].entities);

export const selectBase64ByImageDto = (imageMeta: ImageDto, size = ImageSize.LARGE) =>
  createSelector(selectImagesContentDictionary(size), imagesDictionary => imagesDictionary[imageMeta?.id]?.base64);

export const selectContentByImageDto = (imageMeta: ImageDto, size = ImageSize.LARGE) =>
  createSelector(selectImagesContentDictionary(size), imagesDictionary => imagesDictionary[imageMeta?.id]);
