import { HttpErrorResponse } from '@angular/common/http';
import { Params } from '@angular/router';
import { createAction, createSelector, props } from '@ngrx/store';

import {
  Experiment,
  ExperimentUpdateDto,
  Filterable,
  Pageable,
  PagedResponse,
  Searchable,
  Sortable,
  ImageDto,
  ListIdsDto,
  ImageMetaUpdateDataForEntity,
  ResearchObject
} from '@shared/_models';
import { selectResearchObjects } from '@shared/_root-store/research-objects-store/research-objects.selectors';

// LOAD
export const loadExperiments = createAction('[Experiments] Load Experiments', props<{ params?: Params }>());
export const loadExperimentsSuccess = createAction(
  '[Experiments] Load Experiments Success',
  props<{ data: PagedResponse<Experiment> }>()
);
export const loadExperimentsFailure = createAction(
  '[Experiments] Load Experiments Failure',
  props<{ error: HttpErrorResponse }>()
);
export const loadExperimentsAndStayOnCurrentPage = createAction(
  '[Experiments] Load Experiments and stay on current page',
  props<{ params?: Searchable & Pageable & Sortable & Filterable }>()
);
export const reloadExperiment = createAction('[Experiments] Reload Experiments', props<{ experimentId: string }>());
export const reloadExperimentSuccess = createAction(
  '[Experiments] Reload Experiments Success',
  props<{ data: Experiment }>()
);
export const reloadExperimentFailure = createAction(
  '[Experiments] Reload Experiments Failure',
  props<{ error: HttpErrorResponse }>()
);

// SELECT
// Had to add Action suffix because of conflict with selectors names
export const selectExperimentAction = createAction('[Experiments] Select Experiment', props<{ id: string }>());
export const selectExperimentsAction = createAction('[Experiments] Select Experiments', props<{ ids: string[] }>());
export const unselectExperiment = createAction('[Experiments] Unelect Experiment', props<{ id: string }>());
export const unselectExperiments = createAction('[Experiments] Unelect Experiments', props<{ ids: string[] }>());
export const clearExperimentSelection = createAction('[Experiments] Clear Experiment Selection');
export const getAllExperimentsIds = createAction('[Experiments] Get All Experiments Ids', props<{ params?: Params }>());
export const getAllExperimentsIdsSuccess = createAction(
  '[Experiments] Get All Experiments Ids Success',
  props<{ data: ListIdsDto }>()
);
export const getAllExperimentsIdsFailure = createAction(
  '[Experiments] Get All Experiments Ids Failure',
  props<{ error: HttpErrorResponse }>()
);

// SECTION UPDATE
export const updateExperiment = createAction(
  '[Experiments] Update Experiment',
  props<{ experimentId: string; data: Partial<ExperimentUpdateDto> }>()
);
export const updateExperimentSuccess = createAction(
  '[Experiments] Update Experiment Success',
  props<{ data: Experiment }>()
);
export const updateExperimentSectionFailure = createAction(
  '[Experiments] Update Experiment Section Failure',
  props<{ error: HttpErrorResponse }>()
);

// DELETE
export const deleteExperiments = createAction(
  '[Experiments] Delete Experiments',
  props<{ experimentsIds: string[]; params: Searchable & Pageable & Sortable & Filterable }>()
);
export const deleteExperimentsSuccess = createAction(
  '[Experiments] Delete Experiments Success',
  props<{ deletedExperimentsIds: string[]; params: Searchable & Pageable & Sortable & Filterable }>()
);
export const deleteExperimentsFailure = createAction('[Experiments] Delete Experiments Failure');

export const deleteExperiment = createAction(
  '[Experiments] Delete Experiment',
  props<{ experimentId: string; params: Searchable & Pageable & Sortable & Filterable }>()
);
export const deleteExperimentFailure = createAction(
  '[Experiments] Delete Experiments Failure',
  props<{ errorResponse: HttpErrorResponse }>()
);

// EXPORT
export const generateCSV = createAction(
  '[Experiments] Generate Experiments CSV',
  props<{ fileName: any; experimentsIds: string[] }>()
);
export const generateCSVSuccess = createAction(
  '[Experiments] Generate Experiments CSV Success',
  props<{ fileName: any; file: any }>()
);
export const generateCSVFailure = createAction(
  '[Experiments] Generate Experiments CSV Failure',
  props<{ error: HttpErrorResponse }>()
);
export const generateMultipleCSV = createAction(
  '[Experiments] Generate Experiments Multiple CSV',
  props<{ fileName: any; expIdsGroupedByTemplateId: string[][] }>()
);
export const generateMultipleCSVSuccess = createAction(
  '[Experiments] Generate Experiment Multiple CSV Success',
  props<{ fileName: any; file: any }>()
);
export const generateMultipleCSVFailure = createAction(
  '[Experiments] Generate Experiment Multiple CSV Failure',
  props<{ error: HttpErrorResponse }>()
);

export const resetExperimentsSorting = createAction('[Experiments] Reset sorting');
export const resetExperimentsSearching = createAction('[Experiments] Reset searching');

export const clearExperiments = createAction('[Experiments] Clear experiments');

// IMAGE META
export const updateImageMeta = createAction(
  '[Experiments] Update Image Meta',
  props<{ data: ImageMetaUpdateDataForEntity }>()
);
// export const updateImageMeta = createAction('[Experiments] Update Image Meta', props<{ experimentId: string; metaData: ImageMetaData }>());

export const updateImageMetaSuccess = createAction(
  '[Experiments] Update Image Meta Success',
  props<{ experimentId: string; imageMeta: ImageDto }>()
);

export const updateImageMetaFailure = createAction(
  '[Experiments] Update Image Meta Failure',
  props<{ error: HttpErrorResponse }>()
);
