import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserDto } from '@shared/_models';

import { USERS_API_URL, USERS_CURRENT_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  constructor(private readonly http: HttpClient) {}

  getUsers(params?: { [param: string]: string }): Observable<UserDto[]> {
    return this.http.get<UserDto[]>(USERS_API_URL, { params });
  }

  getUser(id: string): Observable<UserDto> {
    return this.http.get<UserDto>(`${USERS_API_URL}/${id}`);
  }

  getCurrentUser(params?: HttpParams): Observable<UserDto> {
    return this.http.get<UserDto>(USERS_CURRENT_API_URL, { params });
  }

  // TODO: verify
  getUserByEmail(email: string, params: HttpParams): Observable<any> {
    return this.http.get(`${USERS_API_URL}/${email}`, { params });
  }

  getUserImage(userId: string) {
    return this.http.get(`${USERS_API_URL}/${userId}/image`, { responseType: 'blob' });
  }
}
