import { NgClass, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, fromEvent, Subscription, tap } from 'rxjs';

import { ExperimentParamsService } from '@app/shared/_services/experiment/experiment-params.service';
import { ResearchObjectParamsService } from '@app/shared/_services/research-object/research-object-params.service';
import { DevelopmentType } from '@shared/_models';
import { DevelopmentTypeCssClassPipe } from '@shared/pipes/development-type-css-class.pipe';

@Component({
  selector: 'app-list-search',
  imports: [NgClass, NgIf, DevelopmentTypeCssClassPipe],
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss']
})
export class ListSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() developmentType: DevelopmentType;
  @Input() searchBarExpanded: boolean;
  @ViewChild('search', { static: true }) search: ElementRef;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() closed = new EventEmitter<null>();
  private searchSub!: Subscription;
  DevelopmentType = DevelopmentType;
  includeInstances = false;
  searchValue = '';

  constructor(
    private readonly experimentParamsService: ExperimentParamsService,
    private readonly researchObjectParamsService: ResearchObjectParamsService
  ) {}

  ngOnInit(): void {
    this.searchValue =
      this.developmentType == DevelopmentType.experiment
        ? this.experimentParamsService.getCurrentParams().search
        : this.researchObjectParamsService.getCurrentParams().search;
    this.includeInstances = this.researchObjectParamsService.getCurrentParams().inInstanceChecked;
  }

  ngAfterViewInit(): void {
    this.searchSub = fromEvent(this.search.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.searchChange.emit(this.search.nativeElement.value);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.searchSub.unsubscribe();
  }

  onCloseBtnClick($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.search.nativeElement.value !== '') {
      this.search.nativeElement.value = '';
      this.searchChange.emit(this.search.nativeElement.value);
    }

    this.closed.emit();
  }

  onSearchInInstancesBtnClick() {
    this.includeInstances = !this.includeInstances;
    this.researchObjectParamsService.patchCurrentParams({
      inInstanceChecked: this.includeInstances
    });
  }
}
