import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//
import { EXPERIMENTS_FILES_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ExperimentFileApiService {
  constructor(private readonly http: HttpClient) {}

  generateCSV(id_export_list: string[]): Observable<Blob> {
    return this.http.post<Blob>(`${EXPERIMENTS_FILES_API_URL}/export_to_excel`, { id_export_list }, { responseType: 'blob' as 'json' });
  }

  upload(experimentId: string, data: any): Observable<any> {
    return this.http.post<any>(`${EXPERIMENTS_FILES_API_URL}/upload_file/${experimentId}`, data);
  }

  delete(data: any): Observable<any> {
    return this.http.post<any>(`${EXPERIMENTS_FILES_API_URL}/delete_file`, data);
  }

  deleteMultiple(ids_to_remove: string[]): Observable<any> {
    return this.http.post<any>(`${EXPERIMENTS_FILES_API_URL}/delete_file`, { ids_to_remove });
  }
}
