import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { shareReplay } from 'rxjs/operators';

import { toTemplateFieldUpdate } from '@app/domain/field';
import {
  CategoryTemplateDto,
  SectionAddDto,
  SectionUpdateDto,
  ParameterDto,
  UpdateCategoryTemplateDto,
  ParameterUpdateDto
} from '@shared/_models';

import { CATEGORY_TEMPLATES_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class CategoryTemplateApiService {
  constructor(private readonly http: HttpClient) {}

  getList(params?: Params) {
    return this.http.get<CategoryTemplateDto[]>(CATEGORY_TEMPLATES_API_URL, { params }).pipe(shareReplay());
  }

  get(categoryTemplateId: string) {
    return this.http.get<CategoryTemplateDto>(`${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}`).pipe(shareReplay());
  }

  update(categoryTemplateId: string, changes: Partial<UpdateCategoryTemplateDto>) {
    return this.http.patch<CategoryTemplateDto>(`${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}`, changes);
  }

  addSection(categoryTemplateId: string, newSection: SectionAddDto) {
    return this.http.patch<CategoryTemplateDto>(`${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}/add_section`, newSection);
  }

  updateSection(categoryTemplateId: string, section_id: string, change: SectionUpdateDto) {
    return this.http.patch<CategoryTemplateDto>(`${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}/update_section`, change, {
      params: { section_id }
    });
  }

  deleteSection(categoryTemplateId: string, section_id: string) {
    return this.http.patch<CategoryTemplateDto>(`${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}/delete_section`, null, {
      params: { section_id }
    });
  }

  addFields(categoryTemplateId: string, section_id: string, fields: ParameterDto[]) {
    return this.http.patch<CategoryTemplateDto>(
      `${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}/add_fields`,
      {
        fields
      },
      {
        params: { section_id }
      }
    );
  }

  updateField(categoryTemplateId: string, section_id: string, field: ParameterDto, update: Partial<ParameterUpdateDto> = null) {
    return this.http.patch<CategoryTemplateDto>(
      `${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}/update_fields`,
      update ?? toTemplateFieldUpdate(field),
      {
        params: { section_id, field_template_id: field.id }
      }
    );
  }

  deleteFields(categoryTemplateId: string, fields_ids: string[]) {
    return this.http.patch<CategoryTemplateDto>(`${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}/delete_fields`, {
      fields_ids
    });
  }

  getUsedCount(categoryTemplateId: string, params: { section_id?: string; field_template_id?: string }) {
    return this.http.get<number>(`${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}/used_count`, { params }).pipe(shareReplay());
  }

  moveFieldsBetweenSections(fieldsIds: string[], sectionId: string, categoryTemplateId: string) {
    return this.http.patch<CategoryTemplateDto>(
      `${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}/move_fields`,
      { fields_ids: fieldsIds },
      {
        params: { section_id: sectionId }
      }
    );
  }

  moveImagesBetweenSections(source_section_id: string, target_section_id: string, categoryTemplateId: string) {
    return this.http.patch<CategoryTemplateDto>(`${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}/move_images`, null, {
      params: {
        source_section_id,
        target_section_id
      }
    });
  }

  deleteImages(categoryTemplateId: string, section_id: string) {
    return this.http.patch<CategoryTemplateDto>(`${CATEGORY_TEMPLATES_API_URL}/${categoryTemplateId}/delete_images`, null, {
      params: { section_id }
    });
  }
}
