<app-item-details-section
  [editMode]="mode() === FieldsViewMode.EDIT"
  [mode]="mode()"
  [showEditBtn]="editable() && (anyParameterHasAValue() || mode() === FieldsViewMode.EDIT)"
  [disabled]="(areErrors() && submitted()) || areTouchedErrors()"
  [activeArea]="activeArea()"
  [hiddenArea]="hiddenArea()"
  [sectionName]="activeArea() ? areaName() : (areaName() | conditionalPrefix: mode() === FieldsViewMode.EDIT : EDITING_PREFIX)"
  (edit)="edit()"
  (save)="onUpdate()"
  (cancelEdit)="cancel()"
>
  <app-item-details-loading-calculations
    *ngIf="areComplexFieldsPresent()"
    [pendingArea]="pendingArea"
    [errorMessage]="errorMessage"
  ></app-item-details-loading-calculations>
  <ng-container header-elements>
    <button
      data-testid="tab"
      *ngFor="let section of displaySections(); trackBy: trackById"
      class="tab"
      (click)="changeTab(section.id)"
      [ngClass]="{ 'tab--active': sectionIdSignal() === section.id, 'tab--disabled': mode() === FieldsViewMode.EDIT }"
      [disabled]="mode() === FieldsViewMode.EDIT"
      [ngbTooltip]="section.name"
    >
      {{ section.name | conditionalPrefix: mode() === FieldsViewMode.EDIT && sectionIdSignal() === section.id : EDITING_PREFIX }}
    </button>
  </ng-container>

  <app-item-details-grid-section
    *ngIf="showItemDetailsGridSection()"
    [mode]="mode()"
    [gridData]="fieldsViewData()"
    [errorMessage]="errorMessage"
    [categoryTemplate]="templateSignal()"
    [pendingArea]="pendingArea"
    [pepseqErrors]="pepseqErrors()"
    (errorsChange)="onErrorsChange($event)"
  ></app-item-details-grid-section>

  <app-item-details-table-section
    *ngIf="showItemDetailsTableSection()"
    [errorMessage]="errorMessage"
    [fields]="parametersDataToSend()"
    [editMode]="mode() === FieldsViewMode.EDIT"
    [templatePreview]="mode() === FieldsViewMode.TEMPLATE_PREVIEW"
    (errorsChange)="onErrorsChange($event)"
  ></app-item-details-table-section>

  <div class="empty-area">
    <app-empty-area-content
      *ngIf="showAddPlaceholder()"
      (click)="edit()"
      [developmentType]="developmentType()"
      [sectionType]="sectionTypeSignal()"
      [edit]="!!initialParameters().length"
      [inactive]="!editable() || !initialParameters().length"
      [editable]="editable()"
      [label]="'Add ' + currentSectionName()"
      [darkMode]="[FieldsViewMode.TEMPLATE_PREVIEW, FieldsViewMode.TEMPLATE_EDIT].includes(mode())"
    ></app-empty-area-content>
  </div>
</app-item-details-section>
