<div
  class="pepseq-field"
  [ngClass]="{
    'pepseq-field--edit-mode': editMode,
    'lg--ro lg-hover-gradient-bg': !editMode,
    'pepseq-field--invalid': errorMessage,
    'pepseq-field--hovered': hovered
  }"
  data-testid="pepseq-field"
>
  @if(!editMode) {
    <div class="pepseq-field__label" data-testid="pepseq-label">{{ topLabel() }}</div>
  }
  <div
    *ngIf="editMode; else previewTmpl"
    class="pepseq-field__input-container"
    [class.pepseq-field__input-container--modification]="isModificationField()"
  >
    <app-dynamic-field
      [config]="dynamicField()"
      [backendErrorMessage]="errorMessage"
      (valueChange)="valueChange.emit($event.value)"
      class="pepseq-field__input"
    ></app-dynamic-field>
    @if (isSmilesField()) {
      <app-icon
        fileName="molecule_icon.svg"
        (click)="openKetcher()"
        [color]="IconColors.blue"
        width="0.8rem"
        height="0.9rem"
        class="pepseq-field__input-container__ketcher-icon"
      ></app-icon>
    }
    @if (isModificationField()) {
      <app-icon
        fileName="trash_5_icon.svg"
        (click)="onRemoveBtnClick()"
        [color]="IconColors.invalidRed"
        width="0.9rem"
        height="0.9rem"
        class="pepseq-field__input-container__remove-icon"
      ></app-icon>
    }
  </div>
  <ng-template #previewTmpl>
    <div class="pepseq-field__value">
      <app-content-with-copy-btn [valueToCopy]="fieldSignal()?.value">
        <app-field-value-preview [field]="fieldSignal()"></app-field-value-preview>
      </app-content-with-copy-btn>
    </div>
  </ng-template>
  <div class="my-1 pepseq-field__divider" *ngIf="editMode"></div>
  <div *ngIf="bottomLabel()" class="pepseq-field__label">{{ bottomLabel() }}</div>
</div>

<ng-template #smilesWysiwygModal let-modal>
  <app-smiles-wysiwyg-modal
    [value]="fieldSignal()?.value"
    [developmentType]="developmentType$ | async"
    [fieldName]="fieldSignal()?.name"
    [categoryName]="(category$ | async)?.name"
    (close)="modal.close()"
    (update)="updateByKetcher($event, modal)"
  ></app-smiles-wysiwyg-modal>
</ng-template>
