import { HttpErrorResponse } from '@angular/common/http';
import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';

import {
  Filterable,
  Pageable,
  PagedResponse,
  ResearchObject,
  Searchable,
  Sortable,
  ImageDto,
  ListIdsDto,
  ResearchObjectAddDto,
  DevelopmentItemUpdateFileDto,
  ImageMetaUpdateDataForEntity
} from '@shared/_models';

// LOAD
export const loadResearchObjects = createAction('[Research Objects] Load Research Objects', props<{ params?: Params }>());
export const loadResearchObjectsSuccess = createAction(
  '[Research Objects] Load Research Objects Success',
  props<{ data: PagedResponse<ResearchObject> }>()
);
export const loadResearchObjectsFailure = createAction(
  '[Research Objects] Load Research Objects Failure',
  props<{ error: HttpErrorResponse }>()
);
export const loadResearchObjectsAndStayOnCurrentPage = createAction(
  '[Experiments] Load Research Objects and stay on current page',
  props<{ params?: Searchable & Pageable & Sortable & Filterable }>()
);
export const loadResearchObjectsAndStayOnCurrentPageSuccess = createAction(
  '[Experiments] Load Research Objects and stay on current page success',
  props<{ data: PagedResponse<ResearchObject> }>()
);

// SELECT
// Had to add Action suffix because of conflict with selectors names
export const selectResearchObjectAction = createAction('[Research Objects] Select Research Object', props<{ id: string }>());
export const selectResearchObjectsAction = createAction('[Research Objects] Select Research Objects', props<{ ids: string[] }>());
export const unselectResearchObject = createAction('[Research Objects] Unelect Research Object', props<{ id: string }>());
export const unselectResearchObjects = createAction('[Research Objects] Unelect Research Objects', props<{ ids: string[] }>());
export const clearResearchObjectSelection = createAction('[Research Objects] Clear Research Object Selection');
export const getAllResearchObjectsIds = createAction('[Research Objects] Get All Research Objects Ids', props<{ params?: Params }>());
export const getAllResearchObjectsIdsSuccess = createAction(
  '[Research Objects] Get All Research Objects Ids Success',
  props<{ data: ListIdsDto }>()
);
export const getAllResearchObjectsIdsFailure = createAction(
  '[Research Objects] Get All Research Objects Ids Failure',
  props<{ error: HttpErrorResponse }>()
);

// CREATE
export const addResearchObject = createAction(
  '[Research Objects] Add Research Object',
  props<{ data: ResearchObjectAddDto; onSuccessAction: () => void; onFailAction: (error: HttpErrorResponse) => void }>()
);
export const addResearchObjectSuccess = createAction(
  '[Research Objects] Add Research Object Success',
  props<{ researchObject: ResearchObject }>()
);
export const addResearchObjectFailure = createAction(
  '[Research Objects] Add Research Object Failure',
  props<{ error: HttpErrorResponse }>()
);

// DELETE
export const deleteResearchObjects = createAction(
  '[Research Objects] Delete ResearchObjects',
  props<{
    researchObjectsIds: string[];
    params: Searchable & Pageable & Sortable & Filterable;
    onDeleteFailed: (deleteFailRoIds: string[], deleteFailRelatedExpCount: number) => void;
  }>()
);
export const deleteResearchObjectsSuccess = createAction(
  '[Research Objects] Delete ResearchObjects Success',
  props<{
    deletedResearchObjectsIds: string[];
    deletedAllResearchObjects: boolean;
    params: Searchable & Pageable & Sortable & Filterable;
  }>()
);
export const deleteResearchObjectsFailure = createAction(
  '[Research Objects] Delete ResearchObjects Failure',
  props<{ showErrorMessage: boolean }>()
);
export const deleteResearchObject = createAction(
  '[Research Objects] Delete ResearchObject',
  props<{ researchObjectId: string; params: Searchable & Pageable & Sortable & Filterable }>()
);
export const deleteResearchObjectFailure = createAction(
  '[Research Objects] Delete ResearchObject Failure',
  props<{ error: HttpErrorResponse }>()
);

// EXPORT
export const generateCSV = createAction(
  '[Research Object] Generate Research Object CSV',
  props<{ fileName: any; researchObjectsIds: string[] }>()
);
export const generateCSVSuccess = createAction(
  '[Research Object] Generate Research Object CSV Success',
  props<{ fileName: any; file: any }>()
);
export const generateCSVFailure = createAction(
  '[Research Object] Generate Research Object CSV Failure',
  props<{ error: HttpErrorResponse }>()
);
export const generateMultipleCSV = createAction(
  '[Research Object] Generate Research Object Multiple CSV',
  props<{ fileName: string; roIdsGroupedByTemplateId: string[][] }>()
);
export const generateMultipleCSVSuccess = createAction(
  '[Research Object] Generate Research Object Multiple CSV Success',
  props<{ fileName: string; file: any }>()
);
export const generateMultipleCSVFailure = createAction(
  '[Research Object] Generate Research Object Multiple CSV Failure',
  props<{ error: HttpErrorResponse }>()
);

export const resetSorting = createAction('[Research Objects] Reset sorting');
export const resetSearching = createAction('[Research Objects] Reset searching');

export const clearResearchObjects = createAction('[Research Objects] Clear research objects');

// SHARES
export const updateShare = createAction(
  '[Research Objects] Update Share',
  props<{ researchObjectId: string; share: DevelopmentItemUpdateFileDto }>()
);
export const updateShareSuccess = createAction(
  '[Research Objects] Update Share Success',
  props<{ researchObjectId: string; share: DevelopmentItemUpdateFileDto }>()
);
export const updateShareFailure = createAction('[Research Objects] Update Share Failure', props<{ error: HttpErrorResponse }>());

// IMAGE META
export const updateImageMeta = createAction('[Research Objects] Update Image Meta', props<{ data: ImageMetaUpdateDataForEntity }>());

export const updateImageMetaSuccess = createAction(
  '[Research Objects] Update Image Meta Success',
  props<{ researchObjectId: string; imageMeta: ImageDto }>()
);

export const updateImageMetaFailure = createAction('[Research Objects] Update Image Meta Failure', props<{ error: HttpErrorResponse }>());
