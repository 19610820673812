import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { DevelopmentType, SectionType } from '@shared/_models';
import { CATEGORIES_EDIT_QUERY_PARAMETER } from '@shared/_models/query-params';

import { IconColors } from '../icon/utils/icon-colors';

@Component({
  selector: 'app-empty-area-content',
  imports: [CommonModule, IconComponent],
  templateUrl: './empty-area-content.component.html',
  styleUrls: ['./empty-area-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyAreaContentComponent {
  developmentType = input.required<DevelopmentType>();
  sectionType = input.required<SectionType | 'files' | 'changelog'>();
  edit = input(false);
  inactive = input(false);
  label = input('Add Field');
  darkMode = input(false);
  editable = input<boolean>(true);
  areaHovered = signal(false);
  isTemplatePreviewView = signal(!!this.route.snapshot.queryParamMap.get(CATEGORIES_EDIT_QUERY_PARAMETER));
  iconFileName = computed(() => {
    switch (this.sectionType()) {
      case SectionType.PARAMETERS:
        return this.edit() && this.editable() ? 'parameters_results_placeholer_icon.svg' : 'parameters_preview_placeholer_icon.svg';
        break;
      case SectionType.RESULTS:
        return this.edit() && this.editable() ? 'results_empty_icon.svg' : 'results_preview_placeholer_icon.svg';
        break;
      case SectionType.INSTANCES:
        return this.edit() && this.editable() ? 'instances_empty_icon.svg' : 'instances_empty_placeholder_icon.svg';
        break;
      case 'files':
        return 'files_empty_icon.svg';
        break;
      case 'changelog':
        return 'changelog_empty_icon.svg';
        break;
      case SectionType.IMAGES:
        return 'images_placeholder_plain_icon.svg';
        break;
    }
  });
  placeholderIconHoverColor = computed(() => {
    if (this.isTemplatePreviewView() || !this.editable()) {
      return null;
    }

    if (this.developmentType() === DevelopmentType.researchObject) {
      return IconColors.roGradient;
    }

    if (this.developmentType() === DevelopmentType.experiment) {
      return IconColors.expGradient;
    }

    return null;
  });

  readonly IconColors = IconColors;
  readonly SectionType = SectionType;

  onHoverChange(hovered: boolean) {
    if (this.isTemplatePreviewView()) {
      return;
    }
    this.areaHovered.set(hovered);
  }

  constructor(private route: ActivatedRoute) {}
}
