import { ActiveStatus } from '@shared/_models/active-status';

import { UserDto } from './user.interface';
import { WorkspaceProjectCategoryDto } from './workspace-project-category';

export interface WorkspaceDto {
  id: string;
  name: string;
  status: ActiveStatus;
  code: string;
  members: WorkspaceMemberDto[];
  user_crt: UserDto;
  date_created: string;
  user_mod: UserDto;
  date_modified: string;
  is_favourite: boolean;
  project_categories: WorkspaceProjectCategoryDto[];
  default_project_category_id: string;
}

export enum WorkspaceMemberStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INVITED = 'invited', // endpoint /invited_workspaces returns workspaces with current user with that status
  BLOCKED = 'blocked'
}

export enum WorkspaceMemberRole {
  OWNER = 'owner', // TODO: ask Artur: is this ever used ?
  DEPUTY = 'deputy',
  MEMBER = 'member',
  GUEST = 'guest'
}

export interface WorkspaceMemberDto {
  user: UserDto;
  status: WorkspaceMemberStatus;
  role: WorkspaceMemberRole;
  date_created: string;
  date_modified: string;
}

export interface WorkspacePendingMemberDto {
  name: string;
  user: UserDto;
  workspace_id: string;
}

export interface CreateWorkspaceDto {
  name: string;
}

export interface UpdateWorkspaceDto {
  name: string;
}

export interface UpdateWorkspaceProjectCategoriesDto {
  // partial because property id can be not provided ( creating new project category )
  project_categories: Partial<WorkspaceProjectCategoryDto>[];
  // name, not id, because we can choose workspace project category which we adding in this request
  default_project_category_name?: string;
}

export class WorkspaceProxy {
  constructor(
    private workspace: WorkspaceDto,
    private currentUser: UserDto
  ) {}

  get isOwner() {
    return this.workspace?.user_crt.id === this.currentUser.id;
  }

  get isDeputy() {
    return this.workspace?.members.some(
      member => member.user.id === this.currentUser.id && member.role === WorkspaceMemberRole.DEPUTY
    );
  }

  get role(): WorkspaceMemberRole {
    if (this.isOwner) return WorkspaceMemberRole.OWNER;

    return this.workspace.members.find(member => member.user.id === this.currentUser.id)?.role;
  }
}
