import { Component } from '@angular/core';

import { AuthService } from '@app/_auth/auth.service';

import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-internal-server-error',
  imports: [ButtonComponent],
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./internal-server-error.component.scss']
})
export class InternalServerErrorComponent {
  constructor(private authService: AuthService) {}

  onGoHomeBtnClick() {
    this.authService.signOut();
  }
}
