import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SettingsDto } from '@shared/_models';

import { SETTINGS_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class SettingsApiService {
  constructor(private readonly http: HttpClient) {}

  get() {
    return this.http.get<SettingsDto>(SETTINGS_API_URL);
  }
}
