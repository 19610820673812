import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { hexToRgba } from '@shared/_helpers';

import { MenuAction } from './utils';

@Component({
  selector: 'app-more-actions',
  imports: [CommonModule, NgbDropdownModule, NgbTooltipModule, IconComponent],
  templateUrl: './more-actions.component.html',
  styleUrl: './more-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreActionsComponent<T> {
  entity = input<T>();
  actions = input.required<MenuAction<T>[]>();
  disabled = input<boolean>(false);
  tooltip = input<string>();
  size = input<string>('1rem');
  color = input<IconColors>(IconColors.blue);
  hoverColor = input<IconColors>(null);
  buttonLabel = input<string>();
  placement = input<string>('auto');

  hovered = signal<number>(null);
  toggleIconColor = computed(() => (this.disabled() ? hexToRgba(IconColors.darkGrey, 0.5) : this.color()));
  toggleIconHoverColor = computed(() => (this.disabled() ? hexToRgba(IconColors.darkGrey, 0.5) : this.hoverColor()));

  IconColors = IconColors;

  onMenuItemHoverChange(hovered: boolean, index: number) {
    if (hovered) {
      this.hovered.set(index);

      return;
    }

    this.hovered.set(null);
  }

  onMenuItemClick(action: MenuAction<T>) {
    if (action.disabled) return;

    action.callback(this.entity());
  }
}
