import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';

import { UserDto } from '@app/shared/_models';
import { DevelopmentType } from '@app/shared/_models/development-type';
import { InfoPopoverProps } from '@shared/_components/info-popover/model/info-popover-props';

import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import { CloseReason } from '../generic-modal/utils';
import { InfoPopoverComponent } from '../info-popover/info-popover.component';
import { ModalHeaderCenterComponent } from '../modal-header-center/modal-header-center.component';

@Component({
  selector: 'app-change-owner-confirmation-modal',
  imports: [GenericModalComponent, ModalHeaderCenterComponent, InfoPopoverComponent],
  templateUrl: './change-owner-confirmation-modal.html',
  styleUrls: ['./change-owner-confirmation-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeOwnerConfirmationModalComponent {
  newOwner = input.required<UserDto>();
  developmentType = input.required<DevelopmentType>();
  ownerRolePopover = input.required<InfoPopoverProps>();

  closeModal = output<CloseReason>();

  header = computed<string>(() => `Are you sure you want to change the owner of this ${this.developmentType()}?`);

  DevelopmentType = DevelopmentType;
}
