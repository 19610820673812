import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { resetStatesMetaReducer } from '@shared/_root-store/app-store/app.meta-reducers';
import { RouterState } from '@shared/_root-store/router-store/interface/router.state.interface';
import {
  WorkspacesState,
  WORKSPACES_STATE_KEY,
  reducer as workspacesReducer
} from '@shared/_root-store/workspaces-store/workspaces.reducer';

import { environment } from './../../../environments/environment';
import { COMMON_STATE_KEY, reducer as commonReducer, CommonState } from './app-store/common.reducer';
import { CategoriesState, reducer as categoriesReducer, CATEGORIES_STATE_KEY } from './categories-store/categories.reducer';
import {
  CategoryTemplatesState,
  reducer as categoryTemplatesReducer,
  CATEGORY_TEMPLATES_STATE_KEY
} from './category-templates-store/category-templates.reducer';
import { ImagesState, reducer as imagesReducer, IMAGES_STATE_KEY } from './images-store/images.reducer';
import { ProjectsState, reducer as projectsReducer, PROJECTS_STATE_KEY } from './projects-store/projects.reducer';
import { ROUTER_STATE_KEY } from './router-store/router-store.module';
import { UsersState, reducer as usersReducer, USERS_STATE_KEY } from './users-store/users.reducer';

export interface RootState {
  [COMMON_STATE_KEY]: CommonState;
  [ROUTER_STATE_KEY]: RouterReducerState<RouterState>;
  [WORKSPACES_STATE_KEY]: WorkspacesState;
  [PROJECTS_STATE_KEY]: ProjectsState;
  [CATEGORIES_STATE_KEY]: CategoriesState;
  [CATEGORY_TEMPLATES_STATE_KEY]: CategoryTemplatesState;
  [IMAGES_STATE_KEY]: ImagesState;
  [USERS_STATE_KEY]: UsersState;
}

export const reducers: ActionReducerMap<RootState> = {
  [COMMON_STATE_KEY]: commonReducer,
  [ROUTER_STATE_KEY]: routerReducer,
  [WORKSPACES_STATE_KEY]: workspacesReducer,
  [PROJECTS_STATE_KEY]: projectsReducer,
  [CATEGORIES_STATE_KEY]: categoriesReducer,
  [CATEGORY_TEMPLATES_STATE_KEY]: categoryTemplatesReducer,
  [IMAGES_STATE_KEY]: imagesReducer,
  [USERS_STATE_KEY]: usersReducer
};

export const metaReducers: MetaReducer<RootState>[] = !environment.production ? [resetStatesMetaReducer] : [resetStatesMetaReducer];
