import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, input, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { first, map, Subject, takeUntil } from 'rxjs';

import { ButtonComponent } from '@app/shared/_components/button/button.component';
import { AppSettings } from '@app/shared/_configuration';
import { DevelopmentType, Filterable, Pageable, ProjectDto, Searchable, Sortable } from '@app/shared/_models';
import { ExperimentsStoreService } from '@app/shared/_root-store/experiments-store/experiments-store.service';
import { ResearchObjectsStoreService } from '@app/shared/_root-store/research-objects-store/research-objects-store.service';
import { ItemsRemoveService } from '@app/shared/_services/item/items-remove.service';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { ResearchObjectsRemoveRejectionComponent } from '@shared/_components/item/items-remove-confirmation/research-objects-remove-rejection/research-objects-remove-rejection.component';

import { GenericModalComponent } from '../../../generic-modal/generic-modal.component';
import { ModalHeaderCenterComponent } from '../../../modal-header-center/modal-header-center.component';

@Component({
  selector: 'app-items-remove-confirmation',
  imports: [
    NgClass,
    NgIf,
    ResearchObjectsRemoveRejectionComponent,
    ButtonComponent,
    ModalHeaderCenterComponent,
    GenericModalComponent,
    NgTemplateOutlet
  ],
  templateUrl: './items-remove-confirmation.component.html',
  styleUrls: ['./items-remove-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsRemoveConfirmationComponent implements OnInit, OnDestroy {
  project = input.required<ProjectDto>();
  @Input() developmentType: DevelopmentType;
  @Input() params: Searchable & Pageable & Sortable & Filterable;
  @ViewChild('researchObjectsRemoveRejectionModal', { static: false }) researchObjectsRemoveRejectionModal: ElementRef;
  @ViewChild('itemsRemoveConfirmationModal', { static: false }) itemsRemoveConfirmationModal: ElementRef;

  selectedItemsLength: number;
  deletingInProgress = false;
  confirmationModalRef: NgbModalRef;
  deleteFailRoIds: string[];
  deleteFailRelatedExpCount: number;

  readonly DevelopmentType = DevelopmentType;
  readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    private readonly experimentsStoreService: ExperimentsStoreService,
    private readonly researchObjectsStoreService: ResearchObjectsStoreService,
    private readonly ngbModalService: NgbModal,
    private readonly itemsRemoveService: ItemsRemoveService
  ) {}

  get developmentTypeName(): string {
    return this.developmentType === DevelopmentType.experiment
      ? this.project()?.development_type_names.experiments
      : this.project()?.development_type_names.research_objects;
  }

  ngOnInit(): void {
    this.openConfirmationModalWhenConfirmRemoveClicked();
    this.closeConfirmationModalWhenItemsDeleted();
    if (this.developmentType == DevelopmentType.researchObject) {
      this.openRejectionModalWhenRelatedExperiments();
    }
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.CLOSE) {
      this.confirmationModalRef.close();
    } else if (reason === CloseReason.ACCEPT) {
      this.onConfirmRemoveBtnClick();
    }
  }

  onConfirmRemoveBtnClick(): void {
    if (this.developmentType === DevelopmentType.experiment) {
      this.deleteExperiments();

      return;
    }

    this.deleteResearchObjects();
  }

  deleteResearchObjects(): void {
    this.researchObjectsStoreService.researchObjectsSelectedIds$
      .pipe(
        first(),
        map(ids => {
          this.researchObjectsStoreService.deleteResearchObjects(ids, this.params, this.#onDeleteFailed.bind(this));
        })
      )
      .subscribe();
  }

  deleteExperiments(): void {
    this.experimentsStoreService.experimentsSelectedIds$
      .pipe(
        first(),
        map(ids => {
          this.experimentsStoreService.deleteExperiments(ids, this.params);
        })
      )
      .subscribe();
  }

  private openRejectionModalWhenRelatedExperiments(): void {
    this.itemsRemoveService.openRejectionModal$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.ngbModalService.open(this.researchObjectsRemoveRejectionModal, this.settings.MODAL_DEFAULT_CONFIG);
      this.confirmationModalRef.close();
    });
  }

  private openConfirmationModalWhenConfirmRemoveClicked(): void {
    this.itemsRemoveService.openConfirmationModal$.pipe(takeUntil(this.destroy$)).subscribe((selectedItemsLength: number) => {
      this.deletingInProgress = false;
      this.selectedItemsLength = selectedItemsLength;
      this.confirmationModalRef = this.ngbModalService.open(this.itemsRemoveConfirmationModal, this.settings.MODAL_DEFAULT_CONFIG);
    });
  }

  private closeConfirmationModalWhenItemsDeleted() {
    this.itemsRemoveService.closeConfirmationModal$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.deletingInProgress = false;
      this.confirmationModalRef.close();
    });
  }

  #onDeleteFailed(deleteFailRoIds: string[], deleteFailRelatedExpCount: number) {
    this.deleteFailRoIds = deleteFailRoIds;
    this.deleteFailRelatedExpCount = deleteFailRelatedExpCount;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
