<div class="empty-area" [ngClass]="inactive() && 'empty-area--inactive'"
     (mouseover)="onHoverChange(true)"
     (mouseleave)="onHoverChange(false)">
  <div data-testid="add-sample-placeholder" class="empty-area__wrapper"
       [class.empty-area__wrapper--dark-mode]="darkMode()"
       [class.empty-area--static]="isTemplatePreviewView() || !editable()"
  >
    <div class="empty-area__placeholder" [ngClass]="{ 'empty-area__placeholder-cursor': sectionType() !== SectionType.IMAGES && edit() && editable()  }">
      <app-icon class="empty-area__placeholder__icon"
                [fileName]="iconFileName()"
                [color]="IconColors.mediumLightGrey"
                [hoverColor]="placeholderIconHoverColor()"
                [inheritHover]="areaHovered()"
                width="4rem"
                height="4rem"
      >
      </app-icon>
      <span *ngIf="sectionType() !== SectionType.IMAGES && !isTemplatePreviewView() && editable()"
            class="empty-area__placeholder__label"
      >
        <span data-testid="add-sample-button" class="empty-area__placeholder__add">
          {{ inactive() ? 'No fields defined for this section' : label() }}
        </span>
      </span>
    </div>
  </div>
</div>

