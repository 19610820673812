import { NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';

import { optionColors } from '@app/shared/_helpers/option-colors';

@Component({
  selector: 'app-item-colors',
  imports: [NgFor, NgClass],
  templateUrl: './item-colors.component.html',
  styleUrls: ['./item-colors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemColorsComponent {
  @Input() set itemColor(value: number) {
    this.selectedColor.set(this.itemColors[value]);
  }

  @Output() selectedColorIndex = new EventEmitter<number>();
  readonly itemColors = optionColors();
  selectedColor = signal<string>(null);

  onColorClick(color: string, index: number) {
    this.selectedColor.set(color);
    this.selectedColorIndex.emit(index);
  }
}
