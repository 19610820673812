import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FORMATTED_TEXT_MAX_LENGTH } from '@shared/constans/constans';
import { EditorComponent } from '@shared/dynamic-form/components/editor/editor.component';

import { DynamicFieldBaseComponent } from '../dynamic-field-base/dynamic-field-base.component';

@Component({
  selector: 'app-form-wysiwyg',
  imports: [EditorComponent, ReactiveFormsModule],
  templateUrl: './form-wysiwyg.component.html',
  styleUrls: ['./form-wysiwyg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormWysiwygComponent extends DynamicFieldBaseComponent {
  maxLength = computed<number>(() => this.config()?.options?.max_length || FORMATTED_TEXT_MAX_LENGTH);
}
