import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';

import { AutoFocusDirective } from '@shared/_directives/auto-focus/auto-focus.directive';

import { TextCounterComponent } from './text-counter/text-counter.component';
import { CounterOptions } from './text-counter/utils';

@Component({
  selector: 'app-text-long-input',
  imports: [FormsModule, CommonModule, ReactiveFormsModule, TextCounterComponent, AutoFocusDirective, AutosizeModule],
  templateUrl: './text-long-input.component.html',
  styleUrls: ['./text-long-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextLongInputComponent implements ControlValueAccessor {
  placeholder = input('-');
  counterOptions = input<CounterOptions>(null);
  hasError = input(false);
  autoHeight = input(false);
  blurred = output<void>();

  ngModel = signal('');

  registerOnChange(fn: (value: string) => void): void {
    this.#onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.#onTouched = fn;
  }
  setDisabledState(): void {}
  writeValue(value: string): void {
    this.ngModel.set(value);
  }

  readonly MIN_ROWS = 3;
  readonly MAX_ROWS = 5;

  constructor(private controlDirective: NgControl) {
    this.controlDirective.valueAccessor = this;
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  onNgModelChange(value: string) {
    const valueWithoutEnters = value?.replace(/\r?\n|\r/g, ' ');
    this.ngModel.set(valueWithoutEnters);
    this.#onTouched();
    this.#onChange(this.ngModel());
  }

  #onChange = (value: string) => undefined;
  #onTouched = () => undefined;
}
