import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { CategoryTemplateDto } from '@shared/_models/category-template';

export const loadCategoryTemplates = createAction(
  '[Category Templates] Load Category Templates',
  (props: { projectId?: string } = {}) => props
);

export const loadCategoryTemplatesSuccess = createAction(
  '[Category Templates] Load Category Templates Success',
  props<{ data: CategoryTemplateDto[] }>()
);

export const loadCategoryTemplatesFailure = createAction(
  '[Category Templates] Load Category Templates Failure',
  props<{ error: HttpErrorResponse }>()
);

export const loadCategoryTemplatesIfNotCached = createAction('[Category Templates] Load Category Templates If Not Cached');

export const updateCategoryTemplateSuccess = createAction(
  '[Category Templates] Update Category Templates Success',
  props<{ data: CategoryTemplateDto }>()
);

export const deleteCategoryTemplateOnCategoryDeleted = createAction(
  '[Category Templates] Delete Category Template On Category Deleted',
  props<{ categoryId: string }>()
);
