import { Directive, Signal, computed, input } from '@angular/core';
import { DisplayFieldType, Field } from 'src/app/shared/_models';

@Directive()
export class ListItemResultBase {
  results = input.required<Field[]>();
  resultToDisplay: Signal<Field> = computed(() => this.results()?.[0]);
  resultType: Signal<DisplayFieldType> = computed(() => this.resultToDisplay()?.type);
  decimalFormat = computed<string>(() => {
    const decimalPlaces = this.resultToDisplay()?.display_options?.decimal_places;

    return decimalPlaces ? `1.0-${decimalPlaces}` : '1.0-0';
  });

  readonly DisplayFieldType = DisplayFieldType;
}
