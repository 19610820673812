import { CategoryDto, DevelopmentType } from '@shared/_models';

import { ROOT_CATEGORY_ID } from './';

function anyCategoryHasStatus(categories: CategoryDto[], allCategories: CategoryDto[]): boolean {
  if (categories.length === 0) return false;

  if (categories.some(category => category.statuses.length > 0)) return true;

  return categories.some(category =>
    anyCategoryHasStatus(
      allCategories.filter(child => child.parent_id === category.id),
      allCategories
    )
  );
}

export function hasStatus(categoryId: string, categories: CategoryDto[], developmentType: DevelopmentType): boolean {
  const getCategories = (): CategoryDto[] => {
    if (categoryId === ROOT_CATEGORY_ID)
      return categories.filter(
        category => category.development_type === developmentType && category.parent_id === null
      );

    return [categories.find(category => category.id === categoryId)];
  };

  return anyCategoryHasStatus(getCategories(), categories);
}
