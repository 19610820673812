/* eslint-disable import/order */
import { ItemImageDetailsComponent } from './item-image-details/item-image-details.component';
import { ItemSelectCheckboxComponent } from './item-select-checkbox/item-select-checkbox.component';
import { ItemsCounterComponent } from './items-counter/items-counter.component';
import { ITEM_DETAILS_COMPONENTS } from './item-details';
import { ItemsRemoveConfirmationComponent } from './items-remove-confirmation/items-remove-confirmation/items-remove-confirmation.component';
import { PepseqFormFieldComponent } from './pepseq-form-field/pepseq-form-field.component';
import { PepseqFormGroupComponent } from './pepseq-form-group/pepseq-form-group.component';

export const ITEM_COMPONENTS = [
  ItemImageDetailsComponent,
  ItemSelectCheckboxComponent,
  ItemsCounterComponent,
  ItemsRemoveConfirmationComponent,
  PepseqFormFieldComponent,
  PepseqFormGroupComponent,
  ...ITEM_DETAILS_COMPONENTS
] as const;

export * from './item-image-details/item-image-details.component';
export * from './item-select-checkbox/item-select-checkbox.component';
export * from './list-item-result-base/list-item-result-base.component';
export * from './items-counter/items-counter.component';
export * from './item-details';
export * from './items-remove-confirmation/items-remove-confirmation/items-remove-confirmation.component';
export * from './item-details/item-details-table-section/item-details-table-section.component';
