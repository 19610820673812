<div class="select-status" [class.open]="isOpen()" #statusSelect>
  <div class="selected-option" (click)="toggle()">
    @if (showColors()) {
      <span class="status-icon" [ngClass]="(selectedStatus() ? selectedStatus().color : 8) | statusIcon"></span>
    }
    <!-- TODO: fix label for empty value -->
    {{ selectedStatus()?.name ?? 'Select status' }}
    @if (editable()) {
      <app-icon
        class="select-status-icon"
        width="0.65rem"
        height="0.4rem"
        [color]="IconColors.grey"
        fileName="chevron_4_icon.svg"
      ></app-icon>
    }
  </div>
  <ul class="options" *ngIf="isOpen()">
    <li (click)="onResetStatus()">
      @if (showColors()) {
        <span class="status-icon" [ngClass]="8 | statusIcon"></span>
      }
      Select status
    </li>
    <li
      [ngClass]="{ 'option-selected': status.name === selectedStatus()?.name }"
      *ngFor="let status of statuses()"
      (click)="selectOption(status)"
    >
      @if (showColors()) {
        <span class="status-icon" [ngClass]="status.color | statusIcon"></span>
      }
      {{ status.name }}
    </li>
  </ul>
</div>
