import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { UserAvatar, UserDto } from '@shared/_models';
import { RootState } from '@shared/_root-store/reducer';

import { getFilteredUsers } from './get-filtered-users';
import { UsersState, USERS_STATE_KEY } from './users.reducer';

export const selectUsersState = createSelector(
  (state: RootState) => state[USERS_STATE_KEY],
  state => state
);

export const selectCurrentUser = createSelector(selectUsersState, (state: UsersState) => state.currentUser);
export const currentUserLoading = createSelector(selectUsersState, (state: UsersState) => state.currentUserLoading);
export const currentUserLoaded = createSelector(selectUsersState, (state: UsersState) => state.currentUserLoaded);

export const selectUsersDictionary = createSelector(selectUsersState, (state: UsersState) => state.users.entities);
export const selectAvatarsDictionary = createSelector(selectUsersState, (state: UsersState) => state.avatars.entities);
export const selectAvatarsPending = createSelector(selectUsersState, (state: UsersState) => state.avatarsPending);
export const selectUsersLoading = createSelector(selectUsersState, (state: UsersState) => state.usersLoading);

export const selectUserById = (id: string) => createSelector(selectUsersDictionary, (dictionary: Dictionary<UserDto>) => dictionary[id]);
export const selectUsersByTerm = (term: string) =>
  createSelector(selectUsersState, (state: UsersState) => {
    if (state.users.ids.length === 0) return [];

    const filteredUsers = getFilteredUsers(Object.values(state.users.entities), term);

    return filteredUsers.map(user => ({
      ...user,
      avatar: state.avatars.entities[user.id]?.base64 ?? null
    }));
  });
export const selectAvatarForId = (id: string) =>
  createSelector(selectAvatarsDictionary, (dictionary: Dictionary<UserAvatar>) => dictionary[id] ?? null);

export const selectAllAvatars = createSelector(selectAvatarsDictionary, (dictionary: Dictionary<UserAvatar>) => Object.values(dictionary));
