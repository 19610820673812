import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { InputWithValidationComponent } from '@shared/_components/inputs/input-with-validation/input-with-validation.component';
import { NumberInputComponent } from '@shared/_components/inputs/number-input/number-input.component';

import { DynamicFieldBaseComponent } from '../dynamic-field-base/dynamic-field-base.component';

@Component({
  selector: 'app-form-number-input',
  imports: [NgClass, ReactiveFormsModule, NumberInputComponent, InputWithValidationComponent],
  templateUrl: './form-number-input.component.html',
  styleUrls: ['./form-number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormNumberComponent extends DynamicFieldBaseComponent {}
