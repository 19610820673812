<section class="development-items-actions-bar">
  <div class="d-flex w-100">
    <div class="development-items-actions-bar__header-section">
      <app-breadcrumbs
        *ngIf="developmentType !== DevelopmentType.project"
        [developmentType]="developmentType"
      ></app-breadcrumbs>
      <div class="development-items-actions-bar__header">
        <span *ngIf="searchTextHasValue && total !== undefined">{{ total }} matching results in {{ header }}</span>
        <span *ngIf="!searchTextHasValue && instancesTableVisible">List of objects instances</span>
        <span *ngIf="!searchTextHasValue && !instancesTableVisible">{{ header }}</span>
      </div>
      <ng-template #instancesTableHeader>
        <div class="development-items-actions-bar__header">List of</div>
        <!-- {{ developmentTemplate?.sections.instanceNamePlural | lowercase }}{{ developmentTemplate?.sections.instanceNamePlural | lowercase }} -->
      </ng-template>
    </div>
    @if (showActions) {
      <div class="development-items-actions-bar__other-section d-flex align-items-center">
        <div
          #addItemControl
          class="development-items-actions-bar__other-section__action"
          [ngClass]="{ 'development-items-actions-bar__other-section__action--active': isAddItemControl() }"
        >
          <ng-content select="[add-item-control]"></ng-content>
        </div>
        <div
          #searchControl
          class="development-items-actions-bar__other-section__action"
          [ngClass]="{ 'development-items-actions-bar__other-section__action--active': isSearchControl() }"
        >
          <ng-content select="[search-control]"></ng-content>
        </div>
        @if (showActions.search) {
          <div
            class="ms-3 d-flex flex-nowrap align-items-center development-items-actions-bar__other-section__action development-items-actions-bar__other-section__action--active"
          >
            <app-list-search
              [developmentType]="developmentType"
              (searchChange)="onSearchChange($event)"
              [searchBarExpanded]="searchBarExpanded"
              (closed)="searchBarExpanded = false"
            ></app-list-search>
            <button
              data-testid="search-on-list-button"
              *ngIf="!searchBarExpanded"
              class="development-items-actions-bar__search-expand-btn"
              (click)="onExpandSearchBarBtnClick()"
            >
              <img src="../../../../../assets/icons/magnifier.svg" alt="magnifier" />
            </button>
          </div>
        }
        @if (viewMode === ViewMode.grid && showActions.sort) {
          <div
            #sortControl
            class="development-items-actions-bar__other-section__action"
            [ngClass]="{ 'development-items-actions-bar__other-section__action--active': isSortControl() }"
          >
            <ng-content select="[sort-control]"></ng-content>
          </div>
        }
        @if (showActions.viewModeSwitch) {
          <div
            #viewControl
            class="development-items-actions-bar__other-section__action"
            [ngClass]="{ 'development-items-actions-bar__other-section__action--active': isViewControl() }"
          >
            <ng-content select="[view-control]"></ng-content>
          </div>
        }
        <div
          #selectionActionControl
          class="development-items-actions-bar__other-section__action"
          [ngClass]="{ 'development-items-actions-bar__other-section__action--active': isSelectionActionControl() }"
        >
          <ng-content select="[selection-action-control]"></ng-content>
        </div>
      </div>
    }
  </div>
</section>
