import { CommonParams } from './common-params';
import { DevelopmentItemDto, DevelopmentItem, DevelopmentItemUpdateDto } from './development-item';
import { ExperimentStatus } from './experiment-status';
import { Field } from './field';
import { ImageDto } from './image';
import { UserDto } from './user.interface';

export interface LinkedObjectDto {
  research_object_id: string;
  ro_name: string;
  ro_oid: number; // auto incrementing id, more readable than id for user
  ro_instance_id: string;
  ro_instance_serial_number: string;
  category_id: string;
  category_name: string;
  position: number;
  user_crt: UserDto;
  date_created: string;
  main_image: ImageDto;
  count_experiments: number;
}

export interface ExperimentDto extends DevelopmentItemDto {
  linked_objects: LinkedObjectDto[];
}

export interface Experiment extends ExperimentDto, DevelopmentItem {}

export interface ExperimentAddDto {
  category_template_id: string;
  name: string;
  description?: string;
  status?: ExperimentStatus;
}

export interface LinkedObjectUpdateDto {
  research_object_id: string;
  ro_instance_id?: string;
  position: number;
}

export interface ExperimentUpdateDto extends DevelopmentItemUpdateDto {
  linked_objects: LinkedObjectUpdateDto[];
}

export interface IExperimentResults {
  readonly primary: Field[];
  readonly secondary: Field[];
}

export interface IExperimentParameters {
  readonly primary: Field[];
  readonly secondary: Field[];
}

export interface IExperimentParams extends CommonParams {
  ro_ids?: string[];
  exp_ids: string[];
}

export enum ResultType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}
