import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-search-result',
  imports: [NgStyle],
  templateUrl: './empty-search-result.component.html',
  styleUrls: ['./empty-search-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptySearchResultComponent {
  @Input() message: string;
  @Input() icon: string;

  iconUrl(): string {
    return `url(${this.icon})`;
  }
}
