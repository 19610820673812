<app-generic-modal
  [closeButton]="{ text: 'No, I want to keep it' }"
  [acceptButton]="{ text: 'Yes, change it' }"
  [developmentType]="developmentType()"
  [primary]="true"
  [hideHeaderCloseIcon]="true"
  (closeModal)="closeModal.emit($event)"
>
  <ng-template #headerTemplate>
    <app-modal-header-center imgSrc="toast_icon_warning.png" [header]="header()"> </app-modal-header-center>
    <div class="info-modal__body text-center">
      <span class="info-modal__body-content">
        If you change the owner of this {{ developmentType() }} to
        <span class="content-highlight-blue"> {{ newOwner()?.name }} {{ newOwner()?.surname }} </span> then that user will have full control
        over this {{ developmentType() }}.
        <span class="content-highlight-purple">
          You will be automatically set with role of {{ developmentType() === DevelopmentType.project ? 'Lead' : 'Deputy' }}
          <app-info-popover class="member-element__info-icon" [data]="ownerRolePopover()"></app-info-popover>
          for this {{ developmentType() }}.</span
        >
        <br />
      </span>
    </div>
  </ng-template>
</app-generic-modal>
