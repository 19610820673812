import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-counter',
  imports: [CommonModule],
  templateUrl: './text-counter.component.html',
  styleUrls: ['./text-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextCounterComponent {
  @Input() length: number;
  @Input() maxLength: number;
}
