import { HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { environment } from '../../../environments/environment';
import { StorageService } from '../_services/storage/storage.service';

export const jwtInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const storageService = inject(StorageService);

  const isJwtUrl = (requestUrl: string): boolean => {
    const isApiUrl = requestUrl.startsWith(environment.apiUrl);
    const isUsersUrl = requestUrl.startsWith(environment.usersUrl);

    return isApiUrl || isUsersUrl;
  };

  const token = storageService.getToken();

  if (token && isJwtUrl(req.url)) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  return next(req);
};
