/**
 * Converts RGB image data to RGBA format.
 * @param {Uint8Array} rgbData - The RGB image data.
 * @returns {Uint8ClampedArray} - The converted RGBA image data.
 */
export function rgbToRgba(rgbData: Uint8Array): Uint8ClampedArray {
  const pixelCount = rgbData.length / 3;
  return new Uint8ClampedArray(
    Array.from({ length: pixelCount }, (_, i) => [
      rgbData[i * 3], // R
      rgbData[i * 3 + 1], // G
      rgbData[i * 3 + 2], // B
      255 // A
    ]).flat()
  );
}
